import { React } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import CustomizedButton from './buttons/CustomizedButton';
import CheckPassword from './CheckPassword';
import { styleScrollbar } from '../assets/style/utils/utils';

export default function CheckingPopUp({
  openPop,
  onClose,
  onClickValidate,
  datatestidCancel,
  datatestidValidate,
  dialogText,
  isTextField,
  errorValidation,
  helperTextCheck,
  labelCheck,
  typeTextField,
  setNewPassword
}) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={openPop}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ sx: styleScrollbar }}
    >
      <DialogContent sx={[{ overflowWrap: 'break-word', maxWidth: '387.11px' }, styleScrollbar]}>
        <DialogContentText
          id="alert-dialog-slide-description"
          textAlign="center"
          sx={(theme) => ({ color: { color: theme.palette.colorBlack } })}
        >
          {t(`${dialogText}`)}
        </DialogContentText>

        {isTextField ? (
          <CheckPassword
            typeTextField={typeTextField}
            errorValidation={errorValidation}
            helperTextCheck={helperTextCheck}
            labelCheck={labelCheck}
            setNewPassword={setNewPassword}
          />
        ) : null}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <CustomizedButton
          variant="contained"
          datatestid={datatestidCancel}
          onClick={onClose}
          text={t('cancel')}
        />
        <CustomizedButton
          variant="contained"
          datatestid={datatestidValidate}
          onClick={onClickValidate}
          text={t('confirm')}
        />
      </DialogActions>
    </Dialog>
  );
}
CheckingPopUp.defaultProps = {
  isTextField: false,
  errorValidation: false,
  helperTextCheck: '',
  labelCheck: '',
  typeTextField: 'text',
  setNewPassword: () => null
};

CheckingPopUp.propTypes = {
  openPop: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickValidate: PropTypes.func.isRequired,
  datatestidCancel: PropTypes.string.isRequired,
  datatestidValidate: PropTypes.string.isRequired,
  dialogText: PropTypes.string.isRequired,
  isTextField: PropTypes.bool,
  errorValidation: PropTypes.bool,
  helperTextCheck: PropTypes.string,
  labelCheck: PropTypes.string,
  typeTextField: PropTypes.string,
  setNewPassword: PropTypes.func
};
