import axios from 'axios';
import API_URL from '../../constants/constants';

const AzureGroupsSuperAdminService = {
  assignAzureGroupsUsers(azureGroupsId, azureUsersId) {
    return axios.patch(`${API_URL}/azure/groups/assign`, { azureGroupsId, azureUsersId });
  },
  removeAzureGroupsUsers(azureGroupsId, azureUsersId) {
    return axios.delete(`${API_URL}/azure/groups/remove`, {
      data: { azureGroupsId, azureUsersId }
    });
  }
};

export default AzureGroupsSuperAdminService;
