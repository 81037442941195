import React, { useState } from 'react';
import { Toolbar, Box, Typography, Button, CardMedia } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import logoCarbur from '../../assets/images/app_logos/carbur-removebg-preview.png';
import {
  styleLoginBoardButton,
  styleLogoPegasusHeader,
  styleLogo,
  styleLoginBoardText
} from '../../assets/style/views/LoginBoard';
import { loginAzure } from '../../services/authentication/AzureAD/authAzure';
import { getPermissions, getProfile } from '../../redux/slices/userConnected';
// import { formatParameter, getParametersNoConnected } from '../../utils/parametersPublic';
// import { BACK_URL } from '../../constants/constants';

export default function HeaderBoard({ isRedirectLogin }) {
  const { t } = useTranslation();
  const backgroundColor = { backgroundColor: 'transparent', height: '0px' };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [parametersNotConnected, setParametersNotConnected] = useState([]);
  // const [logoBack, setLogoBack] = useState('');
  const backgroundColorScroll = {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    height: '25px',
    position: 'sticky',
    top: '0'
  };

  const [navbar] = useState(window.scrollY >= 50);
  const [navbarBackground] = useState(
    window.scrollY >= 50 ? backgroundColor : backgroundColorScroll
  );

  const handleLoginAzure = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(loginAzure());
      if (response.payload.token) {
        localStorage.setItem('token', response.payload.token);
        localStorage.setItem('isOpenSideBar', true);
        await dispatch(getProfile());
        await dispatch(getPermissions());
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await getParametersNoConnected(setParametersNotConnected);
  //   };
  //   fetchData();
  // }, []);
  // useEffect(() => {
  //   const fetchLogo = async () => {
  //     const appLogo = formatParameter(
  //       parametersNotConnected,
  //       'appLogo',
  //       logoCarbur,
  //       (logoImage) => `${BACK_URL}api/images/${logoImage}`
  //     );
  //     setLogoBack(appLogo);
  //   };
  //   fetchLogo();
  // }, [parametersNotConnected]);

  return (
    <Box>
      <AppBar sx={navbarBackground} className={navbar ? 'navbar active' : 'navbar'}>
        <Toolbar sx={navbarBackground}>
          <Box sx={styleLogo}>
            <IconButton aria-label="menu">
              <CardMedia
                sx={styleLogoPegasusHeader}
                component="img"
                image={logoCarbur}
                alt="Logo Pegasus"
              />
            </IconButton>
          </Box>
          <Button
            variant="contained"
            sx={styleLoginBoardButton}
            onClick={
              isRedirectLogin
                ? () => {
                    navigate('/login');
                  }
                : handleLoginAzure
            }
          >
            <Typography sx={styleLoginBoardText}>{t('login')}</Typography>
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

HeaderBoard.propTypes = {
  isRedirectLogin: PropTypes.bool.isRequired
};
