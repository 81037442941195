import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  useTheme
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CustomPasswordStrengthBar from './CustomPasswordStrengthBar';
import {
  styledFormControl,
  styledButtonContainer,
  styledPassword
} from '../../assets/style/views/User';
import { styleEyePassword } from '../../assets/style/views/AzurADUserPopup';

export default function PasswordField({
  defaultPassword,
  setNewPassword,
  newPassword,
  newPasswordAtNextLog,
  setNewPasswordAtNextLog
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    setShowPassword(false);
  }, []);

  return (
    <Grid container item xs={12} justifyContent="center">
      <FormControl sx={styledFormControl}>
        <OutlinedInput
          id="outlined-basic"
          variant="outlined"
          data-testid="resetPassword"
          onChange={(e) => setNewPassword(e.target.value)}
          defaultValue={defaultPassword}
          type={showPassword ? 'text' : 'password'}
          sx={styledPassword}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                sx={styleEyePassword(theme)}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <CustomPasswordStrengthBar password={newPassword} />
        <Box sx={styledButtonContainer}>
          <Box>{t('passwordNextConnection')}</Box>
          <Checkbox
            checked={newPasswordAtNextLog}
            onClick={() => {
              setNewPasswordAtNextLog(!newPasswordAtNextLog);
            }}
          />
        </Box>
      </FormControl>
    </Grid>
  );
}

PasswordField.propTypes = {
  defaultPassword: PropTypes.string.isRequired,
  setNewPassword: PropTypes.func.isRequired,
  newPassword: PropTypes.string.isRequired,
  newPasswordAtNextLog: PropTypes.bool.isRequired,
  setNewPasswordAtNextLog: PropTypes.func.isRequired
};
