import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, FormControl, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import iso31661 from 'iso-3166-1';
import { useDispatch } from 'react-redux';
import { styleDialogActions } from '../../assets/style/views/AzurADUserPopup';
import CustomizedButton from '../buttons/CustomizedButton';
import { leadColumnsRules } from '../../utils/regex';

import CustomFormField from '../form/CustomFormField';
import { updateActivixLead } from '../../redux/slices/ActivixLeads';
import { styleScrollbar } from '../../assets/style/utils/utils';

export default function ActivixEditLeadPopup({ popup, lead, closePopup }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modifiedLead, setModifiedLead] = useState(lead);
  const activixLeadColumns = [
    {
      columnName: 'first_name',
      columnId: 'first_name',
      isNullable: false,
      isEditable: true,
      input: 'TEXTFIELD'
    },
    {
      columnName: 'last_name',
      columnId: 'last_name',
      isNullable: false,
      isEditable: true,
      input: 'TEXTFIELD'
    },
    {
      columnName: 'comment',
      columnId: 'comment',
      isNullable: true,
      isEditable: true,
      input: 'TEXTFIELD'
    },
    {
      columnName: 'type',
      columnId: 'type',
      isNullable: false,
      isEditable: true,
      input: 'AUTOCOMPLETE'
    }
  ];

  const findArgSForRegexByLeadColumn = (leadColumn) => {
    switch (leadColumn) {
      case 'country':
        return iso31661.all().map((elem) => `${elem.alpha2}`);
      case 'type':
        return [
          'email',
          'phone',
          'walk_in',
          'loyalty',
          'renewal',
          'sms',
          'event',
          'pre_booking',
          'web_order'
        ];
      default:
        return undefined;
    }
  };

  const error = (leadColumn, isNullable, isEditable) => {
    if (
      isEditable &&
      leadColumn in leadColumnsRules &&
      ((!isNullable && !modifiedLead[leadColumn]) ||
        (modifiedLead[leadColumn] &&
          !leadColumnsRules[leadColumn].rule(
            modifiedLead[leadColumn],
            findArgSForRegexByLeadColumn(leadColumn)
          )))
    ) {
      return true;
    }
    return false;
  };

  const handleClick = async () => {
    await dispatch(updateActivixLead(modifiedLead));
  };
  const isThereError = activixLeadColumns
    .map(({ columnName, isEditable, isNullable }) => error(columnName, isNullable, isEditable))
    .includes(true);

  return (
    <Dialog
      open={popup}
      onClose={closePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="AcitivixLeadPopup"
      PaperProps={{ sx: styleScrollbar }}
    >
      <DialogContent sx={styleScrollbar}>
        <FormControl sx={{ '& label.Mui-focused': { color: '#00000099' } }}>
          {activixLeadColumns &&
            activixLeadColumns
              .filter(({ columnName }) => columnName !== 'memberOf')
              .map(({ columnName, input, isEditable, isNullable, columnId }) => (
                <Grid key={columnName}>
                  <CustomFormField
                    columnName={columnName}
                    columnId={columnId}
                    input={input}
                    isEditable={isEditable}
                    isNullable={isNullable}
                    valueObject={modifiedLead}
                    setValueObject={setModifiedLead}
                    error={error}
                    ruleMessageKey={leadColumnsRules[columnName].ruleMessageKey}
                    findArgsForRegexByColumnName={findArgSForRegexByLeadColumn}
                  />
                </Grid>
              ))}
        </FormControl>
      </DialogContent>
      <DialogActions sx={styleDialogActions}>
        <CustomizedButton onClick={closePopup} text={t('cancel')} datatestid="closePopup" />
        <CustomizedButton
          datatestid="ButtonUpdateActivixLead"
          disabled={isThereError}
          onClick={handleClick}
          text={t('save')}
        />
      </DialogActions>
    </Dialog>
  );
}

ActivixEditLeadPopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  lead: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
          )
        ])
      ),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.arrayOf(
            PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.objectOf(
                PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
              )
            ])
          )
        ])
      )
    ])
  ).isRequired,
  closePopup: PropTypes.func.isRequired
};
