import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import errorRedirection from '../../../utils/errorRedirection';
import LoadingWrapper from '../../../components/layout/LoadingWrapper';
import HeaderPage from '../../../components/HeaderPage';
import {
  getActivixLeadById,
  selectIsLeadLoaded,
  selectedLead
} from '../../../redux/slices/ActivixLeads';
import LeadStandardDescription from '../../../components/activix/LeadStandardDescription';
import { selectPermissions } from '../../../redux/slices/userConnected';
import hasPermissions from '../../../utils/Permissions/permissions';

export default function ActivixLead() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLeadLoaded = useSelector(selectIsLeadLoaded);
  const lead = useSelector(selectedLead);
  const { id } = useParams();
  const navigate = useNavigate();

  const permissions = useSelector(selectPermissions);

  const dispatchGetLead = async () => {
    try {
      await dispatch(getActivixLeadById({ id })).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const headerPageConfig = {
    text: {
      titleText: t('activixLead'),
      bigTitleText: `${lead.first_name} ${lead.last_name}`,
      selectedItemsText: '',
      tagId: 'titleHeadPage'
    },
    buttonReturn: {
      isDisplayable: hasPermissions(permissions, 'SET_ACTIVIX_LEAD'),
      onClick: () => navigate('/activixLeads')
    }
  };

  useEffect(() => {
    dispatchGetLead();
  }, [dispatch]);

  return (
    <LoadingWrapper isChildDisplayable={isLeadLoaded}>
      <HeaderPage headerPageConfig={headerPageConfig} />
      <Grid sx={{ width: '100%' }} marginTop={{ xs: '100px', sm: '130px', md: '175px' }}>
        <LeadStandardDescription />
      </Grid>
    </LoadingWrapper>
  );
}
