import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UsersDayforce from '../../services/dayforce/dayforceService';
import { getDateTimeFormatted } from '../../utils/Date/DateFunction';

export const initialState = {
  dayforceUsers: [],
  isLoading: false,
  updateAt: '',
  status: 'fulfilled',
  selectedUser: {}
};

export const getDayforceById = createAsyncThunk('getDayforceEmployee', async (id) => {
  const res = await UsersDayforce.getDayforceById(id);
  return res.data;
});
export const editDayforceName = createAsyncThunk('editDayforceEmployee', async (user) => {
  const param = {
    DisplayName: user.DisplayName,
    LastName: user.LastName,
    FirstName: user.FirstName
  };
  const res = await UsersDayforce.editDayforceName(user.id, param);
  return res.data;
});

export const findDayforceUsers = createAsyncThunk('findDayforceUsers', () => {
  UsersDayforce.findDayforceUsers();
});

export const refreshDayforceUsers = createAsyncThunk('refreshDayforceUsers', () => {
  UsersDayforce.refreshDayforceUsers();
});

export const dayforceSlice = createSlice({
  name: 'dayforce',
  initialState,
  reducers: {
    resetSelectedDayforce: () => {
      return initialState;
    },
    resetDayforceList: (state) => {
      state.dayforceUsers = [];
    },
    addDayforceUsersToList: (state, action) => {
      state.dayforceUsers = state.dayforceUsers.concat(action.payload.users);
    },
    setDayforceUserListUpdatedTime: (state, action) => {
      const dateFormatted = getDateTimeFormatted(Number(action.payload));
      state.updateAt = dateFormatted;
      state.isLoading = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDayforceById.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getDayforceById.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedUser = action.payload.dayforceUser;
      })
      .addCase(getDayforceById.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      .addCase(editDayforceName.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(editDayforceName.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedUser = action.meta.arg;
      })
      .addCase(editDayforceName.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(findDayforceUsers.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.dayforceUsers = [];
      })
      .addCase(findDayforceUsers.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLoading = true;
      })
      .addCase(findDayforceUsers.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(refreshDayforceUsers.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.updateAt = '';
        state.dayforceUsers = [];
      })
      .addCase(refreshDayforceUsers.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLoading = true;
      })
      .addCase(refreshDayforceUsers.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});
export const {
  resetSelectedDayforce,
  resetDayforceList,
  addDayforceUsersToList,
  setDayforceUserListUpdatedTime
} = dayforceSlice.actions;

export const selectedUser = (state) => state.dayforce.selectedUser;
export const selectEmployeeManager = (state) => state.dayforce?.selectedUser?.EmployeeManager;
export const selectIsLoading = (state) => state.dayforce.isLoading;
export const dayforceUsersUpdatedAt = (state) => state.dayforce.updateAt;

export const selectIsTableDisplayable = (state) => {
  if (state.dayforce.dayforceUsers.length <= 0) {
    return false;
  }
  return true;
};

export const selectDayforceUsers = (state) => state.dayforce.dayforceUsers;

export default dayforceSlice.reducer;
