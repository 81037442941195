import axios from 'axios';
import API_URL from '../../constants/constants';

const ColumnsService = {
  getColumns(providerName, isBackOffice = false) {
    return isBackOffice
      ? axios.get(`${API_URL}/backOffice/columns/${providerName}`)
      : axios.get(`${API_URL}/columns/${providerName}`);
  },
  patchColumns(providerName, columns) {
    return axios.patch(`${API_URL}/backOffice/columns/${providerName}`, columns);
  }
};

export default ColumnsService;
