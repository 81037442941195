import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Chip, Grid } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import selectAdUserWithAzureUsers from '../../../redux/slices/SynchroAdAzure';

import {
  findAdUsers,
  getDisableText,
  getGroupsAd,
  refreshAdUsers,
  resetAdUserState,
  selectIsLoading,
  selectUpdateAt,
  selectIsTableDisplayable,
  selectedGroups,
  createAdUsersBulk,
  deleteAdUsersBulk,
  enableDisableAdUser
} from '../../../redux/slices/AdUsers';
import { getLicensesAzure, resetAzureLicenseState } from '../../../redux/slices/AzureLicenses';
import hasPermissions from '../../../utils/Permissions/permissions';
import LdapcreateADUserPopup from '../../../components/popup/LdapcreateADUserPopup';
import AdDeleteUserPopup from '../../../components/popup/AdDeleteUserPopup';
import AdGroupsPopup from '../../../components/popup/AdGroupsPopup';
import CsvBulkPopup from '../../../components/popup/CsvBulkPopup';
import AzureUsersGroupsPopup from '../../../components/popup/AzureUsersGroupsPopup';
import { getAzureGroupsGraph, selectAzureGroupsGraph } from '../../../redux/slices/AzureGroups';

import {
  findAzureUsers,
  getAzureUserAvailableServices,
  requestAzureUserDomains,
  resetAzureUserState,
  selectIsLoading as selectAzureLoading,
  refreshAzureUsers,
  selectUpdateAt as azureSelectUpdateAt
  // selectIsTableDisplayable as azureselectIsTableDisplayable
} from '../../../redux/slices/AzureUsersList';
import { getProviderColumns, resetColumn } from '../../../redux/slices/columns';
import { selectPermissions } from '../../../redux/slices/userConnected';
import errorRedirection from '../../../utils/errorRedirection';
import { formatColumnsForTable } from '../../../components/datalist/ComponentsInTable';
import {
  styleActionIconMobileUsersAzure,
  styleChipStatus
} from '../../../assets/style/views/UserPages';
import ItemsList from '../ItemsList';

const sycnhroCustomStatus = ({ row }) => {
  return (
    <Chip
      icon={<CircleRoundedIcon fontSize="small" sx={{ marginLeft: '6px', height: '8px' }} />}
      label={row?.isSynchronize}
      color={row?.isSynchronize === 'synchronized' ? 'success' : 'error'}
      sx={(theme) => styleChipStatus(theme, row?.isSynchronize === 'synchronized')}
    />
  );
};

function SynchroAdAzure() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [tableSelectedRows, setTableSelectedRows] = useState([]);

  const selectSynchroAdAzure = useSelector(selectAdUserWithAzureUsers);
  const usersListIsLoading = useSelector(selectIsLoading);
  const azureUsersListIsLoading = useSelector(selectAzureLoading);
  const adUserdate = useSelector(selectUpdateAt);
  const azureUserdate = useSelector(azureSelectUpdateAt);
  const adUserIsTableDisplayable = useSelector(selectIsTableDisplayable);
  const permissions = useSelector(selectPermissions);
  const azureGroups = useSelector(selectAzureGroupsGraph);
  const adGroups = useSelector(selectedGroups(''));

  const navigate = useNavigate();

  const [selectedIndexRows, setSelectedIndexRows] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [popupDel, setPopupDel] = useState(false);
  const [popupGroups, setPopupGroups] = useState(false);
  const [isRemoveGroups, setIsRemoveGroups] = useState(false);
  const [usersGroupsPopup, setUsersGroupsPopup] = useState(false);
  const [isStepChangeOU, setIsStepChangeOU] = useState(false);
  const [popupCreateBulk, setPopupCreateBulk] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [selectedSyncro, setSelectedSynchro] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [formDataBulk, setFormDataBulk] = useState(null);
  const dispatchGetAllAzureColumns = useCallback(async () => {
    try {
      await dispatch(
        getProviderColumns({ providerName: 'AZURE_ACTIVE_DIRECTORY', isBackOffice: false })
      ).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  }, [dispatch, navigate]);

  const dispatchGetAllADUserColumns = async () => {
    try {
      await dispatch(
        getProviderColumns({ providerName: 'ACTIVE_DIRECTORY', isBackOffice: false })
      ).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };
  const renderAfterCalled = useRef(false);

  useEffect(() => {
    if (!renderAfterCalled.current) {
      dispatch(resetColumn());
      Promise.all([
        dispatchGetAllAzureColumns(),
        dispatchGetAllADUserColumns(),
        dispatch(getAzureGroupsGraph()),
        dispatch(findAdUsers()),
        dispatch(getDisableText()),
        dispatch(getGroupsAd()),
        dispatch(getLicensesAzure()),
        dispatch(requestAzureUserDomains()),
        dispatch(getAzureUserAvailableServices()),
        dispatch(findAzureUsers())
      ]).catch((error) => errorRedirection(error.code, navigate));
    }

    renderAfterCalled.current = true;
    return function cleanup() {
      dispatch(resetAzureUserState());
      dispatch(resetAzureLicenseState());
      dispatch(resetColumn());
      dispatch(resetAdUserState());
    };
  }, [dispatch]);

  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRefresh = async () => {
    setPage(0);
    setTableSelectedRows([]);
    dispatch(refreshAdUsers());
    dispatch(refreshAzureUsers());
  };
  const openPopupCreateBulk = () => {
    setIsStepChangeOU(false);
    setPopupCreateBulk(true);
  };
  const setBulk = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    setFormDataBulk(formData);
  };
  const sendFileCreateBulk = async () => {
    await dispatch(createAdUsersBulk(formDataBulk));
    setPopupCreateBulk(false);
  };

  const sendFileDeleteBulk = async () => {
    await dispatch(deleteAdUsersBulk(formDataBulk));
    setPopupCreateBulk(false);
  };

  const handleSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    setPage(0);
  };

  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];

    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map(
      (rowIndex) => selectSynchroAdAzure[rowIndex === null ? 0 : rowIndex]
    );
    const isUserEnabled = copyRows.map(
      (rowIndex) => selectSynchroAdAzure[rowIndex === null ? 0 : rowIndex]?.isUserEnable
    );

    const isDisabled = copyRows.map(
      (rowIndex) => selectSynchroAdAzure[rowIndex === null ? 0 : rowIndex]?.isSynchronize
    );
    setSelectedSynchro(isDisabled);
    setIsEnabled(isUserEnabled);
    setTableSelectedRows(tempRows);
  };
  const resetSelectedRows = () => {
    setTableSelectedRows([]);
    setSelectedIndexRows([]);
  };

  const handleEnableDisableADUser = async (users, isUserEnable) => {
    await dispatch(enableDisableAdUser({ users, isUserEnable }));
  };

  const openPopup = () => {
    setIsStepChangeOU(false);
    setIsOpen(true);
  };
  const closePopup = () => {
    setIsOpen(false);
  };

  const optionsKeys = {
    titleNameKey: 'userPrincipalNameSynchro',
    secondaryTitleKey: 'onPremisesLastSyncDateTimeAzure',
    customStatus: sycnhroCustomStatus
  };

  const isDisabled =
    tableSelectedRows.length === 1
      ? false
      : selectSynchroAdAzure.findIndex((elem) => elem.isUserEnable === true) > -1 &&
        selectSynchroAdAzure.findIndex((elem) => elem.isUserEnable === false) > -1;

  const buttons = [
    {
      disabled: false,
      datatestid: 'AdCreateUser',
      isDisplayable: hasPermissions(permissions, 'SET_AD_USERS'),
      onClick: () => openPopup(),
      text: t('addUser'),
      icon: <PersonAddAlt1Icon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: false,
      datatestid: 'AdCreateUserBulk',
      isDisplayable: hasPermissions(permissions, 'SET_AD_USERS'),
      onClick: () => openPopupCreateBulk(),
      text: t('importCsv'),
      icon: <UploadFileIcon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: selectedSyncro.includes('noAd') || tableSelectedRows.length === 0,
      datatestid: 'removeADGroups',
      isDisplayable: hasPermissions(permissions, 'DELETE_GROUPS_AD_USERS'),
      onClick: () => {
        setIsRemoveGroups(true);
        setPopupGroups(true);
      },
      text: t('removeADGroups'),
      icon: <GroupRemoveIcon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: selectedSyncro.includes('noAd') || tableSelectedRows.length === 0,
      datatestid: 'assignADGroups',
      isDisplayable: hasPermissions(permissions, 'ASSIGN_GROUPS_AD_USERS'),
      onClick: () => {
        setIsRemoveGroups(false);
        setPopupGroups(true);
      },
      text: t('assignADGroups'),
      icon: <GroupAddIcon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: selectedSyncro.includes('noAd') || tableSelectedRows.length === 0 || isDisabled,
      datatestid: 'ADUpdateStatus',
      isDisplayable: hasPermissions(permissions, 'SET_AD_USERS'),
      onClick: async () => {
        await handleEnableDisableADUser(
          tableSelectedRows.map(({ distinguishedName }) => distinguishedName),
          isEnabled[0]
        );
        resetSelectedRows();
      },
      text: t(`${isEnabled[0] ? `disablUserAD` : `enableAccountAzureUser`}`),
      icon: isEnabled[0] ? (
        <UnpublishedIcon sx={styleActionIconMobileUsersAzure} />
      ) : (
        <PublishedWithChangesIcon sx={styleActionIconMobileUsersAzure} />
      )
    },
    {
      disabled: selectedSyncro.includes('noAd') || tableSelectedRows.length === 0,
      datatestid: 'deleteAdUser',
      isDisplayable: hasPermissions(permissions, 'DELETE_AD_USERS'),
      onClick: () => setPopupDel(true),
      text: t('delUser'),
      icon: <DeleteForeverIcon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: selectedSyncro.includes('noAzure') || tableSelectedRows.length === 0,
      datatestid: 'AzureAssignGroups',
      isDisplayable: hasPermissions(permissions, 'SET_AZURE_GROUPS_TO_USER'),
      onClick: () => {
        setIsRemoveGroups(false);
        setUsersGroupsPopup(true);
      },
      text: t('assignAzureGroups'),
      icon: <GroupAddIcon sx={styleActionIconMobileUsersAzure} />,
      isAzure: true
    },
    {
      disabled: selectedSyncro.includes('noAzure') || tableSelectedRows.length === 0,
      datatestid: 'AzureRemoveGroups',
      isDisplayable: hasPermissions(permissions, 'SET_REMOVE_AZURE_GROUPS_TO_USER'),
      onClick: () => {
        setIsRemoveGroups(true);
        setUsersGroupsPopup(true);
      },
      text: t('removeAzureGroups'),
      icon: <GroupRemoveIcon sx={styleActionIconMobileUsersAzure} />,
      isAzure: true
    }
  ];

  const searchBar = {
    isDisplayable: true,
    disabled: false
  };

  const listIsUserEnable = ['Inactif', 'Actif', 'Tous'];
  const listIsUserSynchro = [{ name: 'synchronized' }, { name: 'noAd' }, { name: 'noAzure' }];

  const linkOptions = {
    linkColumn: 'userPrincipalNameSynchro',
    linkTo1: 'distinguishedName',
    linkTo2: 'idAzure'
  };

  const columnOptions = [
    { name: 'displayName', display: false },
    {
      name: 'userPrincipalNameSynchro',
      display: true,
      type: 'CustomLinkInTable',
      linkOptions
    },
    {
      name: 'isSynchronize',
      type: 'isSynchroInTable',
      display: true,
      filter: true,
      filterListOptions: listIsUserSynchro
    },
    {
      name: 'onPremisesLastSyncDateTimeAzure',
      display: true
    },
    {
      type: 'PathDistinguishedName',
      name: 'onPremisesDistinguishedNameAzure',
      display: false
    },
    {
      name: 'cn',
      display: true
    },
    {
      name: 'isUserEnable',
      type: 'CheckedIconInTable',
      display: true,
      filter: true,
      filterListOptions: listIsUserEnable
    },
    {
      name: 'memberOf',
      type: 'ChipList',
      display: true,
      filter: true,
      filterListOptions: adGroups
    },
    { name: 'sn', display: false },
    { name: 'userPrincipalName', display: true },
    {
      type: 'PathDistinguishedName',
      name: 'distinguishedName',
      display: false
    },

    { name: 'userPrincipalNameAzure', display: true },
    {
      name: 'accountEnabledAzure',
      type: 'CheckedIconInTable',
      display: true,
      filter: true,
      filterListOptions: listIsUserEnable
    },
    { name: 'usageLocationAzure', display: false },
    { name: 'surnameAzure', display: false },

    { name: 'preferredLanguageAzure', display: false },

    { name: 'officeLocationAzure', display: false },

    { name: 'mobilePhoneAzure', display: false },

    { name: 'mailNicknameAzure', display: false },

    { name: 'mailAzure', display: false },

    {
      name: 'memberOfAzure',
      type: 'ChipList',
      display: true,
      filter: true,
      filterListOptions: azureGroups?.map((group) => ({ name: group.displayName }))
    },

    { name: 'jobTitleAzure', display: false },

    { name: 'givenNameAzure', display: false },

    { name: 'employeeIdAzure', display: false },

    { name: 'displayNameAzure', display: false },

    { name: 'departmentAzure', display: false },

    { name: 'businessPhonesAzure', display: false },

    { name: 'accountEnabledAzure', display: true }
  ];
  const formatedColumns = formatColumnsForTable(
    [
      'userPrincipalNameSynchro',
      'isSynchronize',
      'onPremisesLastSyncDateTimeAzure',
      'onPremisesDistinguishedNameAzure',
      'userPrincipalName',
      'displayName',
      'cn',
      'isUserEnable',
      'memberOf',
      'sn',
      'distinguishedName',
      'userPrincipalNameAzure',
      'accountEnabledAzure',
      'officeLocationAzure',
      'mobilePhoneAzure',
      'mailNicknameAzure',
      'mailAzure',
      'memberOfAzure',
      'jobTitleAzure',
      'givenNameAzure',
      'employeeIdAzure',
      'displayNameAzure',
      'departmentAzure',
      'businessPhonesAzure'
    ],
    selectSynchroAdAzure,
    columnOptions,
    t
  );

  const desktopConfig = {
    actions: {
      handleRefresh,
      handleSearchTerm
    },

    buttons,
    searchBar,

    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: selectSynchroAdAzure
    },

    loading: {
      itemsListIsLoading: usersListIsLoading && azureUsersListIsLoading,
      isTableDisplayable: adUserIsTableDisplayable
    },

    tables: {
      optionsKeys,
      formatedColumns,
      date: azureUserdate && adUserdate,
      totalItems,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      selectedIndexRows,
      setTotalItems
    },

    search: {
      searchTerm,
      setSearchTerm
    },

    text: {
      titleText: t('synchroAdAzure'),
      bigTitleText: t('managerSynchroAdAzure'),
      selectedItemsText: t('selectedUsers'),
      tagId: 'SynchroAdAzure'
    },

    linkOptions
  };

  return (
    <Grid>
      <LdapcreateADUserPopup
        isOpen={isOpen}
        closePopup={closePopup}
        isStepChangeOU={isStepChangeOU}
        setIsStepChangeOU={setIsStepChangeOU}
      />
      <AdDeleteUserPopup
        popup={popupDel}
        closePopup={() => setPopupDel(false)}
        selectedUsers={tableSelectedRows.map(({ distinguishedName }) => distinguishedName)}
        resetSelectedRows={resetSelectedRows}
      />
      <AdGroupsPopup
        popup={popupGroups}
        closePopup={() => setPopupGroups(false)}
        selectedUsers={tableSelectedRows.map(({ distinguishedName }) => distinguishedName)}
        isRemove={isRemoveGroups}
        resetSelectedRows={resetSelectedRows}
      />
      <CsvBulkPopup
        open={popupCreateBulk}
        handleClose={() => setPopupCreateBulk(false)}
        handleActions={{ create: sendFileCreateBulk, delete: sendFileDeleteBulk }}
        setFormDataBulk={setBulk}
        actionTitle={t('importCsv')}
      />
      <AzureUsersGroupsPopup
        selectedUsers={tableSelectedRows.map(({ idAzure }) => idAzure)}
        isRemove={isRemoveGroups}
        popup={usersGroupsPopup}
        closePopup={() => setUsersGroupsPopup(false)}
        resetSelectedRows={resetSelectedRows}
      />
      <ItemsList config={desktopConfig} />
    </Grid>
  );
}

export default SynchroAdAzure;
