import React, { useState } from 'react';
import { Switch } from '@mui/material';
import { useDispatch } from 'react-redux';
import { switchTheme } from '../../redux/slices/userConnected';

export default function ThemeSombre() {
  const dispatch = useDispatch();
  const [isBlack, setIsBlack] = useState(() => {
    return localStorage.getItem('isBlack') === 'true';
  });
  const handleChange = () => {
    const newValue = !isBlack;
    setIsBlack(newValue);
    dispatch(switchTheme());
    localStorage.setItem('isBlack', newValue);
  };

  return (
    <Switch
      color="primary"
      value={isBlack}
      onChange={handleChange}
      size="large"
      data-testid="select-isBlack"
      defaultChecked={isBlack}
    />
  );
}
