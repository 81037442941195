import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, FormControl, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import iso31661 from 'iso-3166-1';
import iso6391 from 'iso-639-1';
import { styleDialogActions } from '../../assets/style/views/AzurADUserPopup';
import CustomizedButton from '../buttons/CustomizedButton';
import { azureColumnsRules } from '../../utils/regex';
import {
  selectAllDomains,
  selectAzureUserAvailableServices
} from '../../redux/slices/AzureUsersList';
import { styleScrollbar } from '../../assets/style/utils/utils';
import { selectColumnsCreation } from '../../redux/slices/columns';
import CustomFormField from '../form/CustomFormField';

export default function AzureADCreateUserPopup({ popup, closePopup, createAzureUser, azureUsers }) {
  const { t } = useTranslation();
  const [createdUser, setCreatedUser] = useState({});
  const listAllDomain = useSelector(selectAllDomains);
  const [domain, setDomain] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const azureUserAvailableServices = useSelector(selectAzureUserAvailableServices);
  const azureColumns = useSelector(selectColumnsCreation).filter(
    ({ providerName }) => providerName === 'AZURE_ACTIVE_DIRECTORY'
  );

  const findArgSForRegexByAzureColumn = (azureColumn) => {
    const azureUsersDisplayName = azureUsers.map((elem) => elem.displayName);
    const azureUsersDisplayNameUnique = azureUsersDisplayName.filter(
      (value, index) => azureUsersDisplayName.indexOf(value) === index
    );

    switch (azureColumn) {
      case 'manager':
        return azureUsersDisplayNameUnique;
      case 'department':
        return azureUserAvailableServices;
      case 'usageLocation':
        return iso31661.all().map((elem) => `${elem.country} - ${elem.alpha2}`);
      case 'preferredLanguage':
        return iso6391.getAllNames();
      case 'password':
        return `${createdUser.givenName?.toLowerCase()} ${createdUser.surname?.toUpperCase()}`;
      default:
        return undefined;
    }
  };

  const error = (azureColumn, isNullable, isEditable) => {
    if (
      isEditable &&
      azureColumn in azureColumnsRules &&
      ((!isNullable && !createdUser[azureColumn]) ||
        (createdUser[azureColumn] &&
          !azureColumnsRules[azureColumn].rule(
            createdUser[azureColumn],
            findArgSForRegexByAzureColumn(azureColumn)
          )))
    ) {
      return true;
    }
    return false;
  };

  const formatUser = () => {
    const formattedUser = { ...createdUser };
    if ('preferredLanguage' in formattedUser)
      formattedUser.preferredLanguage = iso6391.getCode(formattedUser.preferredLanguage);
    if ('usageLocation' in formattedUser)
      formattedUser.usageLocation = formattedUser.usageLocation.split('-')[1].trim();
    if ('manager' in formattedUser) {
      const i = azureUsers.findIndex((e) => e.displayName === formattedUser.manager);
      if (i > -1) {
        formattedUser.manager = azureUsers[i].id;
      }
    }
    return formattedUser;
  };

  const handleClick = () => {
    setIsSubmit(true);
    const fieldErrorStatus = azureColumns.map(({ columnId, isNullable, isEditable }) => {
      return error(columnId, isNullable, isEditable);
    });

    if (!fieldErrorStatus.includes(true)) {
      createAzureUser({
        ...formatUser(),
        userPrincipalName: `${createdUser.userPrincipalName}@${domain}`
      });
    }
  };

  const isThereError = azureColumns
    .map(({ columnId, isEditable, isNullable }) => error(columnId, isNullable, isEditable))
    .includes(true);

  useEffect(() => {
    if (!listAllDomain || listAllDomain.length === 0) {
      setDomain(listAllDomain[0]);
    }
  }, [listAllDomain]);

  return (
    <Dialog
      open={popup}
      onClose={closePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="CreateAzureUserPopup"
      PaperProps={{ sx: styleScrollbar }}
    >
      <DialogContent sx={styleScrollbar}>
        <FormControl
          sx={(theme) => ({ '& label.Mui-focused': { color: theme.palette.colorBlack } })}
        >
          {azureColumns.map(({ columnId, columnName, input, isEditable, isNullable }) => (
            <Grid key={columnId}>
              <CustomFormField
                columnId={columnId}
                columnName={columnName}
                input={input}
                isEditable={isEditable}
                isNullable={isNullable}
                valueObject={createdUser}
                setValueObject={setCreatedUser}
                error={error}
                isSubmit={isSubmit}
                ruleMessageKey={azureColumnsRules[columnName]?.ruleMessageKey}
                domain={domain}
                setDomain={setDomain}
                listAllDomain={listAllDomain}
                findArgsForRegexByColumnName={findArgSForRegexByAzureColumn}
              />
            </Grid>
          ))}
        </FormControl>
      </DialogContent>
      <DialogActions sx={styleDialogActions}>
        <CustomizedButton onClick={closePopup} text={t('cancel')} datatestid="closePopup" />
        <CustomizedButton
          onClick={handleClick}
          text={t('save')}
          disabled={isThereError || domain?.length === 0}
          datatestid="ButtonCreateAzureUser"
        />
      </DialogActions>
    </Dialog>
  );
}

AzureADCreateUserPopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  createAzureUser: PropTypes.func.isRequired,
  azureUsers: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.objectOf(
              PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
            )
          ])
        ),
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.arrayOf(
              PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.objectOf(
                  PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
                )
              ])
            )
          ])
        )
      ])
    )
  ).isRequired
};
