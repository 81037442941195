import React from 'react';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StandardBox from '../StandardBox';
import InformationList from '../InformationList';
import { selectedUser, enableDisableJiraUser } from '../../redux/slices/JiraUsers';
import hasPermissions from '../../utils/Permissions/permissions';
import { selectPermissions } from '../../redux/slices/userConnected';
import { selectColumnsInDetails } from '../../redux/slices/columns';

export default function JiraUserDescription() {
  const user = useSelector(selectedUser);
  const displayColumns = useSelector(selectColumnsInDetails);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);

  const formatColumnsArray = (columnsArray) => {
    return columnsArray
      .filter(({ columnId }) => columnId !== 'key' && columnId !== 'groups')
      .map(({ columnId, columnName }) => {
        switch (columnId) {
          case 'active':
          case 'deleted':
            return { name: columnName, key: columnId, type: 'icon' };
          default:
            return { name: columnName, key: columnId, type: 'string' };
        }
      });
  };

  const listButton = [
    {
      hasPermissions: hasPermissions(permissions, 'SET_JIRA_USER_ENABLE'),
      disabled: !hasPermissions(permissions, 'SET_JIRA_USER_ENABLE'),
      text: user.active === true ? `${t('disable')}` : `${t('enable')}`,
      datatestid: 'enableDisableJiraDevice',
      onClick: () => dispatch(enableDisableJiraUser(user.key))
    }
  ];

  return (
    <Grid>
      <StandardBox
        title="Jira"
        buttons={listButton}
        ChildComponent={<InformationList row={user} columns={formatColumnsArray(displayColumns)} />}
      />
    </Grid>
  );
}
