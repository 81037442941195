import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useNavigate } from 'react-router-dom';
import { selectPermissions } from '../../../redux/slices/userConnected';
import hasPermissions from '../../../utils/Permissions/permissions';
import { formatColumnsForTable } from '../../../components/datalist/ComponentsInTable';
import {
  findAdUsers,
  getDisableText,
  refreshAdUsers,
  resetAdUserState,
  selectAdUsers,
  selectIsLoading,
  selectIsTableDisplayable,
  selectUpdateAt,
  enableDisableAdUser,
  getGroupsAd,
  selectedAdUsersStatus,
  selectedGroups,
  createAdUsersBulk,
  deleteAdUsersBulk,
  editAdUsersBulk,
  resetUsersList
} from '../../../redux/slices/AdUsers';
import LdapcreateADUserPopup from '../../../components/popup/LdapcreateADUserPopup';
import AdDeleteUserPopup from '../../../components/popup/AdDeleteUserPopup';
import AdGroupsPopup from '../../../components/popup/AdGroupsPopup';
import CsvBulkPopup from '../../../components/popup/CsvBulkPopup';
import { styleActionIconMobileUsersAzure } from '../../../assets/style/views/UserPages';
import ItemsList from '../ItemsList';
import {
  getProviderColumns,
  resetColumn,
  selectColumnsDisplayed
} from '../../../redux/slices/columns';
import errorRedirection from '../../../utils/errorRedirection';

export default function AdUsers() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const selectedAdUsers = useSelector(selectAdUsers(searchTerm));
  const adUsers = useSelector(selectAdUsers(searchTerm));
  const adColumnsDisplayed = useSelector(selectColumnsDisplayed).map(({ columnId }) => columnId);
  const navigate = useNavigate();

  const date = useSelector(selectUpdateAt);

  const [totalItems, setTotalItems] = useState(0);

  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);

  const permissions = useSelector(selectPermissions);
  const [isOpen, setIsOpen] = useState(false);
  const [isStepChangeOU, setIsStepChangeOU] = useState(false);
  const [popupDel, setPopupDel] = useState(false);
  const [popupGroups, setPopupGroups] = useState(false);
  const [popupCreateBulk, setPopupCreateBulk] = useState(false);
  const [isRemoveGroups, setIsRemoveGroups] = useState(false);
  const [formDataBulk, setFormDataBulk] = useState(null);

  const usersListIsLoading = useSelector(selectIsLoading);
  const isTableDisplayable = useSelector(selectIsTableDisplayable);
  const adUsersStatus = useSelector(
    selectedAdUsersStatus(tableSelectedRows.map(({ distinguishedName }) => distinguishedName))
  );

  const sendFileCreateBulk = async () => {
    await dispatch(createAdUsersBulk(formDataBulk));
    setPopupCreateBulk(false);
  };

  const sendFileDeleteBulk = async () => {
    await dispatch(deleteAdUsersBulk(formDataBulk));
    setPopupCreateBulk(false);
  };

  const sendFileEditBulk = async () => {
    await dispatch(editAdUsersBulk(formDataBulk));
    dispatch(resetUsersList());
    dispatch(findAdUsers());
    setPopupCreateBulk(false);
  };

  const resetSelectedRows = () => {
    setTableSelectedRows([]);
    setSelectedIndexRows([]);
  };

  const openPopupCreateBulk = () => {
    setIsStepChangeOU(false);
    setPopupCreateBulk(true);
  };

  const setBulk = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    setFormDataBulk(formData);
  };

  const openPopup = () => {
    setIsStepChangeOU(false);
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const dispatchGetAllADUserColumns = async () => {
    try {
      await dispatch(
        getProviderColumns({ providerName: 'ACTIVE_DIRECTORY', isBackoffice: false })
      ).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };
  useEffect(() => {
    dispatch(findAdUsers());
    dispatch(getDisableText());
    dispatch(getGroupsAd());
    dispatchGetAllADUserColumns();
    return function cleanup() {
      dispatch(resetColumn());
      dispatch(resetAdUserState());
    };
  }, [dispatch]);

  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const handleRefresh = async () => {
    setPage(0);
    resetSelectedRows();
    dispatch(refreshAdUsers());
  };
  const handleSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(() => value);
    setPage(0);
  };

  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];

    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map((rowIndex) => selectedAdUsers[rowIndex === null ? 0 : rowIndex]);
    setTableSelectedRows(tempRows);
  };

  const handleEnableDisableADUser = async (users, isUserEnable) => {
    await dispatch(enableDisableAdUser({ users, isUserEnable }));
  };

  const searchBar = {
    isDisplayable: true,
    disabled: false
  };

  const isDisabled =
    tableSelectedRows.length === 1
      ? false
      : adUsersStatus.findIndex((elem) => elem.isUserEnable === true) > -1 &&
        adUsersStatus.findIndex((elem) => elem.isUserEnable === false) > -1;

  const buttons = [
    {
      disabled: false,
      datatestid: 'AdCreateUser',
      isDisplayable: hasPermissions(permissions, 'SET_AD_USERS'),
      onClick: () => openPopup(),
      text: t('addUser'),
      icon: <PersonAddAlt1Icon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: false,
      datatestid: 'AdCreateUserBulk',
      isDisplayable: hasPermissions(permissions, 'SET_AD_USERS'),
      onClick: () => openPopupCreateBulk(),
      text: t('importCsv'),
      icon: <UploadFileIcon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: tableSelectedRows.length === 0,
      datatestid: 'removeADGroups',
      isDisplayable: hasPermissions(permissions, 'DELETE_GROUPS_AD_USERS'),
      onClick: () => {
        setIsRemoveGroups(true);
        setPopupGroups(true);
      },
      text: t('removeADGroups'),
      icon: <GroupRemoveIcon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: tableSelectedRows.length === 0,
      datatestid: 'assignADGroups',
      isDisplayable: hasPermissions(permissions, 'ASSIGN_GROUPS_AD_USERS'),
      onClick: () => {
        setIsRemoveGroups(false);
        setPopupGroups(true);
      },
      text: t('assignADGroups'),
      icon: <GroupAddIcon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: tableSelectedRows.length === 0 || isDisabled,
      datatestid: 'ADUpdateStatus',
      isDisplayable: hasPermissions(permissions, 'SET_AD_USERS'),
      onClick: async () => {
        await handleEnableDisableADUser(
          tableSelectedRows.map(({ distinguishedName }) => distinguishedName),
          adUsersStatus[0]?.isUserEnable
        );
        resetSelectedRows();
      },
      text: t(
        `${adUsersStatus[0]?.isUserEnable === true ? `disablUserAD` : `enableAccountAzureUser`}`
      ),
      icon:
        adUsersStatus[0]?.isUserEnable === false ? (
          <PublishedWithChangesIcon sx={styleActionIconMobileUsersAzure} />
        ) : (
          <UnpublishedIcon sx={styleActionIconMobileUsersAzure} />
        )
    },
    {
      disabled: tableSelectedRows.length === 0,
      datatestid: 'deleteAdUser',
      isDisplayable: hasPermissions(permissions, 'DELETE_AD_USERS'),
      onClick: () => setPopupDel(true),
      text: t('delUser'),
      icon: <DeleteForeverIcon sx={styleActionIconMobileUsersAzure} />
    }
  ];
  const adGroups = useSelector(selectedGroups(''));
  const listIsUserEnable = ['Inactif', 'Actif', 'Tous'];

  const columnOptions = [
    {
      name: 'isUserEnable',
      type: 'CheckedIconInTable',
      display: true,
      filter: true,
      filterListOptions: listIsUserEnable
    },
    {
      name: 'cn',
      type: 'LinkInTable',
      linkOptions: { linkColumn: 'cn', linkTo: 'distinguishedName' },
      display: true
    },
    {
      name: 'memberOf',
      type: 'ChipList',
      display: true,
      filter: true,
      filterListOptions: adGroups
    },
    {
      type: 'PathDistinguishedName',
      name: 'distinguishedName',
      display: true
    },
    { name: 'displayName' }
  ];

  const optionsKeys = {
    titleNameKey: 'name',
    secondaryTitleKey: 'distinguishedName',
    statusKey: 'isUserEnable'
  };

  const formatedColumns = formatColumnsForTable(adColumnsDisplayed, adUsers, columnOptions, t);

  const desktopConfig = {
    actions: {
      handleRefresh,
      handleSearchTerm
    },

    buttons,
    searchBar,

    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: adUsers
    },

    loading: {
      itemsListIsLoading: usersListIsLoading,
      isTableDisplayable
    },

    tables: {
      optionsKeys,
      formatedColumns,
      date,
      totalItems,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      selectedIndexRows,
      setTotalItems
    },

    search: {
      searchTerm,
      setSearchTerm
    },

    text: {
      titleText: t('adUsers'),
      bigTitleText: t('manageUsers'),
      selectedItemsText: t('selectedUsers'),
      tagId: 'AdUsers'
    }
  };

  return (
    <Grid>
      <LdapcreateADUserPopup
        isOpen={isOpen}
        closePopup={closePopup}
        isStepChangeOU={isStepChangeOU}
        setIsStepChangeOU={setIsStepChangeOU}
      />
      <AdDeleteUserPopup
        popup={popupDel}
        closePopup={() => setPopupDel(false)}
        selectedUsers={tableSelectedRows.map(({ distinguishedName }) => distinguishedName)}
        resetSelectedRows={resetSelectedRows}
      />
      <AdGroupsPopup
        popup={popupGroups}
        closePopup={() => setPopupGroups(false)}
        selectedUsers={tableSelectedRows.map(({ distinguishedName }) => distinguishedName)}
        isRemove={isRemoveGroups}
        resetSelectedRows={resetSelectedRows}
      />
      <CsvBulkPopup
        open={popupCreateBulk}
        handleClose={() => setPopupCreateBulk(false)}
        handleActions={{
          create: sendFileCreateBulk,
          delete: sendFileDeleteBulk,
          edit: sendFileEditBulk
        }}
        setFormDataBulk={setBulk}
        actionTitle={t('importCsv')}
      />
      <ItemsList config={desktopConfig} />
    </Grid>
  );
}
