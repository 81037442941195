import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  TablePagination,
  MenuItem,
  Grid,
  FormControl,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  styleFormControl,
  colorJumpPage,
  numberPage,
  styleToolbar,
  styleToolbarMobile,
  styledPaginationDesktop,
  typographyPaginationDesktop
} from '../../assets/style/views/UserPages';
import fillMenuItemList from '../../utils/Pagination/fillSelect';
import { menuProps, selectProps } from '../../assets/style/utils/utils';

export default function CustomizedPagination({
  page,
  setPage,
  count,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  isMobile,
  jumpPageNumber,
  rowsPerPageOptions
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const menuItems = fillMenuItemList(Math.ceil(count / rowsPerPage));
  const handleJumpPageChange = (event) => {
    const newPage = parseInt(event.target.value, 10);
    if (newPage > 0 && newPage <= Math.ceil(count / rowsPerPage)) {
      setPage(newPage);
    }
  };
  const handlePageChange = (event) => {
    setPage(event.target.value);
  };
  const labelPaginationDisplay = () => {
    const from = page * rowsPerPage;
    const to = from + rowsPerPage;
    return `${from}–${to} ${t('paginationPageLabel')} ${count}`;
  };

  const menuJumpPageButton = (
    <FormControl size="small" sx={styleFormControl}>
      <Select
        value={page}
        onChange={(e) => {
          handlePageChange(e);
          onPageChange(e);
        }}
        sx={numberPage}
        onBlur={handleJumpPageChange}
        MenuProps={menuProps(theme)}
      >
        {menuItems
          .filter((menuItem) => {
            if (!jumpPageNumber) return true;
            if (Math.abs(menuItem - page) < jumpPageNumber) return true;
            if ((menuItem + 1) % jumpPageNumber === 0) return true;
            return false;
          })
          .map((menuItem) => (
            <MenuItem value={menuItem} key={menuItem} sx={colorJumpPage}>
              {(menuItem + 1).toString()}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );

  return (
    <Grid sx={styledPaginationDesktop}>
      {!isMobile ? (
        <>
          <Typography sx={typographyPaginationDesktop}>{t('paginationJump')}</Typography>
          {menuJumpPageButton}
        </>
      ) : null}

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        labelRowsPerPage={isMobile ? '' : t('paginationLabel')}
        labelDisplayedRows={labelPaginationDisplay}
        sx={!isMobile ? styleToolbar : styleToolbarMobile}
        SelectProps={selectProps(theme)}
      />
      {!isMobile ? null : menuJumpPageButton}
    </Grid>
  );
}

CustomizedPagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  jumpPageNumber: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number)
};

CustomizedPagination.defaultProps = {
  setPage: () => {},
  jumpPageNumber: 0,
  rowsPerPageOptions: [10, 25, 50],
  isMobile: false
};
