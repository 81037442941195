import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  FormControl,
  Grid
} from '@mui/material';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import CustomizedButton from '../buttons/CustomizedButton';
import { styleScrollbar } from '../../assets/style/utils/utils';
import { styleTitleHeadPageDescript, styledTitle } from '../../assets/style/views/User';
import CustomFormField from '../form/CustomFormField';
import { tab } from '../../utils/dayforceConst';
import { editDayforceName } from '../../redux/slices/DayforceUsers';

export default function DayforceEditPopup({ popup, closePopup, user }) {
  const dispatch = useDispatch();
  const [modifiedUser, setModifiedUser] = useState(user);
  const columns = tab.filter((column) => column.isInEdit);

  const handleClick = async () => {
    await dispatch(editDayforceName(modifiedUser));
    closePopup();
  };

  return (
    <Dialog open={popup} onClose={closePopup}>
      <DialogContent sx={styleScrollbar}>
        <Box sx={styledTitle}>
          <Typography sx={styleTitleHeadPageDescript}>{t('editUser')}</Typography>
        </Box>
        <FormControl
          sx={(theme) => ({ '& label.Mui-focused': { color: theme.palette.colorBlack }, gap: 2 })}
        >
          {user &&
            columns &&
            columns.map(({ columnId, columnName, input, isEditable, isNullable }) => (
              <Grid key={columnId}>
                <CustomFormField
                  columnId={columnId}
                  columnName={columnName}
                  input={input}
                  isEditable={isEditable}
                  isNullable={isNullable}
                  valueObject={modifiedUser}
                  setValueObject={setModifiedUser}
                  error={() => {}}
                  isSubmit={false}
                />
              </Grid>
            ))}
        </FormControl>
        <DialogActions>
          <CustomizedButton
            datatestid="closePopup"
            text={t('cancel')}
            onClick={() => closePopup()}
          />
          <CustomizedButton
            datatestid="ButtonUpdateDayforceUser"
            text={t('save')}
            onClick={handleClick}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

DayforceEditPopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
          )
        ])
      ),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number,
          PropTypes.arrayOf(
            PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.objectOf(
                PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
              )
            ])
          )
        ])
      )
    ]),
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ).isRequired
};
