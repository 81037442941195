import React from 'react';
import PropTypes from 'prop-types';
import DataListMuiTable from './DataListMuiTable';
import LoadingWrapper from '../layout/LoadingWrapper';

export default function DataList({
  columns,
  rows,
  page,
  rowsPerPage,
  setTableSelectedRows,
  disableCheckBox,
  selectedIndexRows,
  isDisplayable,
  searchTerm,
  setTotalItems,
  onFilterChange,
  rowsPerPageOptions
}) {
  return (
    <LoadingWrapper isChildDisplayable={isDisplayable}>
      <DataListMuiTable
        columns={columns}
        rows={rows}
        page={page}
        rowsPerPage={rowsPerPage}
        setTableSelectedRows={setTableSelectedRows}
        selectedIndexRows={selectedIndexRows}
        disableCheckBox={disableCheckBox}
        searchTerm={searchTerm}
        setTotalItems={setTotalItems}
        onFilterChange={onFilterChange}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </LoadingWrapper>
  );
}

DataList.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.arrayOf(
              PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.objectOf(
                  PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
                )
              ])
            )
          ])
        ),
        PropTypes.bool,
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired
        ),
        PropTypes.object,
        PropTypes.number
      ])
    )
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
  ).isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setTableSelectedRows: PropTypes.func,
  disableCheckBox: PropTypes.bool,
  selectedIndexRows: PropTypes.arrayOf(PropTypes.number),
  isDisplayable: PropTypes.bool,
  searchTerm: PropTypes.string,
  setTotalItems: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number)
};

DataList.defaultProps = {
  setTableSelectedRows: () => {},
  disableCheckBox: false,
  selectedIndexRows: [],
  isDisplayable: false,
  searchTerm: '',
  onFilterChange: () => {},
  rowsPerPageOptions: [10, 25, 50]
};
