import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StandardBox from '../StandardBox';
import InformationList from '../InformationList';
import { selectedLead } from '../../redux/slices/ActivixLeads';
import ActivixEditLeadPopup from '../popup/ActivixEditLeadPopup';

export default function LeadStandardDescription() {
  const { t } = useTranslation();
  const lead = useSelector(selectedLead);
  const [displayColumns, setDisplayColumns] = useState([]);
  const [editLeadPopup, setEditLeadPopup] = useState(false);
  useEffect(() => {
    if (Object.keys(lead).length !== 0) {
      const columnsDisplay = Object.keys(lead).map((key) => ({ name: key, key, type: 'string' }));
      setDisplayColumns(columnsDisplay);
    }
  }, [lead]);
  const listButton = [
    {
      hasPermissions: true,
      text: t('editLead'),
      datatestid: 'editLeadPopup',
      onClick: () => {
        setEditLeadPopup(true);
      }
    }
  ];

  return (
    <Grid>
      <ActivixEditLeadPopup
        popup={editLeadPopup}
        lead={lead}
        closePopup={() => {
          setEditLeadPopup(false);
        }}
      />
      <StandardBox
        buttons={listButton}
        title="activixLead"
        ChildComponent={<InformationList row={lead} columns={displayColumns} />}
      />
    </Grid>
    // )
  );
}
