import React from 'react';
import { Button, Link, Typography } from '@mui/material';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectIsLogin } from '../../../redux/slices/userConnected';
import store from '../../../redux/store';

export default function Error() {
  const urlParams = useParams();
  const { t } = useTranslation();
  const managedErrors = ['500', '400', '403', '424', '404'];
  const isErrorManaged = urlParams.id && managedErrors.includes(urlParams.id);
  const isLogin = useSelector(selectIsLogin);
  const navigate = useNavigate();
  const logout = () => {
    store.dispatch({ type: 'user/logout' });
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      localStorage.removeItem('dataSession');
      localStorage.removeItem('isBackOffice');
      localStorage.removeItem('isBlack');
    }
    navigate('/');
  };
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Button
        sx={(theme) => ({
          width: 180,
          height: 45,
          backgroundColor: theme.palette.colorMain,
          color: theme.palette.colorMainLight,
          float: 'right',
          position: 'absolute',
          top: 10,
          right: 10
        })}
        variant="text"
        onClick={logout}
      >
        {t('Logout')}
      </Button>
      <Typography sx={{ weight: '900', fontSize: '30px' }} data-testid="message">
        {isErrorManaged ? `Error ${urlParams.id}` : 'Page does not exist'}
      </Typography>
      <Link
        component={RouterLink}
        sx={(theme) => ({ overflow: 'hidden', color: theme.palette.colorMain })}
        to={isLogin ? '/home' : '/login'}
      >
        {isLogin ? 'BACK TO HOME' : 'BACK TO LOGIN'}
      </Link>
    </div>
  );
}
