import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StandardBox from '../StandardBox';
import InformationList from '../InformationList';
import hasPermissions from '../../utils/Permissions/permissions';
import { selectPermissions } from '../../redux/slices/userConnected';
import {
  selectedUser,
  updateAdUser,
  enableDisableAdUser,
  selectDefaultEnableUserText,
  selectDisableText,
  updateAdUserGroups,
  selectedGroups,
  selectUserText
} from '../../redux/slices/AdUsers';
import LdapADUserPopup from '../popup/LdapADUserPopup';
import PopupResetPassword from '../popup/ResetPassword';
import enableDisabledOU from '../../utils/adUsers';
import { selectColumnsInDetails } from '../../redux/slices/columns';

export default function AdUserDescription() {
  const user = useSelector(selectedUser);
  const dispatch = useDispatch();
  const displayColumns = useSelector(selectColumnsInDetails);
  const { t } = useTranslation();
  const permissions = useSelector(selectPermissions);
  const [popupUpdate, setPopupUpdate] = useState(false);
  const [popupPasswordIsOpen, setPopUPpasswordIsOpen] = useState(false);
  const adGroups = useSelector(selectedGroups(''));

  const formatColumnsArray = (columnsArray) => {
    const filteredArray = columnsArray
      .filter(({ columnId }) => columnId !== 'key' && columnId !== 'memberOf')
      .map(({ columnId, columnName }) => {
        switch (columnId) {
          case 'isUserEnable':
            return { name: columnName, key: columnId, type: 'icon' };
          default:
            return { name: columnName, key: columnId, type: 'string' };
        }
      });

    return filteredArray;
  };

  const openPopup = () => {
    setPopupUpdate(true);
  };

  const handleEnableDisableADUser = async (distinguishedName, isUserEnable) => {
    await dispatch(enableDisableAdUser({ users: [distinguishedName], isUserEnable }));
  };

  const handleUpdateADUser = async (modifiedUser) => {
    const res = await dispatch(updateAdUser({ modifiedUser, DN: user.distinguishedName }));

    if (res.payload.success) {
      setPopupUpdate(false);
    }
  };

  const handleUpdateADUserGroups = async (modifiedGroups, oldGroups) => {
    const res = await dispatch(
      updateAdUserGroups({
        user: user.distinguishedName,
        groups: modifiedGroups,
        oldGroups
      })
    );

    if (res.payload.success) {
      setPopupUpdate(false);
    }
  };

  const navigate = useNavigate();
  const userText = useSelector(selectUserText);
  const defaultEnableUserText = useSelector(selectDefaultEnableUserText);
  const disableText = useSelector(selectDisableText);

  const [isStepChangeOU, setIsStepChangeOU] = useState(false);

  const listButton = [
    {
      hasPermissions: hasPermissions(permissions, 'SET_AD_USERS'),
      text: user?.isUserEnable ? t('disableUser') : t('enableUser'),
      datatestid: 'ADActionsEnableDisable',
      onClick: async () => {
        await handleEnableDisableADUser(user.distinguishedName, user.isUserEnable);
        const newDn = enableDisabledOU(
          user.distinguishedName,
          userText,
          disableText,
          defaultEnableUserText
        );
        navigate(`/adUsers/${newDn}`);
      }
    },
    {
      hasPermissions: hasPermissions(permissions, 'SET_AD_USERS'),
      text: `${t('editable')}`,
      datatestid: 'ADActionsEdit',
      onClick: () => {
        openPopup();
        setIsStepChangeOU(false);
      }
    },
    {
      hasPermissions: hasPermissions(permissions, 'SET_AD_USERS'),
      text: `${t('password')}`,
      datatestid: 'ADresetPassword',
      onClick: () => {
        setPopUPpasswordIsOpen(true);
      }
    }
  ];

  return (
    <Grid>
      <StandardBox
        title="AdEntity"
        buttons={listButton}
        ChildComponent={<InformationList row={user} columns={formatColumnsArray(displayColumns)} />}
      />
      <LdapADUserPopup
        popup={popupUpdate}
        user={user}
        closePopup={() => {
          setPopupUpdate(false);
        }}
        updateAdUser={handleUpdateADUser}
        updateAdUserGroups={handleUpdateADUserGroups}
        isStepChangeOU={isStepChangeOU}
        setIsStepChangeOU={setIsStepChangeOU}
        adGroups={adGroups}
      />
      <PopupResetPassword
        popup={popupPasswordIsOpen}
        closePopup={() => {
          setPopUPpasswordIsOpen(false);
        }}
      />
    </Grid>
  );
}
