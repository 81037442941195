import React, { useEffect, useState } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SwitchComponent from './PopupComponent/SwitchComponent';
import CustomizedButton from '../../../../components/buttons/CustomizedButton';

export default function PopUpEditColumns({ open, handleClose, selectedCol, addChange }) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState([]);

  const closePopup = () => {
    handleClose();
  };

  const handleInputChange = (id, name, value) => {
    setFormData((prevFormData) => {
      return prevFormData.map((item) => {
        if (item.id === id) {
          return { ...item, [name]: value };
        }
        return item;
      });
    });
  };

  const dispatchAction = async () => {
    addChange(formData);
    setFormData([]);
    handleClose();
  };

  useEffect(() => {
    setFormData(selectedCol);
  }, [selectedCol]);

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>{t('Columns')}</DialogTitle>
      <form>
        <DialogContent>
          {formData.map(
            (column) =>
              column && (
                <Grid container key={column.id}>
                  <Typography gutterBottom variant="h6">
                    {column.columnId}
                  </Typography>
                  <SwitchComponent
                    switches={Object.keys(column)
                      .filter((property) => typeof column[property] === 'boolean')
                      .map((property) => ({
                        name: property,
                        id: column.id,
                        checked: column[property]
                      }))}
                    onChange={handleInputChange}
                    isEdit
                  />
                  <Divider variant="middle" sx={{ width: '100%', marginY: '1em' }} />
                </Grid>
              )
          )}
        </DialogContent>
      </form>
      <DialogActions>
        <CustomizedButton
          variant="contained"
          datatestid="cancelEditColumn"
          onClick={() => closePopup()}
          text={t('cancel')}
        />
        <CustomizedButton
          variant="contained"
          datatestid="confirmEditColumn"
          type="submit"
          onClick={() => dispatchAction()}
          text={t('save')}
        />
      </DialogActions>
    </Dialog>
  );
}

PopUpEditColumns.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedCol: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      columnId: PropTypes.string,
      columnName: PropTypes.string,
      input: PropTypes.string,
      isDisplayed: PropTypes.bool,
      isEditable: PropTypes.bool,
      isInCreation: PropTypes.bool,
      isInDetails: PropTypes.bool,
      isInEdit: PropTypes.bool,
      isNullable: PropTypes.bool,
      providerName: PropTypes.string
    })
  ),
  addChange: PropTypes.func.isRequired
};

PopUpEditColumns.defaultProps = {
  selectedCol: []
};
