import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Divider,
  List,
  ListItem,
  Grid,
  ListItemText,
  TextField,
  Fab,
  Typography,
  IconButton,
  CircularProgress,
  useTheme,
  Avatar
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import {
  styleChatContainer,
  styleChatArea,
  styleMessageArea,
  styleMessageGrid,
  styleReturnButton,
  styleMessageItem,
  styleMessage,
  styleSendMessageButton,
  styleMessageName,
  styleMessageText,
  styleMessageInput,
  styleMessageAvatar,
  styleSMSIcon
} from '../../assets/style/components/SMS/Chat';
import {
  selectMessages,
  selectPickedConversation,
  updateSelectedMessages,
  selectIsTyping,
  setIsNotRead,
  rcsHandler,
  sendMessage
} from '../../redux/slices/Messages';
import { selectAzureUserByPhone } from '../../redux/slices/AzureUsersList';
import { getDateFormatted } from '../../utils/Date/DateCompare';
import { getLocalTimeFormatted } from '../../utils/Date/DateFunction';
import { selectPermissions } from '../../redux/slices/userConnected';
import hasPermissions from '../../utils/Permissions/permissions';
import { styleScrollbar } from '../../assets/style/utils/utils';

export default function Chat({
  isPhoneNumberExist,
  setIsPhoneNumberExist,
  isMobile,
  isChatOpen,
  setIsChatOpen
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const messages = useSelector(selectMessages);
  const selectedConversation = useSelector(selectPickedConversation);
  const isTyping = useSelector(selectIsTyping);
  const [textFieldEmailValue, setTextFieldEmail] = useState('');
  const permissions = useSelector(selectPermissions);
  const chatList = React.createRef();
  const azureUser = useSelector(selectAzureUserByPhone(selectedConversation.phoneNumber));

  const scrollToBottom = () => {
    chatList.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleReturn = () => {
    setIsChatOpen(false);
  };

  useEffect(() => {
    scrollToBottom();
    if ((!messages || messages.length === 0) && selectedConversation.phoneNumber !== null) {
      setIsPhoneNumberExist(false);
      dispatch(rcsHandler(selectedConversation));
    } else {
      setIsPhoneNumberExist(true);
    }
  }, [messages]);

  const getDateValue = (date) => {
    const localTime = getLocalTimeFormatted(date);
    const dateFormatted = getDateFormatted(date);
    if (dateFormatted === 'yesterday') return `${t(dateFormatted)}`;
    if (dateFormatted !== localTime) return `${dateFormatted}`;
    return `${dateFormatted}`;
  };

  const handleChange = async (e) => {
    setTextFieldEmail(e.target.value);
  };

  function checkMessage(message, index) {
    if (
      index === message.length - 1 &&
      !selectedConversation.isRead &&
      message[message.length - 1].status.name === 'sent'
    ) {
      return <VisibilityIcon sx={styleSMSIcon} />;
    }
    if (index === message.length - 1 && selectedConversation.isRead) {
      return <TaskAltIcon sx={styleSMSIcon} />;
    }
    return '';
  }

  const handleSmsSending = () => {
    if (textFieldEmailValue.length === 0) {
      return;
    }
    let newMessage = {
      phoneNumberId: selectedConversation.phoneNumberId,
      text: textFieldEmailValue
    };
    dispatch(sendMessage(newMessage));
    newMessage = {
      phoneNumber: {
        id: selectedConversation.phoneNumberId,
        name: selectedConversation.phoneNumber
      },
      text: newMessage.text,
      status: {
        id: 3,
        name: 'sent'
      },
      createdTime: `${new Date()}`,
      messageId: null,
      id: `temp${messages.length}`
    };
    dispatch(updateSelectedMessages(newMessage));
    dispatch(setIsNotRead());
    setTextFieldEmail('');
  };

  return selectedConversation.phoneNumber === null ? (
    <Box sx={styleChatContainer(theme, false, false)} />
  ) : (
    <Box sx={styleChatContainer(theme, isMobile, isChatOpen)}>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Grid container alignItems="center">
          {isMobile && (
            <Grid item>
              <IconButton
                type="button"
                aria-label={t('return')}
                onClick={handleReturn}
                sx={styleReturnButton}
              >
                <ArrowBackIcon sx={styleSMSIcon} />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <Avatar sx={styleMessageAvatar} />
          </Grid>
          <Grid item>
            <Typography sx={{ fontWeight: 'bold' }}>
              {azureUser ? azureUser.displayName : selectedConversation.phoneNumber}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <List sx={[styleChatArea, styleScrollbar]}>
        {messages.length === 0 ? (
          <Grid sx={styleMessageGrid}>
            <Typography data-testid="newConversation">{t('engageConversation')}</Typography>
          </Grid>
        ) : (
          messages.map((message, index) => (
            <Grid key={JSON.stringify(index)} ref={chatList}>
              <ListItem sx={styleMessageItem(message.status.name)}>
                <Grid sx={styleMessage(theme, message.status.name)}>
                  <Grid container>
                    <Grid item xs={6} textAlign="left">
                      <ListItemText>
                        <Typography sx={styleMessageName}>
                          {azureUser ? azureUser.displayName : selectedConversation.phoneNumber}
                        </Typography>
                      </ListItemText>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <ListItemText sx={styleMessageText}>
                        {getDateValue(message.createdTime)}
                      </ListItemText>
                    </Grid>
                  </Grid>
                  <ListItemText
                    data-testid={`message${index}`}
                    primary={
                      <Typography sx={styleMessageText} noWrap={false}>
                        {message.text}
                      </Typography>
                    }
                  />
                </Grid>
                {checkMessage(messages, index)}
              </ListItem>
            </Grid>
          ))
        )}
        {isTyping ? <CircularProgress /> : null}
      </List>
      <Divider />
      <Box sx={styleMessageArea}>
        <TextField
          id="textFieldEmail"
          label={
            azureUser
              ? t('writeMessage') + azureUser.displayName
              : t('writeMessage') + selectedConversation.phoneNumber
          }
          onChange={handleChange}
          value={textFieldEmailValue}
          data-testid="textFieldEmail"
          sx={styleMessageInput}
        />
        <Fab
          disabled={!isPhoneNumberExist || !hasPermissions(permissions, 'ADD_SMS')}
          color="primary"
          aria-label="add"
          onClick={handleSmsSending}
          sx={styleSendMessageButton}
        >
          <SendIcon data-testid="sendIcon" />
        </Fab>
      </Box>
    </Box>
  );
}

Chat.propTypes = {
  isPhoneNumberExist: PropTypes.bool.isRequired,
  setIsPhoneNumberExist: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isChatOpen: PropTypes.bool.isRequired,
  setIsChatOpen: PropTypes.func.isRequired
};
