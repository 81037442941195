export default function removeDuplicates(objects) {
  const uniqueMap = {};

  const uniqueObjects = objects.filter((obj) => {
    const key = obj.columnName + obj.providerName;
    if (!uniqueMap[key]) {
      uniqueMap[key] = true;
      return true;
    }
    return false;
  });

  return uniqueObjects;
}
