const colorMain = '#71234A';
const colorMainDark = '#5e1d3e';
const paletteSombre = {
  colorMain,
  colorMainDark,
  colorMainGradient: `linear-gradient(180deg, ${colorMainDark} 0%, ${colorMain} 100%);`,
  colorMainHover: '#77465D',
  colorMainSelected: '#BA8C9F',

  colorTableCheckboxDark: '#77465D',
  colorText: '#ededed',

  colorMainLightHover: '#c47f00',
  colorMainLight: '#292929',
  colorTextMain: '#000',
  colorApps: '#f4f5f7',
  colorSecondaryLight: '#424242',
  colorGray: '#AF8399',

  colorBlack: '#f4f5f7',
  colorSoftBlack: '#a65d82',
  colorSoftProfile: '#fff',
  colorNumberPage: '#fff',
  successMain: '#42824C',
  successSecondary: '#A2DBAC',

  errorMain: '#B13434',
  errorSecondary: '#CC8181',

  warnMain: '#c47f00',
  warnSecondary: '#ffd589',

  neutralMain: '#27407A',
  neutralSecondary: '#A4C0C7'
};

export default paletteSombre;
