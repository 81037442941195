export const styleAzureButton = (theme) => ({
  display: 'flex',
  borderRadius: '4px',
  justifyContent: 'center',
  alignItems: 'center',
  width: '290px',
  height: '40px',
  marginTop: '20px',
  color: theme.palette.colorSoftBlack,
  transition: 'all 0.5s',
  '&:hover': {
    transform: 'scale(1.2, 1.2)'
  }
});

export const styleAzureUserSaveButton = (theme) => ({
  display: 'flex',
  borderRadius: '4px',
  justifyContent: 'center',
  alignItems: 'center',
  width: '290px',
  height: '40px',
  marginTop: '20px',
  color: theme.palette.colorSoftBlack
});

export const styleAzureText = {
  fontSize: '15px',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'initial',
  borderRadius: '5px',
  textDecoration: 'none',
  width: '200px',
  margin: '10px 0'
};

export const styleImageAzureAd = {
  height: '30px',
  width: '30px',
  display: 'flex',
  borderRadius: '4px'
};
