import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UsersAdminService from '../../services/admin/UsersAdminService';
import UsersSuperAdminService from '../../services/superAdmin/UsersSuperAdminService';
import AzureUsersService from '../../services/users/AzureUsersService';
import { getDateTimeFormatted } from '../../utils/Date/DateFunction';
import formattedAzureUserField from '../../utils/jsFormatter';
import { regexpEscaping } from '../../utils/regex';
import { setLicensesAzure, deleteLicensesAzure } from './AzureLicenses';
import { assignAzureGroupsUser, removeAzureGroupsUser } from './AzureGroups';

export const initialState = {
  azureUsers: [],
  isLoading: false,
  updateAt: '',
  status: 'fulfilled',
  selectedUser: {},
  services: [],
  allDomains: []
};

export const getAzureUser = createAsyncThunk('azureUser', async (payload) => {
  const res = await UsersAdminService.getAzureUser(payload);
  return res.data;
});

export const updateAzureUser = createAsyncThunk('updateAzureUser', async (modifiedUser) => {
  const res = await UsersSuperAdminService.updateAzureUser(modifiedUser);
  return res.data;
});

export const createAzureUser = createAsyncThunk('createAzureUser', async (createdUser) => {
  const res = await UsersSuperAdminService.createAzureUser(createdUser);
  return res.data;
});

export const deleteAzureUser = createAsyncThunk('deleteAzureUser', async (deletedUsersBody) => {
  const res = await UsersSuperAdminService.deleteAzureUser(deletedUsersBody);
  return res.data;
});

export const updateAzureUserAccount = createAsyncThunk('updateAzureUserAccount', async (userId) => {
  const res = await UsersSuperAdminService.updateAzureUserAccount(userId);
  return res.data;
});

export const changePasswordAzureUser = createAsyncThunk(
  'changePasswordAzureUser',
  async (payload) => {
    const res = await UsersSuperAdminService.changePasswordAzureUser(
      payload.userId,
      payload.changePassword
    );
    return res.data;
  }
);

export const getAzureUserAvailableServices = createAsyncThunk(
  'getAzureUserAvailableServices',
  async () => {
    const res = await UsersAdminService.azureUserAvailableServices();
    return res.data;
  }
);

export const requestAzureUserDomains = createAsyncThunk('AzureUserDomains', async () => {
  const res = await UsersAdminService.getAzureUserDomains();
  return res.data;
});

export const findAzureUsers = createAsyncThunk('findAzureUsers', () => {
  AzureUsersService.findAzureUsers();
});

export const refreshAzureUsers = createAsyncThunk('refreshAzureUsers', () => {
  AzureUsersService.refreshAzureUsers();
});

// export const assignAzureGroupsUser = createAsyncThunk('assignAzureGroupsUser', () => {
//   AzureGroups.assignAzureGroupsUser();
// });

// export const removeAzureGroupsUser = createAsyncThunk('removeAzureGroupsUser', () => {
//   AzureGroups.removeAzureGroupsUser();
// });

export const azureUsersSlice = createSlice({
  name: 'azureUsers',
  initialState,
  reducers: {
    addUsersToList: (state, action) => {
      state.azureUsers = state.azureUsers.concat(action.payload.users);
    },
    resetUsersList: (state) => {
      state.azureUsers = [];
    },
    setUsersListUpdateAt: (state, action) => {
      const dateFormatted = getDateTimeFormatted(Number(action.payload));
      state.updateAt = dateFormatted;
      state.isLoading = false;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    resetSelectedUser: (state) => {
      state.selectedUser = {};
    },
    resetAzureUserState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAzureUser.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getAzureUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedUser = action?.payload?.azureUser;
      })
      .addCase(getAzureUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(updateAzureUser.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updateAzureUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const formattedUser = formattedAzureUserField(action.meta.arg);
        state.selectedUser = formattedUser;
        const index = state.azureUsers.findIndex((user) => user.id === action.meta.arg.id);
        delete formattedUser.assignedLicenses;
        state.azureUsers[index] = formattedUser;
      })
      .addCase(updateAzureUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(createAzureUser.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(createAzureUser.fulfilled, (state, action) => {
        const azureUsersList = state.azureUsers;
        azureUsersList.push({
          ...action.payload.user,
          manager: {
            displayName: action.meta.arg.manager.displayName,
            id: action.meta.arg.manager.id
          },
          usageLocation: action.meta.arg.usageLocation
        });
        azureUsersList.sort((a, b) => a.userPrincipalName.localeCompare(b.userPrincipalName));
        state.azureUsers = azureUsersList;
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(createAzureUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(deleteAzureUser.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(deleteAzureUser.fulfilled, (state, action) => {
        action.meta.arg.userIdList.forEach((element) => {
          state.azureUsers = state.azureUsers.filter((data) => {
            return data.id !== element;
          });
        });
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(deleteAzureUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(updateAzureUserAccount.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(updateAzureUserAccount.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const userIdUpdatedList = action.meta.arg;
        userIdUpdatedList.forEach((id) => {
          const index = state.azureUsers.findIndex((user) => user.id === id);
          state.azureUsers[index] = {
            ...state.azureUsers[index],
            accountEnabled: !state.azureUsers[index].accountEnabled
          };
          if (id === state.selectedUser.id) {
            state.selectedUser = {
              ...state.selectedUser,
              accountEnabled: !state.selectedUser.accountEnabled
            };
          }
        });
      })
      .addCase(updateAzureUserAccount.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(changePasswordAzureUser.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(changePasswordAzureUser.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(changePasswordAzureUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder.addCase(setLicensesAzure.fulfilled, (state, action) => {
      if (action.payload.response[0].status === 200) {
        if (action.meta.arg.users[0] === state.selectedUser.id) {
          const setUserAssignedLicenses = state.selectedUser.assignedLicenses.concat(
            action.meta.arg.licenses
          );
          state.selectedUser.assignedLicenses = setUserAssignedLicenses;
        }
      }
      state.isLoading = false;
      state.status = 'fulfilled';
    });
    builder.addCase(deleteLicensesAzure.fulfilled, (state, action) => {
      if (action.payload.response[0].status === 200) {
        if (
          action.meta.arg.users.length === 1 &&
          state.selectedUser.id === action.meta.arg.users[0] &&
          state.selectedUser.assignedLicenses !== 0
        ) {
          const removeUserAssignedLicenses = state.selectedUser.assignedLicenses.filter(
            (license) => !action.meta.arg.removeLicenses.includes(license.skuId)
          );
          state.selectedUser = {
            ...state.selectedUser,
            assignedLicenses: removeUserAssignedLicenses
          };
        }
      }
      state.isLoading = false;
      state.status = 'fulfilled';
    });
    builder
      .addCase(getAzureUserAvailableServices.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getAzureUserAvailableServices.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.services = action.payload.services;
      })
      .addCase(getAzureUserAvailableServices.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(requestAzureUserDomains.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(requestAzureUserDomains.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.allDomains = action.payload.value;
      })
      .addCase(requestAzureUserDomains.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(findAzureUsers.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.azureUsers = [];
      })
      .addCase(findAzureUsers.fulfilled, (state) => {
        state.status = 'fulfilled';
      })
      .addCase(findAzureUsers.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(refreshAzureUsers.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.azureUsers = [];
      })
      .addCase(refreshAzureUsers.fulfilled, (state) => {
        state.status = 'fulfilled';
      })
      .addCase(refreshAzureUsers.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder.addCase(assignAzureGroupsUser.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.isLoading = false;

      action.meta.arg.users.map((selectedUser) => {
        const indexUserFinder = state.azureUsers.findIndex((user) => user.id === selectedUser);
        if (action.meta.arg.users.includes(state?.azureUsers[indexUserFinder]?.id)) {
          action.meta.arg.groups.map((assignedGroup) => {
            const indexGroup = action.meta.arg.allGroups.findIndex(
              (group) => group.id === assignedGroup
            );
            if (
              !state.azureUsers[indexUserFinder]?.memberOf?.includes(
                action.meta.arg?.allGroups[indexGroup]?.displayName
              ) &&
              indexGroup !== -1
            ) {
              state?.azureUsers[indexUserFinder]?.memberOf?.push(
                action.meta.arg?.allGroups[indexGroup]?.displayName
              );
            }
            return true;
          });
        }
        return true;
      });
    });

    builder.addCase(removeAzureGroupsUser.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.isLoading = false;
      action.meta.arg.users.map((selectedUser) => {
        const indexUserFinder = state.azureUsers.findIndex((user) => user.id === selectedUser);
        if (action.meta.arg.users.includes(state.azureUsers[indexUserFinder]?.id)) {
          action.meta.arg.groups.map((assignedGroup) => {
            const indexGroup = action.meta.arg.allGroups.findIndex(
              (group) => group.id === assignedGroup
            );
            if (
              state.azureUsers[indexUserFinder]?.memberOf?.includes(
                action.meta.arg.allGroups[indexGroup]?.displayName
              )
            ) {
              const indexMemberOf = state.azureUsers[indexUserFinder].memberOf.findIndex(
                (memberOf) => memberOf === action.meta.arg.allGroups[indexGroup]?.displayName
              );
              state.azureUsers[indexUserFinder].memberOf.splice(indexMemberOf, 1);
            }
            return true;
          });
        }
        return true;
      });
    });
  }
});

export const {
  addUsersToList,
  setSelectedUser,
  resetUsersList,
  setIsLoading,
  setUsersListUpdateAt,
  resetAzureUserState
} = azureUsersSlice.actions;

export const selectedAzureUsersStatus = (arrayUsersId) => (state) => {
  if (state.azureUsers.azureUsers.length > 0)
    return state.azureUsers.azureUsers.filter((user) => arrayUsersId.includes(user.id));

  return state.azureUsers.azureUsers;
};

export const selectAzureUsers = (searchTerm) => (state) => {
  if (state.azureUsers.azureUsers.length > 0 && searchTerm.length > 2) {
    const escapedString = regexpEscaping(searchTerm);
    const regexp = new RegExp(`${escapedString}`, 'gi');
    return state.azureUsers.azureUsers.filter(
      (user) =>
        (user.displayName && user.displayName.match(regexp)) ||
        (user.surname && user.surname.match(regexp)) ||
        (user.givenName && user.givenName.match(regexp)) ||
        (user.userPrincipalName && user.userPrincipalName.match(regexp))
    );
  }
  return state.azureUsers.azureUsers;
};
export const { resetSelectedUser } = azureUsersSlice.actions;

export const selectAzureUsersWithPhones = (state) => {
  return state.azureUsers.azureUsers.filter(
    (user) => user.mobilePhone || user.businessPhones?.length > 0
  );
};

export const selectAzureUserByPhone = (phoneNumber) => (state) => {
  return state.azureUsers.azureUsers.filter(
    (user) =>
      (user.mobilePhone && user.mobilePhone === phoneNumber) ||
      (user.businessPhones && user.businessPhones === phoneNumber)
  )[0];
};

export const selectAzureUserAvailableServices = (state) =>
  state.azureUsers.services.map((element) => element.name);

export const selectedUser = (state) => state.azureUsers.selectedUser;
export const selectIsLoading = (state) => state.azureUsers.isLoading;
export const selectUpdateAt = (state) => state.azureUsers.updateAt;
export const selectedAzureUsers = (state) => state.azureUsers.azureUsers;
export const selectAllDomains = (state) => state.azureUsers.allDomains;
export const searchAzureUserById = (id) => (state) =>
  state.azureUsers.azureUsers.filter((user) => user.id === id)[0]?.displayName;

export const selectIsTableDisplayable = (state) => {
  if (state.azureUsers.azureUsers.length <= 0 || state.columns?.columns?.length <= 0) {
    return false;
  }
  return true;
};

export const selectIsUserLoaded = (state) => {
  if (!state.azureUsers.isLoading && state.azureUsers.selectedUser?.displayName) {
    return true;
  }
  return false;
};

export default azureUsersSlice.reducer;
