export const allowedAzureColumns = [
  { columnId: 'businessPhones', input: 'string' },
  { columnId: 'displayName', input: 'string' },
  { columnId: 'givenName', input: 'string' },
  { columnId: 'jobTitle', input: 'string' },
  { columnId: 'mail', input: 'string' },
  { columnId: 'mailNickname', input: 'string' },
  { columnId: 'mobilePhone', input: 'string' },
  { columnId: 'officeLocation', input: 'string' },
  { columnId: 'preferredLanguage', input: 'string' },
  { columnId: 'usageLocation', input: 'string' },
  { columnId: 'surname', input: 'string' },
  { columnId: 'userPrincipalName', input: 'string' },
  { columnId: 'id', input: 'string' },
  { columnId: 'accountEnabled', input: 'boolean' },
  { columnId: 'isActive', input: 'boolean' },
  { columnId: 'employeeId', input: 'string' },
  { columnId: 'department', input: 'string' },
  { columnId: 'assignedLicenses', input: 'array' },
  { columnId: 'manager', input: 'string' },
  { columnId: 'onPremisesLastSyncDateTime', input: 'string' },
  { columnId: 'onPremisesDistinguishedName', input: 'string' },
  { columnId: 'memberOf', input: 'array' },
  { columnId: 'aboutMe', input: 'string' },
  { columnId: 'ageGroup', input: 'string' },
  { columnId: 'assignedPlans', input: 'array' },
  { columnId: 'birthday', input: 'array' },
  { columnId: 'city', input: 'string' },
  { columnId: 'companyName', input: 'string' },
  { columnId: 'consentProvidedForMinor', input: 'string' },
  { columnId: 'country', input: 'string' },
  { columnId: 'createdDateTime', input: 'string' },
  { columnId: 'creationType', input: 'string' },
  { columnId: 'customSecurityAttributes', input: 'string' },
  { columnId: 'deletedDateTime', input: 'string' },
  { columnId: 'employeeHireDate', input: 'string' },
  { columnId: 'employeeLeaveDateTime', input: 'string' },
  { columnId: 'employeeOrgData', input: 'object' },
  { columnId: 'externalUserState', input: 'string' },
  { columnId: 'externalUserStateChangeDateTime', input: 'string' },
  { columnId: 'faxNumber', input: 'string' },
  { columnId: 'hireDate', input: 'string' },
  { columnId: 'identities', input: 'object' },
  { columnId: 'imAddresses', input: 'array' },
  { columnId: 'interests', input: 'array' },
  { columnId: 'isResourceAccount', input: 'boolean' },
  { columnId: 'lastPasswordChangeDateTime', input: 'string' },
  { columnId: 'legalAgeGroupClassification', input: 'string' },
  { columnId: 'licenseAssignmentStates', input: 'array' },
  { columnId: 'mySite', input: 'array' },
  { columnId: 'onPremisesDomainName', input: 'string' },
  { columnId: 'onPremisesExtensionAttributes', input: 'array' },
  { columnId: 'onPremisesImmutableId', input: 'string' },
  { columnId: 'onPremisesProvisioningErrors', input: 'string' },
  { columnId: 'onPremisesSamAccountName', input: 'string' },
  { columnId: 'onPremisesSecurityIdentifier', input: 'string' },
  { columnId: 'onPremisesSyncEnabled', input: 'boolean' },
  { columnId: 'onPremisesUserPrincipalName', input: 'string' },
  { columnId: 'passwordPolicies', input: 'string' },
  { columnId: 'passwordProfile', input: 'string' },
  { columnId: 'pastProjects', input: 'array' },
  { columnId: 'postalCode', input: 'string' },
  { columnId: 'preferredDataLocation', input: 'string' },
  { columnId: 'preferredName', input: 'string' },
  { columnId: 'provisionedPlans', input: 'array' },
  { columnId: 'proxyAddresses', input: 'array' },
  { columnId: 'refreshTokensValidFromDateTime', input: 'string' },
  { columnId: 'responsibilities', input: 'string' },
  { columnId: 'serviceProvisioningErrors', input: 'array' },
  { columnId: 'schools', input: 'array' },
  { columnId: 'securityIdentifier', input: 'string' },
  { columnId: 'showInAddressList', input: 'string' },
  { columnId: 'signInSessionsValidFromDateTime', input: 'string' },
  { columnId: 'skill', input: 'array' },
  { columnId: 'state', input: 'string' },
  { columnId: 'streetAddress', input: 'string' },
  { columnId: 'userType', input: 'string' }
];

export const allowedADColumns = [
  { columnId: 'cn', input: 'string' },
  { columnId: 'name', input: 'string' },
  { columnId: 'distinguishedName', input: 'string' },
  { columnId: 'sn', input: 'string' },
  { columnId: 'userPrincipalName', input: 'string' },
  { columnId: 'userAccountControl', input: 'string' },
  { columnId: 'displayName', input: 'string' },
  { columnId: 'memberOf', input: 'array' },
  { columnId: 'whenCreated', input: 'string' },
  { columnId: 'whenChanged', input: 'string' },
  { columnId: 'mail', input: 'string' },
  { columnId: 'isUserEnable', input: 'boolean' },
  { columnId: 'givenName', input: 'string' },
  { columnId: 'initials', input: 'string' },
  { columnId: 'description', input: 'string' },
  { columnId: 'physicalDeliveryOfficeName', input: 'string' },
  { columnId: 'telephoneNumber', input: 'string' },
  { columnId: 'otherTelephone', input: 'string' },
  { columnId: 'wWWHomePage', input: 'string' },
  { columnId: 'url', input: 'string' },
  { columnId: 'instanceType', input: 'string' },
  { columnId: 'userCertificate', input: 'array' },
  { columnId: 'uSNCreated', input: 'string' },
  { columnId: 'uSNChanged', input: 'string' },
  { columnId: 'objectGUID', input: 'string' },
  { columnId: 'badPwdCount', input: 'string' },
  { columnId: 'codePage', input: 'string' },
  { columnId: 'countryCode', input: 'string' },
  { columnId: 'badPasswordTime', input: 'string' },
  { columnId: 'lastLogoff', input: 'string' },
  { columnId: 'lastLogon', input: 'string' },
  { columnId: 'localPolicyFlags', input: 'string' },
  { columnId: 'pwdLastSet', input: 'string' },
  { columnId: 'primaryGroupID', input: 'string' },
  { columnId: 'objectSid', input: 'string' },
  { columnId: 'accountExpires', input: 'string' },
  { columnId: 'logonCount', input: 'string' },
  { columnId: 'sAMAccountName', input: 'string' },
  { columnId: 'sAMAccountType', input: 'string' },
  { columnId: 'operatingSystem', input: 'string' },
  { columnId: 'operatingSystemVersion', input: 'string' },
  { columnId: 'serverReferenceBL', input: 'string' },
  { columnId: 'dNSHostName', input: 'string' },
  { columnId: 'rIDSetReferences', input: 'string' },
  { columnId: 'servicePrincipalName', input: 'array' },
  { columnId: 'objectCategory', input: 'string' },
  { columnId: 'objectClass', input: 'array' },
  { columnId: 'isCriticalSystemObject', input: 'boolean' },
  { columnId: 'dSCorePropagationData', input: 'array' },
  { columnId: 'lastLogonTimestamp', input: 'string' },
  { columnId: 'msDS-SupportedEncryptionTypes', input: 'string' },
  { columnId: 'msDFSR-ComputerReferenceBL', input: 'string' },
  { columnId: 'mS-DS-ConsistencyGuid', input: 'string' },
  { columnId: 'msDS-ExternalDirectoryObjectId', input: 'string' },
  { columnId: 'adminCount', input: 'string' },
  { columnId: 'showInAdvancedViewOnly', input: 'boolean' },
  { columnId: 'lockoutTime', input: 'string' },
  { columnId: 'managedObjects', input: 'string' },
  { columnId: 'proxyAddresses', input: 'array' },
  { columnId: 'directReports', input: 'array' },
  { columnId: 'manager', input: 'string' },
  { columnId: 'garbageCollPeriod', input: 'string' },
  { columnId: 'msExchModerationFlags', input: 'string' },
  { columnId: 'msExchVersion', input: 'string' },
  { columnId: 'msExchRecipientSoftDeletedStatus', input: 'string' },
  { columnId: 'msExchAddressBookFlags', input: 'string' },
  { columnId: 'msExchGroupSecurityFlags', input: 'string' },
  { columnId: 'msExchTransportRecipientSettingsFlags', input: 'string' },
  { columnId: 'msExchBypassAudit', input: 'boolean' },
  { columnId: 'msExchMDBRulesQuota', input: 'string' },
  { columnId: 'msExchProvisioningFlags', input: 'string' },
  { columnId: 'msExchUserAccountControl', input: 'string' },
  { columnId: 'msExchUMEnabledFlags2', input: 'string' },
  { columnId: 'msExchMailboxFolderSet', input: 'string' },
  { columnId: 'msExchMailboxAuditEnable', input: 'boolean' },
  { columnId: 'msExchMailboxAuditLogAgeLimit', input: 'string' },
  { columnId: 'internetEncoding', input: 'string' },
  { columnId: 'streetAddress', input: 'string' },
  { columnId: 'postOfficeBox', input: 'string' },
  { columnId: 'l', input: 'string' },
  { columnId: 'st', input: 'string' },
  { columnId: 'postalCode', input: 'string' },
  { columnId: 'c', input: 'string' },
  { columnId: 'userType', input: 'string' },
  { columnId: 'homePhone', input: 'string' },
  { columnId: 'otherHomePhone', input: 'string' },
  { columnId: 'pager', input: 'string' },
  { columnId: 'otherPager', input: 'string' },
  { columnId: 'mobile', input: 'string' },
  { columnId: 'otherMobile', input: 'string' },
  { columnId: 'facsimileTelephoneNumber', input: 'string' },
  { columnId: 'otherFacsimile', input: 'string' },
  { columnId: 'ipPhone', input: 'string' },
  { columnId: 'otherIpPhone', input: 'string' },
  { columnId: 'info', input: 'string' },
  { columnId: 'title', input: 'string' },
  { columnId: 'department', input: 'string' },
  { columnId: 'company', input: 'string' },
  { columnId: 'employeeID', input: 'string' },
  { columnId: 'employeeType', input: 'string' },
  { columnId: 'employeeNumber', input: 'string' },
  { columnId: 'carLicense', input: 'string' },
  { columnId: 'division', input: 'string' },
  { columnId: 'roomNumber', input: 'string' },
  { columnId: 'mailNickname', input: 'string' },
  { columnId: 'displayNamePrintable', input: 'string' },
  { columnId: 'extensionAttribute1', input: 'string' },
  { columnId: 'extensionAttribute2', input: 'string' },
  { columnId: 'extensionAttribute3', input: 'string' },
  { columnId: 'extensionAttribute4', input: 'string' },
  { columnId: 'extensionAttribute5', input: 'string' },
  { columnId: 'extensionAttribute6', input: 'string' },
  { columnId: 'extensionAttribute7', input: 'string' },
  { columnId: 'extensionAttribute8', input: 'string' },
  { columnId: 'extensionAttribute9', input: 'string' },
  { columnId: 'extensionAttribute10', input: 'string' },
  { columnId: 'extensionAttribute11', input: 'string' },
  { columnId: 'extensionAttribute12', input: 'string' },
  { columnId: 'extensionAttribute13', input: 'string' }
];

export const allowedJiraColumns = [
  { columnId: 'key', input: 'string' },
  { columnId: 'name', input: 'string' },
  { columnId: 'emailAddress', input: 'string' },
  { columnId: 'displayName', input: 'string' },
  { columnId: 'active', input: 'boolean' },
  { columnId: 'deleted', input: 'boolean' },
  { columnId: 'timeZone', input: 'string' },
  { columnId: 'locale', input: 'string' },
  { columnId: 'groups', input: 'array' },
  { columnId: 'accountId', input: 'string' },
  { columnId: 'accountType', input: 'string' },
  { columnId: 'avatarUrls', input: 'object' },
  { columnId: 'self', input: 'string' },
  { columnId: 'applicationRoles', input: 'object' },
  { columnId: 'expand', input: 'string' }
];
