export const styleLicenseGrid = (theme) => ({
  borderRadius: '10px',
  boxShadow: `1px 1px 1px 1px ${theme.palette.colorGray}`,
  background: theme.palette.colorBlack,
  opacity: 0.2,
  marginTop: '80px',
  '&:hover': {
    background: theme.palette.colorGray,
    color: theme.palette.colorMainLight
  }
});

export const styleLicenseText = {
  fontSize: { xs: '20px', sm: '25px' },
  marginLeft: '50px'
};

export const styleCollapseWidth = { width: '100%' };

export const styleButtonMargin = { margin: '20px' };

export const styleMarginTextBold = { margin: '5px', fontWeight: 'bold' };

export const styleMarginText = { margin: '5px' };

export const styleUserNameText = {
  font: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '600',
  LineHeight: '29px',
  flex: 'none',
  fontSize: { xs: '25px', sm: '40px' },
  width: '100%',
  textAlign: { sm: 'start', md: 'start' },
  marginLeft: '40px'
};

export const styleHeadPageTwoColumns = (theme) => ({
  display: 'grid',
  top: '0px',
  gridTemplateColumns: '1fr 1fr',
  justifyItems: 'stretch',
  position: 'fixed',
  zIndex: '1',
  width: '-webkit-fill-available',
  paddingTop: '25px',
  paddingLeft: '50px',
  paddingBottom: '35px',
  gap: '20px',
  borderBottom: `3px solid ${theme.palette.colorMainLight}`,
  background: theme.palette.colorMainLight
});

export const styleTitleHeadPageGrid = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
};

export const styleTitleHeadPage = (theme) => ({
  font: 'Nunito',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  LineHeight: '22px',
  color: theme.palette.colorBlack,
  flex: 'none',
  order: '0',
  flexGrow: '0'
});

export const styleTitleHeadPageDescript = (theme) => ({
  font: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '24px',
  LineHeight: '29px',
  color: theme.palette.colorSoftBlack,
  flex: 'none',
  order: '1',
  flexGrow: '0'
});

export const styleGridExpand = {
  justifyContent: 'flex-end',
  display: 'flex',
  alignItems: 'center',
  paddingRight: '30px'
};

export const styledFormControl = {
  display: 'flex',
  padding: '20px',
  gap: '16px'
};

export const styledButtonContainer = {
  display: 'flex',
  gap: '16x',
  alignItems: 'center',
  justifyContent: 'space-around'
};

export const styledTitle = {
  marginBottom: '16px'
};

export const styledPassword = (theme) => ({
  color: theme.palette.colorBlack
});
