import React from 'react';
import { Grid, InputLabel, TextField } from '@mui/material';
import PropTypes from 'prop-types';

function InputComponent({ textFields, onChange, inputErrors }) {
  const numColumns = textFields.length % 2 === 0 ? 2 : 1;

  return (
    <Grid container spacing={2}>
      {textFields.map((textField) => (
        <Grid key={textField.name} item xs={12} sm={12 / numColumns}>
          <InputLabel htmlFor={textField.name}>{textField.name}</InputLabel>
          <TextField
            fullWidth
            name={textField.name}
            value={textField.value || ''}
            error={inputErrors[textField.name]}
            onChange={(e) => onChange(textField.name, e.target.value)}
            data-testid={`${textField.name}Input`}
          />
        </Grid>
      ))}
    </Grid>
  );
}

InputComponent.propTypes = {
  inputErrors: PropTypes.objectOf(PropTypes.bool),
  textFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
};

InputComponent.defaultProps = {
  inputErrors: {}
};

export default InputComponent;
