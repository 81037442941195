import { createTheme } from '@mui/material/styles';

const getMuiTheme = (theme) =>
  createTheme({
    palette: theme.palette,
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            color: `${theme.palette.colorSoftBlack} !important`
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '3px',
            backgroundColor: theme.palette.colorMainLight
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: theme.palette.colorBlack
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.colorSecondaryLight
          },
          toolButton: {
            fontWeight: 'bold'
          }
        }
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          root: {
            minWidth: '10px',
            position: 'inherit'
          },
          headerCell: {
            backgroundColor: `${theme.palette.colorSecondaryLight} !important`,
            position: 'sticky !important',
            zIndex: 100
          }
        }
      },
      MUIDataTable: {
        styleOverrides: {
          responsiveBase: {
            '::-webkit-scrollbar': {
              width: '8px',
              height: '8px'
            },
            '::-webkit-scrollbar-track': {
              background: theme.palette.colorMainLight,
              borderRadius: '5px'
            },
            '::-webkit-scrollbar-thumb': {
              background: theme.palette.colorSecondaryLight,
              borderRadius: '5px'
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: theme.palette.colorMain
            },
            '::-webkit-scrollbar-corner': {
              background: 'transparent'
            }
          }
        }
      },
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            width: '30rem',
            backgroundColor: theme.palette.colorSecondaryLight
          },
          resetLink: {
            alignItems: 'center',
            paddingTop: '18px',
            paddingBottom: '18px',
            textTransform: 'none',
            borderRadius: '10px',
            fontWeight: 550,
            height: '42px',
            marginTop: '5px',
            marginRight: '5px',
            marginLeft: '5px',
            color: theme.palette.colorMainSelected,
            backgroundColor: theme.palette.colorMainLight,
            fontSize: '14px'
          },
          reset: {
            display: 'flex',
            gap: 16,
            alignItems: 'baseline'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            width: '100% !important',
            color: theme.palette.colorBlack
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            alignItems: 'normal !important'
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            height: '100%',
            justifyContent: 'center'
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          body1: {
            fontSize: '1rem',
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            fontWeight: 'semi-bold',
            color: theme.palette.colorBlack
          }
        }
      },
      MUIDataTableViewCol: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.colorSecondaryLight
          },
          title: {
            color: theme.palette.colorBlack
          },
          label: {
            color: theme.palette.colorBlack
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          filled: {
            width: '100%',
            fontSize: '1.2rem',
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            fontWeight: 'semi-bold'
          }
        }
      },
      MuiList: {
        root: {
          MuiMenu: {
            list: {
              backgroundColor: theme.palette.colorSecondaryLight
            }
          }
        }
      }
    },
    MuiTableCell: {
      backgroundColor: theme.palette.colorMainLight
    }
  });

export default getMuiTheme;
