import React from 'react';
import { Grid, Typography, Link, useTheme, List } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useSelector } from 'react-redux';
import {
  styleEllipsis,
  styleRowGrid,
  styleInformationNameText,
  styleInformationListWrapper,
  styleList
} from '../assets/style/components/InformationList';
import CheckedIcon from './CheckedIcon';
import { getDateTimeFormatted } from '../utils/Date/DateFunction';
import { searchAzureUserById } from '../redux/slices/AzureUsersList';

export default function InformationList({ row, columns }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const manager = useSelector(searchAzureUserById(row.manager));

  const foundObjectRef = (obj, attributes) => {
    return attributes.split('.').reduce((x, a) => x[a], obj);
  };

  const friendlyTextTransform = (column) => {
    if (row.manager && column === 'manager') {
      return manager;
    }
    const displayValue = foundObjectRef(row, column);
    if (!displayValue) return t('none');
    return displayValue;
  };

  return (
    <Grid sx={styleInformationListWrapper}>
      {columns.map((column) => (
        <Grid sx={styleRowGrid} key={column.name}>
          <Typography sx={styleInformationNameText} gutterBottom>
            {t(`${column.name}`)}:&#160;
          </Typography>
          {column.type === 'icon' ? <CheckedIcon checked={row[column.key] === true} /> : null}
          {column.type === 'date' ? (
            <Typography>{getDateTimeFormatted(foundObjectRef(row, column.key))} </Typography>
          ) : null}{' '}
          {column.type === 'link' || row[column.name]?.length <= 0 ? (
            <Link
              data-testid={`${row.userId}DisplayUserLink`}
              component={RouterLink}
              to={`/azureUsers/${row.userId}`}
              underline="hover"
              sx={{ overflow: 'hidden', color: theme.palette.colorMain }}
            >
              {row[column.name]}
            </Link>
          ) : null}
          {column.type === 'list' && row[column.name].length > 0 ? (
            <List sx={styleList}>
              {row[column.name].map((element) => {
                return (
                  <ListItem key={`${element}-item`}>
                    <ListItemText key={`${element}-itemText`} primary={`${element}`} />
                  </ListItem>
                );
              })}
            </List>
          ) : null}
          <Typography sx={styleEllipsis} data-testid={column.name}>
            {(!column.format && column.type === 'string') ||
            (column.type === 'link' && foundObjectRef(row, column.key)?.length === 0)
              ? friendlyTextTransform(column.key)
              : null}
            {column.type === 'list' && row[column.name].length === 0 ? t('none') : null}
            {column.type === 'string' && column.format ? column.format(row[column.name]) : null}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}
InformationList.propTypes = {
  row: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
        )
      ),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number,
          PropTypes.arrayOf(
            PropTypes.objectOf(
              PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
            )
          )
        ])
      ),
      PropTypes.arrayOf(PropTypes.string)
    ])
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
  ).isRequired
};
