import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Grid,
  ListItem,
  ListItemButton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { styleScrollbar } from '../../assets/style/utils/utils';
import CustomizedButton from '../buttons/CustomizedButton';
import ListItems from './BackOfficeDesktop/ListItems';
import {
  styleMainContainer,
  styleTypographyProfile,
  styleHeader,
  styleTitle,
  styleListContainer
} from '../../assets/style/views/SMS';
import EditItem from './BackOfficeDesktop/EditItem';
import { styleListButton } from '../../assets/style/components/SMS/ChatList';
import ItemsDesktop from '../../views/pages/ItemsDesktop';

export default function BackOfficeDesktop({
  isItemDisplayable,
  isItemDisplayableDataTable,
  desktopConfig,
  listItems,
  selectedItemId,
  selectedItemName,
  fieldList,
  setModifiedItem,
  isItemModified,
  handleClickCancelButton,
  handleClickUpdateButton,
  handleDeletePopup,
  handleAddPopup,
  titleBodyName,
  titleHeader,
  nameButton,
  file,
  setFile,
  hideAddButton
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid>
      <Box>
        <Grid sx={styleHeader(theme, isMobile)}>
          <Typography sx={styleTypographyProfile}>{titleHeader}</Typography>
          <Typography variant="h4" sx={styleTitle}>
            {titleBodyName}
          </Typography>
        </Grid>
      </Box>

      <Grid sx={styleMainContainer}>
        <Box
          sx={[
            styleListContainer(isMobile),
            styleScrollbar,
            {
              height: 'calc(100vh - 169px)',
              flexDirection: 'column',
              overflow: 'hidden',
              background: theme.palette.colorMainLight
            }
          ]}
        >
          {!hideAddButton ? (
            <ListItem>
              <ListItemButton
                data-testid="ListButton"
                sx={{
                  ...styleListButton(theme),
                  background: theme.palette.colorMainSelected,
                  padding: '15px',
                  borderRadius: '10px',
                  '&:hover': {
                    background: theme.palette.colorMainSelected,
                    transform: 'scale(0.95, 0.95)'
                  },
                  '&:focus': {
                    outline: 'none'
                  },
                  '&:disabled': {
                    background: theme.palette.colorMainLight,
                    border: `1px solid ${theme.palette.colorGray}`
                  }
                }}
                onClick={handleAddPopup}
              >
                <AddIcon sx={{ height: '25px', color: theme.palette.colorSecondaryLight }} />
                <Typography
                  sx={{
                    marginLeft: '25px',
                    ontsize: '25px',
                    color: theme.palette.colorSecondaryLight,
                    fontWeight: 'bold'
                  }}
                >
                  {nameButton}
                </Typography>
              </ListItemButton>
            </ListItem>
          ) : null}
          <ListItems listItems={listItems} selectedItemId={selectedItemId} />
        </Box>

        {isItemDisplayableDataTable ? (
          <ItemsDesktop desktopConfig={desktopConfig} />
        ) : (
          <EditItem
            selectedItemName={selectedItemName}
            fieldList={fieldList}
            setModifiedItem={setModifiedItem}
            handleDeletePopup={handleDeletePopup}
            file={file}
            setFile={setFile}
          />
        )}
        {isItemDisplayable ? (
          <Box
            sx={{
              position: 'fixed',
              bottom: '40px',
              right: '50px',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <CustomizedButton
              variant="contained"
              datatestid="cancelUpdateItem"
              disabled={isItemModified}
              onClick={handleClickCancelButton}
              text={t('cancel')}
            />
            <CustomizedButton
              variant="contained"
              datatestid="confirmUpdateItem"
              disabled={isItemModified}
              onClick={handleClickUpdateButton}
              text={t('save')}
            />
          </Box>
        ) : null}
      </Grid>
    </Grid>
  );
}

BackOfficeDesktop.propTypes = {
  isItemDisplayable: PropTypes.bool,
  isItemDisplayableDataTable: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  desktopConfig: PropTypes.object,
  isItemModified: PropTypes.bool,
  listItems: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func]))
  ),
  fieldList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
        PropTypes.bool
      ]),
      type: PropTypes.string
    })
  ),
  selectedItemId: PropTypes.number,
  selectedItemName: PropTypes.string,
  setModifiedItem: PropTypes.func,
  handleClickCancelButton: PropTypes.func,
  handleClickUpdateButton: PropTypes.func,
  handleDeletePopup: PropTypes.func,
  handleAddPopup: PropTypes.func,
  titleBodyName: PropTypes.string,
  titleHeader: PropTypes.string,
  nameButton: PropTypes.string,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setFile: PropTypes.func,
  hideAddButton: PropTypes.bool
};

BackOfficeDesktop.defaultProps = {
  isItemDisplayable: false,
  isItemModified: false,
  listItems: [],
  fieldList: [],
  selectedItemId: null,
  selectedItemName: null,
  setModifiedItem: () => {},
  handleClickCancelButton: () => {},
  handleClickUpdateButton: () => {},
  handleDeletePopup: () => {},
  handleAddPopup: () => {},
  isItemDisplayableDataTable: false,
  desktopConfig: {},
  titleBodyName: null,
  titleHeader: null,
  nameButton: null,
  file: null,
  setFile: () => {},
  hideAddButton: false
};
