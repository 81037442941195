import axios from 'axios';
import API_URL from '../../constants/constants';

const LicensesService = {
  findAllLicenses() {
    return axios.get(`${API_URL}/licenses`);
  },
  assignLicenses(body) {
    return axios.post(`${API_URL}/licenses/assign`, body);
  },
  removeLicenses(body) {
    return axios.delete(`${API_URL}/licenses/remove`, { data: body });
  }
};

export default LicensesService;
