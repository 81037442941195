import * as React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';
import { getNotifications, delNotification } from '../../redux/slices/notificationSlice';

const DURATION_NOTIFICATION = 3000;

const NotificationAction = React.memo(function NotificationAction({ id, closeSnackbar }) {
  return (
    <IconButton onClick={() => closeSnackbar(id)} color="inherit">
      <CancelIcon />
    </IconButton>
  );
});

NotificationAction.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  closeSnackbar: PropTypes.func.isRequired
};

function Notification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const notificationsList = useSelector(getNotifications);
  const { t } = useTranslation();

  const renderNotificationAction = React.useCallback(
    (key) => <NotificationAction id={key} closeSnackbar={closeSnackbar} />,
    [closeSnackbar]
  );

  const handleSnackBar = React.useCallback(() => {
    if (notificationsList && notificationsList.length > 0) {
      notificationsList.forEach((notificationItem, index) => {
        enqueueSnackbar(t(notificationItem.key), {
          variant: notificationItem.status,
          autoHideDuration: DURATION_NOTIFICATION,
          action: renderNotificationAction,
          'data-testid': `notificationItem${index}`
        });
      });
      dispatch(delNotification());
    }
  }, [notificationsList, enqueueSnackbar, renderNotificationAction, dispatch, t]);

  React.useEffect(() => {
    handleSnackBar();
  }, [notificationsList, handleSnackBar]);

  return null;
}

function ApiResponseHandlerReturn() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Notification />
    </SnackbarProvider>
  );
}

export default ApiResponseHandlerReturn;
