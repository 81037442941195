import axios from 'axios';
import API_URL from '../../constants/constants';

const UsersAdminService = {
  requestUserList(page, rowsPerPage) {
    return axios.get(`${API_URL}/users/${page}/${rowsPerPage}`);
  },
  requestSearchedUsers(str, page, rowsPerPage) {
    return axios.get(`${API_URL}/users/search/${str}/${page}/${rowsPerPage}`);
  },
  getAzureUser(id) {
    return axios.get(`${API_URL}/users/azure/${id}`);
  },
  azurePrivilegeUp(token) {
    return axios.patch(`${API_URL}/users/azure/privilege/up`, token);
  },
  azurePrivilegeDown() {
    return axios.patch(`${API_URL}/users/azure/privilege/down`);
  },
  getAzureUserDomains() {
    return axios.get(`${API_URL}/users/azure/domain`);
  },
  azureUserAvailableServices() {
    return axios.get(`${API_URL}/users/services`);
  },
  getOneJiraUser(key) {
    return axios.get(`${API_URL}/users/jira/${key}`);
  },
  getOneAdUser(DN) {
    return axios.post(`${API_URL}/users/ad`, { DN });
  },
  updateAdUser(modifiedUser, DN) {
    return axios.put(`${API_URL}/users/ad`, { modifiedUser, DN });
  },
  updateAdUserGroups(user, groups, oldGroups) {
    return axios.put(`${API_URL}/users/ad/modifyGroups`, { user, groups, oldGroups });
  },
  postOneAdUser(createdUser) {
    return axios.post(`${API_URL}/users/ad/create`, createdUser);
  },
  enableDisableAdUser(users, isUserEnable) {
    return axios.put(`${API_URL}/users/ad/enableDisable`, { users, isUserEnable });
  },
  findJiraUserGroups() {
    return axios.get(`${API_URL}/users/jira/groups`);
  },
  enableDisableJiraUser(key) {
    return axios.patch(`${API_URL}/users/jira/enableDisable`, { user: key });
  },
  getSubDNObject(DN) {
    return axios.post(`${API_URL}/users/ad/browseOU`, { DN });
  },
  getDC() {
    return axios.get(`${API_URL}/users/ad/browseOU`);
  },
  getMandatoryGroup() {
    return axios.get(`${API_URL}/users/ad/mandatoryGroup`);
  },
  findAdUserGroups() {
    return axios.get(`${API_URL}/groups/ad`);
  },
  getDisableText() {
    return axios.get(`${API_URL}/disableText/ad`);
  },
  createAdUsersBulk(formData) {
    return axios.post(`${API_URL}/createUsersBulk/ad`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  deleteAdUsersBulk(formData) {
    return axios.post(`${API_URL}/deleteUsersBulk/ad`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  editAdUsersBulk(formData) {
    return axios.post(`${API_URL}/editUsersBulk/ad`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  requestActionsList(page, rowsPerPage) {
    return axios.get(`${API_URL}/users/actions/${page}/${rowsPerPage}`);
  },
  requestSearchedActions(str, page, rowsPerPage, filterRequest) {
    return axios.get(
      `${API_URL}/users/actions/search/${page}/${rowsPerPage}/${str}${filterRequest}`
    );
  },
  getBoondResourceById(id) {
    return axios.get(`${API_URL}/boond/resources/${id}`);
  },
  requestActionsDetails(id) {
    return axios.get(`${API_URL}/users/actions/${id}`);
  }
};

export default UsersAdminService;
