import React, { useEffect, useState } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  FormLabel,
  TextField,
  Autocomplete,
  DialogTitle,
  createFilterOptions,
  DialogActions,
  ListItem,
  ListItemText,
  Checkbox,
  useTheme,
  FormControl,
  FormHelperText
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styleScrollbar } from '../../assets/style/utils/utils';
import {
  selectSelectedDevice,
  assignUserToIntuneDevice,
  removeUserToIntuneDevice,
  renameIntuneDeviceName
} from '../../redux/slices/IntuneDevices';
import { selectedAzureUsers } from '../../redux/slices/AzureUsersList';
import CustomizedButton from '../buttons/CustomizedButton';
import { styleDialogActions, styleHelperText } from '../../assets/style/views/AzurADUserPopup';
import { styleFlexWrap } from '../../assets/style/components/popup/EditIntuneDevicePopup';
import { regexIntuneDeviceName } from '../../utils/regex';
import { styleCheckbox } from '../../assets/style/views/UserPages';

export default function EditIntuneDevicePopup({ popup, closePopup }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const device = useSelector(selectSelectedDevice);
  const azureUsers = useSelector(selectedAzureUsers);

  const [selectedUser, setSelectedUser] = useState({});
  const [isRemoveUserBtnDisable, setIsRemoveUserBtnDisabled] = useState(true);
  const [isAssignUserBtnDisable, setIsAssignUserBtnDisabled] = useState(true);
  const [deviceNameAndReboot, setDeviceNameAndReboot] = useState({
    newDeviceName: device.deviceName,
    reboot: false
  });
  useEffect(() => {
    if (
      Object.keys(selectedUser).length > 0 &&
      (Object.keys(device.user).length === 0 || selectedUser.id !== device.user.id)
    ) {
      setIsAssignUserBtnDisabled(false);
    } else {
      setIsAssignUserBtnDisabled(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (Object.keys(device.user).length > 0) setIsRemoveUserBtnDisabled(false);
  }, []);

  const isDisabledRenameDeviceName =
    device.deviceName === deviceNameAndReboot.newDeviceName ||
    !regexIntuneDeviceName(deviceNameAndReboot.newDeviceName);

  const handleAssignPrincipalUser = () => {
    const body = { userId: selectedUser.id };
    dispatch(assignUserToIntuneDevice({ deviceId: device.id, body }));
    closePopup();
  };

  const handleRemovePrincipalUser = () => {
    dispatch(removeUserToIntuneDevice(device.id));
    closePopup();
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => {
      return option.displayName;
    },
    limit: 50
  });
  const handleChangeDeviceName = (event) => {
    setDeviceNameAndReboot({ ...deviceNameAndReboot, newDeviceName: event.target.value });
  };
  const handleRebootDevice = () => {
    setDeviceNameAndReboot({ ...deviceNameAndReboot, reboot: !deviceNameAndReboot.reboot });
  };

  const handleDeviceName = () => {
    dispatch(renameIntuneDeviceName({ deviceId: device.id, body: deviceNameAndReboot }));
    closePopup();
  };

  return (
    <Dialog
      open={popup}
      onClose={closePopup}
      aria-labelledby="alert-dialog-editDevice"
      aria-describedby="alert-dialog-editDevice"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{t('manageDeviceInformation')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl sx={{ '& label.Mui-focused': { color: theme.palette.colorBlack } }}>
          <FormLabel align="left">{t('deviceName')} :</FormLabel>
          <TextField
            defaultValue={device.deviceName}
            error={!regexIntuneDeviceName(deviceNameAndReboot.newDeviceName)}
            data-testid="deviceNameField"
            onChange={(event) => handleChangeDeviceName(event)}
            variant="standard"
          />{' '}
          {regexIntuneDeviceName(deviceNameAndReboot.newDeviceName) ? null : (
            <FormHelperText sx={styleHelperText} data-testid="helperTextDeviceName">
              &#x25CF; {t('helperTextMaxLength')} <br />
              &#x25CF; {t('helperTextContent')} <br />
              &#x25CF; {t('helperTextExclude')}
            </FormHelperText>
          )}
          <Grid item xs={9}>
            <FormLabel>{t('renameReboot')}</FormLabel>
            <Checkbox
              data-testid="rebootCheckbox"
              onChange={handleRebootDevice}
              sx={styleCheckbox(theme)}
            />
          </Grid>
          <CustomizedButton
            datatestid="buttonChangeDeviceName"
            disabled={isDisabledRenameDeviceName}
            onClick={handleDeviceName}
            text={t('rename')}
          />
        </FormControl>
        <FormControl sx={{ '& label.Mui-focused': { color: theme.palette.colorBlack } }}>
          <Grid item xs={12} sx={{ marginTop: '10px' }}>
            <FormLabel align="center">{t('userPrincipalName')} :</FormLabel>
          </Grid>
          <Grid container item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid>
              <Autocomplete
                data-testid="select-devicePrincipalUser"
                options={[...azureUsers, { displayName: t('none') }]}
                filterOptions={filterOptions}
                sx={{ width: '100%' }}
                disableClearable
                ListboxProps={{ sx: styleScrollbar(theme) }}
                getOptionLabel={(azureUser) => azureUser.displayName}
                defaultValue={device.user?.id ? device.user : { displayName: t('none') }}
                isOptionEqualToValue={(options, value) => options.id === value.id}
                onChange={(e, user) => setSelectedUser(user)}
                renderOption={(props, option) => {
                  return (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <ListItem {...props} key={option.id} data-testid={`option${option.id}`}>
                      <ListItemText>{option.displayName}</ListItemText>
                    </ListItem>
                  );
                }}
                renderInput={({ InputLabelProps, InputProps, inputProps }) => {
                  return (
                    <TextField
                      data-testid="selectUserPrincipalName"
                      sx={{ width: '100%' }}
                      InputLabelProps={InputLabelProps}
                      InputProps={InputProps}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={inputProps}
                    />
                  );
                }}
              />
            </Grid>
            <Grid display="flex" sx={styleFlexWrap}>
              <CustomizedButton
                datatestid="buttonAssignUserPrincipal"
                disabled={isAssignUserBtnDisable}
                onClick={handleAssignPrincipalUser}
                text={t('assignPrincipalUser')}
              />
              <CustomizedButton
                datatestid="buttonRemoveUserPrincipal"
                disabled={isRemoveUserBtnDisable}
                onClick={handleRemovePrincipalUser}
                text={t('removePrincipalUser')}
              />
            </Grid>
          </Grid>
        </FormControl>
      </DialogContent>
      <DialogActions sx={styleDialogActions}>
        <CustomizedButton
          onClick={() => closePopup()}
          text={t('closePopup')}
          datatestid="closePopup"
        />
      </DialogActions>
    </Dialog>
  );
}

EditIntuneDevicePopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired
};
