import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, Autocomplete, TextField } from '@mui/material';

export default function AutocompleteComponent({ name, error, onChange, options }) {
  const optionLabels = options.map((option) => ({ label: option }));
  const [initialOptions, setInitialOptions] = useState(optionLabels);

  const handleChange = (event, selectedOption) => {
    if (initialOptions.length === optionLabels.length) {
      setInitialOptions(optionLabels);
    }
    onChange(name, selectedOption ? selectedOption.label : '');
  };

  return (
    <Grid container my={1}>
      <FormControl fullWidth>
        <Autocomplete
          options={optionLabels}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(value) => {
            const initialValueLabels = initialOptions.map((option) => option.label);
            return initialValueLabels.includes(value.label);
          }}
          onChange={handleChange}
          data-testid="columnIdAutocomplete"
          renderInput={(params) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField {...params} error={error} label={name} />
          )}
        />
      </FormControl>
    </Grid>
  );
}

AutocompleteComponent.propTypes = {
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string)
};

AutocompleteComponent.defaultProps = {
  error: false,
  options: []
};
