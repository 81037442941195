import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Select
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CustomizedButton from '../buttons/CustomizedButton';
import CustomFiledDrop from '../fileDrop/CustomFileDrop';
import hasPermissions from '../../utils/Permissions/permissions';
import { selectPermissions } from '../../redux/slices/userConnected';
import { colorJumpPage } from '../../assets/style/views/UserPages';
import { colorScrollBar, styleScrollbar } from '../../assets/style/utils/utils';

export default function CsvBulkPopup({
  open,
  handleClose,
  handleActions,
  actionTitle,
  setFormDataBulk
}) {
  const [fileName, setFileName] = useState('');
  const permissions = useSelector(selectPermissions);
  const { t } = useTranslation();

  useEffect(() => {
    setFileName('');
  }, [open]);

  const [csvAction, setCsvAction] = useState('none');
  const [csvActions, setCsvActions] = useState([{ value: 'none', text: t('none') }]);

  const handleChange = (event) => {
    setCsvAction(event.target.value);
  };

  const handleSubmitAction = () => {
    handleActions[csvAction]();
    setCsvAction('none');
  };

  useEffect(() => {
    const temp = [{ value: 'none', text: t('none') }];
    if (hasPermissions(permissions, 'ADD_AD_USERS')) {
      temp.push({ value: 'create', text: t('create') });
    }
    if (hasPermissions(permissions, 'SET_AD_USERS')) {
      temp.push({ value: 'edit', text: t('edit') });
    }
    if (hasPermissions(permissions, 'DELETE_AD_USERS')) {
      temp.push({ value: 'delete', text: t('delete') });
    }
    setCsvActions(temp);
  }, [permissions]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: styleScrollbar }}
    >
      <DialogTitle id="alert-dialog-title">{actionTitle}</DialogTitle>
      <DialogContent sx={styleScrollbar}>
        <DialogContentText
          sx={(theme) => ({ color: { color: theme.palette.colorText } })}
          id="alert-dialog-description"
        >
          {t('choseYourAction')}
        </DialogContentText>

        <Select
          id="selectBulkAction"
          value={csvAction}
          onChange={handleChange}
          data-testid="selectBulkAction"
          sx={colorScrollBar}
        >
          {csvActions.map(({ value, text }) => (
            <MenuItem sx={colorJumpPage} value={value} key={value} data-testid={`select${value}`}>
              {text}
            </MenuItem>
          ))}
        </Select>

        <DialogContentText
          sx={(theme) => ({ color: { color: theme.palette.colorText } })}
          id="alert-dialog-description"
        >
          {t('uploadYourCsv')}
        </DialogContentText>
        <CustomFiledDrop setFile={setFormDataBulk} setFileName={setFileName} fileName={fileName} />
      </DialogContent>
      <DialogActions>
        <CustomizedButton onClick={handleClose} text={t('cancel')} datatestid="cancelConsent" />
        <CustomizedButton
          disabled={fileName.length === 0 || csvAction === 'none'}
          onClick={() => handleSubmitAction()}
          text={t('confirm')}
          datatestid="confirmButton"
        />
      </DialogActions>
    </Dialog>
  );
}

CsvBulkPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleActions: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.func])).isRequired,
  actionTitle: PropTypes.string.isRequired,
  setFormDataBulk: PropTypes.func.isRequired
};
