import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, FormControl, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import iso31661 from 'iso-3166-1';
import iso6391 from 'iso-639-1';
import { styleDialogActions } from '../../assets/style/views/AzurADUserPopup';
import CustomizedButton from '../buttons/CustomizedButton';
import { azureColumnsRules } from '../../utils/regex';
import {
  selectAllDomains,
  selectAzureUserAvailableServices,
  selectAzureUsers
} from '../../redux/slices/AzureUsersList';
import { styleScrollbar } from '../../assets/style/utils/utils';
import { selectColumnsEdit } from '../../redux/slices/columns';
import CustomFormField from '../form/CustomFormField';

export default function AzureADUserPopup({ popup, user, closePopup, updateAzureUser }) {
  const { t } = useTranslation();
  const [modifiedUser, setModifiedUser] = useState(user);
  const azureUsers = useSelector(selectAzureUsers(''));
  const listAllDomain = useSelector(selectAllDomains);
  const [domain, setDomain] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const azureUserAvailableServices = useSelector(selectAzureUserAvailableServices);
  const azureColumns = useSelector(selectColumnsEdit).filter(
    ({ providerName }) => providerName === 'AZURE_ACTIVE_DIRECTORY'
  );

  const findArgSForRegexByAzureColumn = (azureColumn) => {
    const azureUsersDisplayName = azureUsers.map((elem) => elem.displayName);
    const azureUsersDisplayNameUnique = azureUsersDisplayName.filter(
      (value, index) => azureUsersDisplayName.indexOf(value) === index
    );

    switch (azureColumn) {
      case 'manager':
        return azureUsersDisplayNameUnique;
      case 'department':
        return azureUserAvailableServices;
      case 'usageLocation':
        return iso31661.all().map((elem) => `${elem.country} - ${elem.alpha2}`);
      case 'preferredLanguage':
        return iso6391.getAllNames();
      case 'password':
        return `${modifiedUser.givenName.toLowerCase()} ${modifiedUser.surname.toUpperCase()}`;
      default:
        return undefined;
    }
  };

  const error = (azureColumn, isNullable, isEditable) => {
    if (
      isEditable &&
      azureColumn in azureColumnsRules &&
      ((!isNullable && !modifiedUser[azureColumn]) ||
        (modifiedUser[azureColumn] &&
          !azureColumnsRules[azureColumn].rule(
            modifiedUser[azureColumn],
            findArgSForRegexByAzureColumn(azureColumn)
          )))
    ) {
      return true;
    }
    return false;
  };

  const formatUser = () => {
    const formattedUser = { ...modifiedUser };
    if ('preferredLanguage' in formattedUser)
      formattedUser.preferredLanguage = iso6391.getCode(formattedUser.preferredLanguage);
    if ('usageLocation' in formattedUser)
      formattedUser.usageLocation = formattedUser.usageLocation.split('-')[1].trim();
    if ('manager' in formattedUser) {
      const i = azureUsers.findIndex((e) => e.displayName === formattedUser.manager);
      if (i > -1) {
        formattedUser.manager = azureUsers[i].id;
      }
    }
    if (formattedUser.memberOf) {
      delete formattedUser.memberOf;
    }
    return formattedUser;
  };

  const handleClick = () => {
    setIsSubmit(true);
    const fieldErrorStatus = azureColumns.map(({ columnId, isNullable, isEditable }) => {
      return error(columnId, isNullable, isEditable);
    });

    if (!fieldErrorStatus.includes(true)) {
      updateAzureUser({
        ...formatUser(),
        userPrincipalName: `${modifiedUser.userPrincipalName}@${domain}`
      });
    }
  };
  const isThereError = azureColumns
    .map(({ columnId, isEditable, isNullable }) => error(columnId, isNullable, isEditable))
    .includes(true);

  useEffect(() => {
    const tempModifiedUser = { ...modifiedUser };
    if (listAllDomain.length) {
      if (user && user.userPrincipalName) setDomain(user.userPrincipalName.split('@')[1]);
      else setDomain(listAllDomain[0]);
    }
    if (user.preferredLanguage)
      tempModifiedUser.preferredLanguage = iso6391.getName(user.preferredLanguage);
    if (user.usageLocation) {
      const usgLocation = iso31661.whereAlpha2(user.usageLocation);
      tempModifiedUser.usageLocation = `${usgLocation.country} - ${usgLocation.alpha2}`;
    }
    if (azureUsers.length && user.manager) {
      tempModifiedUser.manager = azureUsers.filter(({ id }) => id === user.manager)[0].displayName;
    }
    setModifiedUser(tempModifiedUser);
  }, [listAllDomain, azureUsers, user, popup]);

  return (
    <Dialog
      open={popup}
      onClose={closePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="AzureUserPopUp"
      PaperProps={{ sx: styleScrollbar }}
    >
      <DialogContent sx={styleScrollbar}>
        <FormControl
          sx={(theme) => ({ '& label.Mui-focused': { color: theme.palette.colorBlack } })}
        >
          {azureColumns &&
            azureColumns
              .filter(({ columnId }) => columnId !== 'memberOf')
              .map(({ columnId, columnName, input, isEditable, isNullable }) => (
                <Grid key={columnId}>
                  <CustomFormField
                    columnId={columnId}
                    columnName={columnName}
                    input={input}
                    isEditable={isEditable}
                    isNullable={isNullable}
                    valueObject={modifiedUser}
                    setValueObject={setModifiedUser}
                    error={error}
                    isSubmit={isSubmit}
                    ruleMessageKey={azureColumnsRules[columnName]?.ruleMessageKey}
                    domain={domain}
                    setDomain={setDomain}
                    listAllDomain={listAllDomain}
                    findArgsForRegexByColumnName={findArgSForRegexByAzureColumn}
                  />
                </Grid>
              ))}
        </FormControl>
      </DialogContent>
      <DialogActions sx={styleDialogActions}>
        <CustomizedButton onClick={closePopup} text={t('cancel')} datatestid="closePopup" />
        <CustomizedButton
          datatestid="ButtonUpdateAzureUser"
          disabled={isThereError}
          onClick={handleClick}
          text={t('save')}
        />
      </DialogActions>
    </Dialog>
  );
}

AzureADUserPopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
          )
        ])
      ),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.arrayOf(
            PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.objectOf(
                PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
              )
            ])
          )
        ])
      )
    ])
  ).isRequired,
  closePopup: PropTypes.func.isRequired,
  updateAzureUser: PropTypes.func.isRequired
};
