import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material/';
import MuiDrawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  styleClosedMixin,
  StyleSideBarWrapper,
  classes
} from '../../assets/style/components/layout/SideBar';
import { styleScrollbar } from '../../assets/style/utils/utils';
import {
  selectEmail,
  selectName,
  selectPermissions,
  selectPhoto
} from '../../redux/slices/userConnected';
import { selectNotReadCounter } from '../../redux/slices/Messages';
import SubMenuUserList from './SideBar/SubMenuList';
import hasPermissions from '../../utils/Permissions/permissions';
import SubMenuAccountList from './SideBar/SubMenuAccountList';
import SideBarList from './SideBarList';
import SubMenuBackOffice from './SideBar/SubMenuBackOffice';

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: '100%',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...{
      width: '100%',
      overflowX: 'hidden'
    },
    '& .MuiDrawer-paper': {
      width: '100%',
      overflowX: 'hidden'
    }
  }),
  ...(!open && {
    ...styleClosedMixin(theme),
    '& .MuiDrawer-paper': styleClosedMixin(theme)
  })
}));

export default function MobileSideBar({
  isOpenSideBar,
  style,
  reductMobileSideBar,
  openSubMenuId,
  openReductedSubMenuId,
  setOpenSubMenuId,
  setOpenReductedSubMenuId
}) {
  const handleClick = (id) => {
    if (isOpenSideBar) {
      return openSubMenuId === id ? setOpenSubMenuId('none') : setOpenSubMenuId(id);
    }
    setOpenSubMenuId('none');
    return openReductedSubMenuId === id
      ? setOpenReductedSubMenuId('none')
      : setOpenReductedSubMenuId(id);
  };
  const theme = useTheme();
  const email = useSelector(selectEmail);
  const name = useSelector(selectName);
  const userPhoto = useSelector(selectPhoto);

  const permissions = useSelector(selectPermissions);
  const notReadCounter = useSelector(selectNotReadCounter);
  const subMenuListByPerm = SubMenuUserList(notReadCounter).reduce((array, element) => {
    if (element.child) {
      const childSubMenuListByPerm = element.child.filter((childElement) =>
        hasPermissions(permissions, childElement.codename)
      );
      if (childSubMenuListByPerm.length > 0) {
        array.push({ ...element, child: childSubMenuListByPerm });
      }
    } else if (hasPermissions(permissions, element.codename)) {
      array.push(element);
    }
    return array;
  }, []);
  const subMenuListProfile = SubMenuAccountList().reduce((array, element) => {
    if (element.child) {
      const childSubMenuListByPerm = element.child.filter((childElement) =>
        hasPermissions(permissions, childElement.codename)
      );
      if (childSubMenuListByPerm.length > 0) {
        array.push({ ...element, child: childSubMenuListByPerm });
      }
    } else if (hasPermissions(permissions, element.codename) || element.codename === 'LOGOUT') {
      array.push(element);
    }
    return array;
  }, []);

  const subMenuListBackOffice = SubMenuBackOffice().reduce((array, element) => {
    if (element.child) {
      const childSubMenuListByPerm = element.child.filter((childElement) =>
        hasPermissions(permissions, childElement.codename)
      );
      if (childSubMenuListByPerm.length > 0) {
        array.push({ ...element, child: childSubMenuListByPerm });
      }
    } else if (hasPermissions(permissions, element.codename)) {
      array.push(element);
    }
    return array;
  }, []);

  const isBackOffice = localStorage.getItem('isBackOffice');
  const currentPathLocation = `/${useLocation().pathname.split('/')[1]}`;
  return (
    <Drawer variant="permanent" open={isOpenSideBar} sx={style} PaperProps={{ sx: styleScrollbar }}>
      <StyleSideBarWrapper
        container
        className={`${classes.sideBarWrapper} ${classes.sideBarMobile}`}
      >
        <Box
          sx={{
            background: theme.palette.colorMainGradient,
            height: '100%',
            width: '100%',
            marginTop: '50px'
          }}
        >
          <SideBarList
            subMenuList={[
              ...(!isBackOffice ? subMenuListByPerm : subMenuListBackOffice),
              ...subMenuListProfile
            ]}
            isOpenSideBar={isOpenSideBar}
            reductMobileSideBar={reductMobileSideBar}
            openSubMenuId={openSubMenuId}
            openReductedSubMenuId={openReductedSubMenuId}
            setOpenSubMenuId={setOpenSubMenuId}
            setOpenReductedSubMenuId={setOpenReductedSubMenuId}
          />
          <ListItem
            disablePadding
            className={classes.sideBarMobileListItem}
            onClick={() => handleClick('profile')}
            selected={currentPathLocation === '/profile'}
          >
            <Link
              component={RouterLink}
              to="/profile"
              data-testid="SideBarLinkProfile"
              className={classes.sideBarMobileLink}
            >
              <ListItemButton className={classes.sideBarMobileListButton} id="basic-button">
                <ListItemIcon className={classes.sideBarMobileListIcon}>
                  <img
                    alt="hello"
                    height="50px"
                    style={{ marginLeft: '10px', borderRadius: '8px' }}
                    src={userPhoto}
                  />
                </ListItemIcon>
                <Grid container>
                  <Grid item xs={12}>
                    <ListItemText primary={name} className={classes.sideBarMobileListItemText} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.sideBarMobileListItemTypo}>{email}</Typography>
                  </Grid>
                </Grid>
              </ListItemButton>
            </Link>
          </ListItem>
        </Box>
      </StyleSideBarWrapper>
    </Drawer>
  );
}

MobileSideBar.propTypes = {
  isOpenSideBar: PropTypes.bool.isRequired,
  reductMobileSideBar: PropTypes.func,
  style: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)])
    )
  ]).isRequired,
  openSubMenuId: PropTypes.string.isRequired,
  openReductedSubMenuId: PropTypes.string.isRequired,
  setOpenSubMenuId: PropTypes.func.isRequired,
  setOpenReductedSubMenuId: PropTypes.func.isRequired
};

MobileSideBar.defaultProps = {
  reductMobileSideBar: () => null
};
