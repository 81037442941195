import { React, useState } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StandardBox from '../StandardBox';
import InformationList from '../InformationList';
import { selectedUser, selectEmployeeManager } from '../../redux/slices/DayforceUsers';
import formatAddress from '../../utils/formatDayforce';
import AdGroupList from '../adUserGroups/AdGroupList';
import DayforceEditPopup from '../popup/DayforceEditPopup';
import hasPermissions from '../../utils/Permissions/permissions';
import { selectPermissions } from '../../redux/slices/userConnected';

export default function DayforceDescription() {
  const user = useSelector(selectedUser);
  const employeeManager = useSelector(selectEmployeeManager);
  const [popupUpdate, setPopupUpdate] = useState(false);
  const { t } = useTranslation();
  const permissions = useSelector(selectPermissions);

  const openPopup = () => {
    setPopupUpdate(true);
  };

  const columns = [
    { name: 'DisplayName', key: 'DisplayName', type: 'string' },
    { name: 'FirstName', key: 'FirstName', type: 'string' },
    { name: 'LastName', key: 'LastName', type: 'string' },
    { name: 'BirthDate', key: 'BirthDate', type: 'string' },
    { name: 'GenderIdentity', key: 'GenderIdentity', type: 'string' },
    { name: 'Culture', key: 'Culture', type: 'string' },
    { name: 'Address', key: 'Address', type: 'string', format: formatAddress },
    { name: 'id', key: 'id', type: 'string' },
    { name: 'EmployeePin', key: 'EmployeePin', type: 'string' },
    { name: 'HireDate', key: 'HireDate', type: 'string' },
    { name: 'StartDate', key: 'StartDate', type: 'string' },
    { name: 'LoginId', key: 'LoginId', type: 'string' },
    { name: 'EmployeeNumber', key: 'EmployeeNumber', type: 'string' },
    { name: 'XRefCode', key: 'XRefCode', type: 'string' },
    { name: 'LastModifiedTimestamp', key: 'LastModifiedTimestamp', type: 'string' }
  ];

  const columnsManager = [
    { name: 'ManagerFirstName', key: 'ManagerFirstName', type: 'string' },
    { name: 'ManagerLastName', key: 'ManagerLastName', type: 'string' },
    { name: 'EffectiveStart', key: 'EffectiveStart', type: 'string' },
    { name: 'ManagerXRefCode', key: 'ManagerXRefCode', type: 'string' },
    { name: 'ManagerBadgeNumber', key: 'ManagerBadgeNumber', type: 'string' }
  ];

  const listButton = [
    {
      hasPermissions: hasPermissions(permissions, 'SET_DAYFORCE_USER'),
      text: `${t('editable')}`,
      datatestid: 'dayforceUserEdit',
      onClick: openPopup
    }
  ];

  return (
    <Grid>
      <StandardBox
        buttons={listButton}
        title="DayforceUser"
        ChildComponent={<InformationList row={user} columns={columns} />}
      />
      {employeeManager && (
        <StandardBox
          title="EmployeeManager"
          ChildComponent={<InformationList row={employeeManager} columns={columnsManager} />}
        />
      )}
      {user && user.Roles && (
        <StandardBox
          title="RoleUserDayforce"
          ChildComponent={
            <AdGroupList selectedGroups={user.Roles.map(({ Role }) => ({ name: Role }))} />
          }
        />
      )}
      <DayforceEditPopup
        popup={popupUpdate}
        closePopup={() => {
          setPopupUpdate(false);
        }}
        user={user}
      />
    </Grid>
  );
}
