import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import StandardBox from '../StandardBox';
import InformationList from '../InformationList';
import CheckingPopUp from '../CheckingPopUp';
import AzureADUserPopup from '../popup/AzureADUserPopup';
import { selectColumnsInDetails } from '../../redux/slices/columns';
import { selectPermissions, selectGroup } from '../../redux/slices/userConnected';
import {
  selectedUser,
  updateAzureUserAccount,
  updateAzureUser,
  changePasswordAzureUser
} from '../../redux/slices/AzureUsersList';
import { azureColumnsRules } from '../../utils/regex';
import hasPermissions from '../../utils/Permissions/permissions';

export default function UserStandardDescription() {
  const dispatch = useDispatch();
  const user = useSelector(selectedUser);
  const permissions = useSelector(selectPermissions);
  const { t } = useTranslation();
  const group = useSelector(selectGroup);
  const [popup, setPopup] = useState(false);
  const [openDisableAzureUser, setOpenDisableAzureUser] = useState(false);
  const [error, setError] = useState();
  const [helperText, setHelperText] = useState();
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const azureColumnsFilter = useSelector(selectColumnsInDetails);

  const openPopup = () => {
    setPopup(true);
  };
  const handleUpdateAzureUser = async (modifiedUser) => {
    const res = await dispatch(updateAzureUser(modifiedUser));
    if (res.payload.success) {
      setPopup(false);
    }
  };
  const openPopUpDisableAzureUser = () => {
    setOpenDisableAzureUser(true);
  };
  const closePopUpDisableAzureUser = () => {
    setOpenDisableAzureUser(false);
  };
  const closeConfirmPopUpDisableAzureUser = async () => {
    const userToUpdate = [user.id];
    await dispatch(updateAzureUserAccount(userToUpdate));
    setOpenDisableAzureUser(false);
  };

  const openPopupChangePassword = () => {
    setOpenChangePassword(true);
  };
  const [newPassword, setNewPassword] = useState('');
  const closeConfirmPopupChangePassword = async () => {
    if (!azureColumnsRules.password.rule(newPassword)) {
      setHelperText(t(azureColumnsRules.password.ruleMessageKey));
      setError(true);
    } else {
      setError(false);
      setHelperText('');
      await dispatch(
        changePasswordAzureUser({
          userId: user.id,
          changePassword: newPassword
        })
      );
      setOpenChangePassword(false);
    }
  };
  const closePopupChangePassword = async () => {
    setError(false);
    setHelperText('');
    setOpenChangePassword(false);
  };
  const listButton = [
    {
      hasPermissions: hasPermissions(permissions, 'SET_AZURE_USER'),
      text: `${t('editable')}`,
      datatestid: 'AzureADActionsEdit',
      onClick: openPopup
    },
    {
      hasPermissions: hasPermissions(permissions, 'SET_DISABLED_AZURE_USER'),
      text:
        user.accountEnabled === true ? `${t('disablUserAD')}` : `${t('enableAccountAzureUser')}`,
      datatestid: 'AzureADActionsDisable',
      onClick: openPopUpDisableAzureUser
    },
    {
      hasPermissions: hasPermissions(permissions, 'SET_RESET_PASSWORD_AZURE_USER'),
      text: `${t('changePassword')}`,
      datatestid: 'AzureADActionsChangePassword',
      onClick: openPopupChangePassword
    }
  ];
  const listChecking = [
    {
      openPop: openDisableAzureUser,
      onClose: closePopUpDisableAzureUser,
      onClickValidate: closeConfirmPopUpDisableAzureUser,
      datatestidCancel: 'AzureADActionsCancelDisable',
      datatestidValidate: 'AzureADActionsAcceptDisable',
      dialogText: user.accountEnabled ? 'validateDisablUserAD' : 'validateEnableAccountAzureUser'
    },
    {
      openPop: openChangePassword,
      onClose: closePopupChangePassword,
      onClickValidate: closeConfirmPopupChangePassword,
      datatestidCancel: 'AzureADActionsCancelChangePassword',
      datatestidValidate: 'AzureADActionsAcceptChangePassword',
      dialogText: 'validateChangePassword',
      isTextField: true,
      errorValidation: error,
      helperTextCheck: helperText,
      typeTextField: 'password'
    }
  ];

  return (
    group.name !== 'user' && (
      <Grid>
        <StandardBox
          buttons={listButton}
          title="AzureAD"
          ChildComponent={
            <InformationList
              row={user}
              columns={azureColumnsFilter.map(({ columnId, columnName }) => {
                if (columnId === 'accountEnabled')
                  return { name: columnName, key: columnId, type: 'icon' };
                return { name: columnName, key: columnId, type: 'string' };
              })}
            />
          }
        />
        {listChecking.map(
          ({
            openPop,
            onClose,
            onClickValidate,
            datatestidCancel,
            datatestidValidate,
            dialogText,
            isTextField,
            errorValidation,
            helperTextCheck,
            typeTextField
          }) => (
            <CheckingPopUp
              key={dialogText}
              openPop={openPop}
              onClose={onClose}
              onClickValidate={onClickValidate}
              datatestidCancel={datatestidCancel}
              datatestidValidate={datatestidValidate}
              dialogText={dialogText}
              isTextField={isTextField}
              errorValidation={errorValidation}
              helperTextCheck={helperTextCheck}
              typeTextField={typeTextField}
              setNewPassword={setNewPassword}
            />
          )
        )}
        <AzureADUserPopup
          popup={popup}
          user={user}
          closePopup={() => {
            setPopup(false);
          }}
          updateAzureUser={handleUpdateAzureUser}
        />
      </Grid>
    )
  );
}
