import React from 'react';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';
import Badge from '@mui/material/Badge';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import MonitorRoundedIcon from '@mui/icons-material/MonitorRounded';
import HistoryIcon from '@mui/icons-material/History';
import { styleIconSize } from '../../../assets/theme/utils';

const iconSize = 32;
function SubMenuUserList(notifCount) {
  return [
    {
      id: 'homePage',
      codename: 'GET_DASHBOARD',
      icon: <GridViewRoundedIcon sx={styleIconSize(iconSize)} />,
      path: '/home',
      openChild: false,
      child: null,
      datatestid: 'SubMenuList-homepage'
    },
    {
      id: 'users',
      icon: <GroupsRoundedIcon sx={styleIconSize(iconSize)} />,
      openChild: false,
      child: [
        {
          path: '/adUsers',
          id: 'adTitle',
          datatestid: 'submenuAdUsers',
          codename: 'GET_AD_USERS'
        },
        {
          path: '/azureUsers',
          id: 'azureTitle',
          datatestid: 'submenuAzureUsers',
          codename: 'GET_AZURE_USERS'
        },
        {
          path: '/jiraUsers',
          id: 'jiraTitle',
          datatestid: 'submenuJiraUsers',
          codename: 'GET_JIRA_USERS'
        },
        {
          path: '/synchroAdAzure',
          id: 'synchroAdAzureTitle',
          datatestid: 'submenuaduserAzure',
          codename: 'GET_SYNCHRO_AD_AZURE_USERS'
        },
        {
          path: '/boondManagerResources',
          id: 'boondManagerResourcesTitle',
          datatestid: 'submenuBoondManagerResources',
          codename: 'GET_BOONDMANAGER_RESOURCES'
        },
        {
          path: '/activixLeads',
          id: 'activixLeadsTitle',
          datatestid: 'submenuaduserActivix',
          codename: 'GET_ACTIVIX_LEAD'
        },
        {
          path: '/dayforceUsers',
          id: 'dayforceTitle',
          datatestid: 'dayforceResources',
          codename: 'GET_DAYFORCE_USERS'
        }
      ],
      datatestid: 'SubMenuList-users'
    },
    {
      id: 'sms',
      codename: 'GET_SMS',
      icon: notifCount ? (
        <Badge badgeContent={notifCount} color="warning">
          <SmsRoundedIcon sx={styleIconSize(iconSize)} />
        </Badge>
      ) : (
        <SmsRoundedIcon sx={styleIconSize(iconSize)} />
      ),
      path: '/SMS',
      openChild: false,
      child: null,
      datatestid: 'SubMenuList-sms'
    },
    {
      id: 'devices',
      icon: <MonitorRoundedIcon sx={styleIconSize(iconSize)} />,
      openChild: false,
      child: [
        {
          path: '/devicesIntune',
          id: 'intuneTitle',
          datatestid: 'submenuDevicesIntune',
          codename: 'GET_INTUNE_DEVICES'
        },
        {
          path: '/devicesJira',
          id: 'jiraTitle',
          datatestid: 'submenuDevicesJira',
          codename: 'GET_JIRA_DEVICES'
        }
      ],
      datatestid: 'SubMenuList-devices'
    },
    {
      id: 'azureLicenses',
      codename: 'GET_AZURE_LICENSES',
      icon: <ModeEditRoundedIcon sx={styleIconSize(iconSize)} />,
      path: '/azureLicenses',
      openChild: false,
      child: null,
      datatestid: 'SubMenuList-azureLicenses'
    },
    // {
    //   id: 'settings',
    //   codename: 'GET_SETTINGS',
    //   icon: <Settings sx={styleIconSize(iconSize)} />,
    //   path: '/settings',
    //   child: null,
    //   datatestid: 'SubMenuList-settings'
    // },
    {
      id: 'actionsHistory',
      codename: 'GET_ACTIONS_HISTORY',
      icon: <HistoryIcon sx={styleIconSize(iconSize)} />,
      path: '/actionsHistory',
      openChild: false,
      child: null,
      datatestid: 'SubMenuList-actionsHistory'
    }
  ];
}

export default SubMenuUserList;
