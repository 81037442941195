import React from 'react';
import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styleScrollbar } from '../../assets/style/utils/utils';
import JiraSetGroups from '../jiraUserGroups/JiraSetGroups';
import { setAssignJiraGroup, removeJiraGroup } from '../../redux/slices/JiraUsers';
import hasPermissions from '../../utils/Permissions/permissions';
import { selectPermissions } from '../../redux/slices/userConnected';

export default function JiraGroupsPopup({
  selectedUsers,
  isRemove,
  popup,
  closePopup,
  resetSelectedRows
}) {
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);

  const { t } = useTranslation();

  const handleAssignJiraGroup = async (selectedJiraGroups) => {
    await dispatch(
      setAssignJiraGroup({
        users: selectedUsers,
        groups: selectedJiraGroups
      })
    );
    resetSelectedRows();
    return closePopup();
  };

  const handleRemoveJiraGroup = async (selectedJiraGroups) => {
    await dispatch(
      removeJiraGroup({
        users: selectedUsers,
        groups: selectedJiraGroups
      })
    );
    resetSelectedRows();
    return closePopup();
  };

  const assignButton = {
    hasPermissions: hasPermissions(permissions, 'SET_JIRA_GROUPS_TO_USER'),
    text: `${t('assignJiraGroups')}`,
    datatestid: 'AssignGroups'
  };

  const removeButton = {
    hasPermissions: hasPermissions(permissions, 'SET_REMOVE_JIRA_GROUPS_TO_USER'),
    text: `${t('removeJiraGroups')}`,
    datatestid: 'unAssignJiraGroups'
  };

  return (
    <Dialog
      onClose={closePopup}
      open={popup}
      fullWidth
      maxWidth="xl"
      PaperProps={{ sx: styleScrollbar }}
    >
      {isRemove ? (
        <JiraSetGroups
          closePopup={closePopup}
          setGroups={handleRemoveJiraGroup}
          numberOfUsers={selectedUsers.length}
          button={removeButton}
        />
      ) : (
        <JiraSetGroups
          closePopup={closePopup}
          setGroups={handleAssignJiraGroup}
          numberOfUsers={selectedUsers.length}
          button={assignButton}
        />
      )}
    </Dialog>
  );
}

JiraGroupsPopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isRemove: PropTypes.bool,
  resetSelectedRows: PropTypes.func
};
JiraGroupsPopup.defaultProps = {
  isRemove: false,
  resetSelectedRows: () => {}
};
