import { React, useEffect, useState } from 'react';
import { Grid, Box, Paper, Card, CardMedia, Link, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { login } from '../../../redux/slices/userConnected';
import '../../../assets/style/views/Login.css';
import {
  styleResponsiveBox,
  styleBody,
  styleGridItemMargin,
  styleCardMedia,
  styleCardSize,
  styleBackground,
  logoPegasus,
  styleLinkForgotPassword
} from '../../../assets/style/views/LoginPage';
import logoBackground from '../../../assets/images/backgroundpegasusboard.webp';
import logoCarbur from '../../../assets/images/app_logos/carbur-removebg-preview.png';
import LoginUserPassword from './LoginUserPassword';
import AuthService from '../../../services/authentication/AuthService';
import LoginProvider from './LoginProvider';
import LoadingWrapper from '../../../components/layout/LoadingWrapper';
// import { getParametersNoConnected, formatParameter } from '../../../utils/parametersPublic';
// import { BACK_URL } from '../../../constants/constants';

export default function Login() {
  const { t } = useTranslation();
  // const [parametersNotConnected, setParametersNotConnected] = useState([]);
  // const [logoBack, setLogoBack] = useState('');
  const theme = useTheme();
  const isBackOffice = useLocation().pathname.toLowerCase() === '/backoffice';

  const { pathname } = useLocation();
  const isAdminLogin = pathname === '/admin';

  const [authProviders, setAuthProviders] = useState([]);
  const getAuthProviders = async () => {
    const providers = (await AuthService.getAuthProviders())?.data?.authProviders;
    setAuthProviders(providers);
  };

  useEffect(() => {
    const fetchData = async () => {
      // await getParametersNoConnected(setParametersNotConnected);
      if (!isAdminLogin && !isBackOffice) await getAuthProviders();
    };
    fetchData();
  }, [isAdminLogin, isBackOffice]);

  // useEffect(() => {
  //   const fetchLogo = async () => {
  //     const appLogo = formatParameter(
  //       parametersNotConnected,
  //       'appLogo',
  //       logoCarbur,
  //       (logoImage) => `${BACK_URL}api/images/${logoImage}`
  //     );
  //     setLogoBack(appLogo);
  //   };
  //   fetchLogo();
  // }, [parametersNotConnected]);

  return (
    <Box sx={styleBody}>
      <Paper style={styleBackground} />
      <Card sx={styleCardSize}>
        <CardMedia component="img" sx={styleCardMedia} image={logoBackground} />
        <CardMedia component="img" image={logoCarbur} sx={logoPegasus} />
        <Grid container item xs={7} sm={10} md={8} sx={styleResponsiveBox}>
          {isAdminLogin || isBackOffice ? (
            <Grid item xs={10} sx={styleGridItemMargin}>
              <LoginUserPassword
                loginName={t('ConnectWithLogin')}
                loginAction={login}
                isBackOffice={isBackOffice}
              />
            </Grid>
          ) : (
            <Grid item xs={10} sx={styleGridItemMargin}>
              <LoadingWrapper isChildDisplayable={!!authProviders && !!authProviders.length}>
                {authProviders.map((authProvider) => (
                  <LoginProvider key={authProvider} authProvider={authProvider} />
                ))}
              </LoadingWrapper>
            </Grid>
          )}
          <Link
            target="_blank"
            href="https://passwordreset.acensi.fr/"
            datatestid="ForgetPassword"
            sx={styleLinkForgotPassword(theme)}
          >
            {t('forgotPassword')}
          </Link>
        </Grid>
      </Card>
    </Box>
  );
}
