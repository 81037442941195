import React, { useEffect, useState } from 'react';
import { Chip, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { useTheme } from '@emotion/react';
import {
  requestSearchedActionsPagination,
  resetActionsState,
  selectUpdateAt,
  selectIsLoading,
  selectIsTableDisplayable,
  selectActions,
  actionsColumns,
  selectTotalItems
} from '../../redux/slices/ActionsHistory';
import errorRedirection from '../../utils/errorRedirection';
import { formatColumnsForTable } from '../../components/datalist/ComponentsInTable';
import { permissionNames, providerNames, statusNames } from '../../constants/constants';
import ItemsList from './ItemsList';
import { styleChipColorStatus } from '../../assets/style/views/UserPages';

const formatFilterRequest = (filterReq) => {
  let finalRequest = '?';
  Object.keys(filterReq).map((key) => {
    if (filterReq[key].length)
      finalRequest += `&${key.toLowerCase().replace('name', '')}=${filterReq[key][0]}`;
    return key;
  });
  return finalRequest;
};

export default function ActionsHistory() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const actions = useSelector(selectActions);
  const updatedTime = useSelector(selectUpdateAt);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const displayColumns = useSelector(actionsColumns);

  const [totalItems, setTotalItems] = useState(0);

  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);

  const [filterRequest, setFilterRequest] = useState({});

  const actionsIsLoading = useSelector(selectIsLoading);
  const isTableDisplayable = useSelector(selectIsTableDisplayable);

  const theme = useTheme();

  const classification = {
    done: {
      backgroundColor: theme.palette.successSecondary,
      fontColor: theme.palette.successMain
    },
    rejected: {
      backgroundColor: theme.palette.errorSecondary,
      fontColor: theme.palette.errorMain
    },
    inProgress: {
      backgroundColor: theme.palette.warnSecondary,
      fontColor: theme.palette.warnMain
    },
    notDelivered: {
      backgroundColor: theme.palette.neutralSecondary,
      fontColor: theme.palette.neutralMain
    },
    default: {
      backgroundColor: theme.palette.colorMainSelected,
      fontColor: theme.palette.colorMainLight
    }
  };

  const historyCustomStatus = ({ row }) => (
    <Chip
      icon={
        <CircleRoundedIcon
          fontSize="small"
          color={classification[row?.value?.name]?.fontColor || classification?.default?.fontColor}
          sx={{ marginLeft: '6px', height: '8px' }}
        />
      }
      label={row?.status?.name}
      sx={styleChipColorStatus(theme, row?.status?.name, classification)}
    />
  );

  const optionsKeys = {
    titleNameId: 'idDetails',
    titleNameKey: 'permissionName',
    secondaryTitleKey: 'createdAt',
    otherTitleKey: ['providerName', 'fromUserData'],
    customStatus: historyCustomStatus
  };

  const navigate = useNavigate();
  const total = useSelector(selectTotalItems);

  useEffect(() => {
    setTotalItems(total);
  }, [total]);

  const dispatchRequestAllActionsPagination = async () => {
    try {
      await dispatch(
        requestSearchedActionsPagination({
          searchTerm,
          page,
          rowsPerPage,
          filterRequest: formatFilterRequest(filterRequest)
        })
      ).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const onFilterChange = (changedColumn, filterList, type, changedColumnIndex) => {
    const newFilterRequest = { ...filterRequest, [changedColumn]: filterList[changedColumnIndex] };
    setFilterRequest(newFilterRequest);
    setPage(0);
  };

  useEffect(() => {
    dispatchRequestAllActionsPagination();
    return function cleanup() {
      dispatch(resetActionsState());
    };
  }, [dispatch, page, rowsPerPage, searchTerm, filterRequest]);

  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleSearchTerm = (e) => {
    setSearchTerm(() => e);
    setPage(0);
  };

  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];

    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map((rowIndex) => actions[rowIndex === null ? 0 : rowIndex]);
    setTableSelectedRows(tempRows);
  };

  const searchBar = {
    isDisplayable: true,
    disabled: false
  };

  const columnOptions = [
    {
      name: 'idDetails',
      type: 'LinkInTable',
      linkOptions: { linkColumn: 'idDetails', linkTo: 'idDetails' },
      display: true
    },
    {
      name: 'fromUserData',
      display: true
    },
    {
      name: 'statusName',
      type: 'colorClassification',
      classification,
      display: true,
      filter: true,
      filterListOptions: statusNames
    },
    {
      name: 'permissionName',
      display: true,
      filter: true,
      filterListOptions: permissionNames
    },
    {
      name: 'providerName',
      display: true,
      filter: true,
      filterListOptions: providerNames
    },
    { name: 'createdAt', display: true },
    { name: 'deliveredAt', display: true }
  ];

  const formatedColumns = formatColumnsForTable(displayColumns, actions, columnOptions, t);

  const desktopConfig = {
    actions: {
      handleSearchTerm
    },
    searchBar,
    isDisableCheckBox: true,
    hideRefresh: true,

    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: actions
    },

    loading: {
      itemsListIsLoading: actionsIsLoading,
      isTableDisplayable
    },

    tables: {
      optionsKeys,
      formatedColumns,
      date: updatedTime,
      totalItems,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      selectedIndexRows,
      setTotalItems: () => {}
    },

    search: {
      searchTerm,
      setSearchTerm,
      isSlowSearch: true
    },

    text: {
      titleText: t('syneryxUsers'),
      bigTitleText: t('actionsHistory'),
      tagId: 'actionsHistory'
    },
    searchBarPlaceOlder: t('searchActions'),
    onFilterChange,
    isPartialData: true
  };

  return (
    <Grid>
      <ItemsList config={desktopConfig} />
    </Grid>
  );
}
