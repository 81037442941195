import axios from 'axios';
import API_URL from '../../constants/constants';

const ActivixLeadsService = {
  getAllActivixLeadsPerPage({ page, perPage, searchTerm }) {
    return axios.get(`${API_URL}/activix/lead/${page}/${perPage}/${searchTerm}`);
  },
  getActivixLeadById({ id }) {
    return axios.get(`${API_URL}/activix/lead/${id}`);
  },
  updateActivixLead(lead) {
    return axios.put(`${API_URL}/activix/lead/${lead.id}`, lead);
  },
  createActivixLead(lead) {
    return axios.post(`${API_URL}/activix/lead`, lead);
  }
};

export default ActivixLeadsService;
