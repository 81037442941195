// eslint-disable-next-line import/prefer-default-export
export const tab = [
  {
    columnId: 'DisplayName',
    columnName: 'DisplayName',
    isDisplayed: true,
    isInDetails: true,
    isEditable: true,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  },
  {
    columnId: 'LastName',
    columnName: 'LastName',
    isDisplayed: true,
    isInDetails: true,
    isEditable: true,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  },
  {
    columnId: 'FirstName',
    columnName: 'FirstName',
    isDisplayed: true,
    isInDetails: true,
    isEditable: true,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  },
  {
    columnId: 'LoginId',
    columnName: 'LoginId',
    isDisplayed: true,
    isInDetails: true,
    isEditable: false,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  },
  {
    columnId: 'Culture',
    columnName: 'Culture',
    isDisplayed: true,
    isInDetails: true,
    isEditable: false,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  },
  {
    columnId: 'BirthDate',
    columnName: 'BirthDate',
    isDisplayed: true,
    isInDetails: true,
    isEditable: true,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  },
  {
    columnId: 'GenderIdentity',
    columnName: 'GenderIdentity',
    isDisplayed: true,
    isInDetails: true,
    isEditable: false,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  },
  {
    columnId: 'id',
    columnName: 'id',
    isDisplayed: true,
    isInDetails: true,
    isEditable: false,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  },
  {
    columnId: 'EmployeeNumber',
    columnName: 'EmployeeNumber',
    isDisplayed: true,
    isInDetails: true,
    isEditable: false,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  },
  {
    columnId: 'LastModifiedTimestamp',
    columnName: 'LastModifiedTimestamp',
    isDisplayed: true,
    isInDetails: true,
    isEditable: false,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  },
  {
    columnId: 'XRefCode',
    columnName: 'XRefCode',
    isDisplayed: true,
    isInDetails: true,
    isEditable: false,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  },
  {
    columnId: 'StartDate',
    columnName: 'StartDate',
    isDisplayed: true,
    isInDetails: true,
    isEditable: false,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  },
  {
    columnId: 'HireDate',
    columnName: 'HireDate',
    isDisplayed: true,
    isInDetails: true,
    isEditable: true,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  },
  {
    columnId: 'Gender',
    columnName: 'Gender',
    isDisplayed: true,
    isInDetails: true,
    isEditable: true,
    isInCreation: false,
    isInEdit: true,
    isNullable: false,
    input: 'TEXTFIELD'
  }
];
