import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UsersSuperAdminService from '../../services/superAdmin/UsersSuperAdminService';
import { regexpEscaping } from '../../utils/regex';
import {
  getPath,
  changeAd,
  getLabelAdByDN,
  getIndicesByDistinguishedName,
  getCNfromDN
} from '../../utils/ad';
import UsersAdminService from '../../services/admin/UsersAdminService';
import enableDisabledOU, { formatGroups } from '../../utils/adUsers';
import AdUsersService from '../../services/users/AdUsersService';
import { getDateTimeFormatted, getCurrentFormattedDate } from '../../utils/Date/DateFunction';

export const initialState = {
  isLoading: false,
  isLoadingUser: false,
  updateAt: '',
  status: 'fulfilled',
  AD: [],
  ADForForm: [],
  selectedUser: {},
  groups: [],
  userText: '',
  disableText: '',
  defaultEnableUserText: '',
  adUsers: [],
  mandatoryGroup: {}
};

export const getOneAdUser = createAsyncThunk('adUser', async (payload) => {
  const res = await UsersAdminService.getOneAdUser(payload);
  return res.data;
});

export const postOneAdUser = createAsyncThunk('adUserPost', async (payload) => {
  const res = await UsersAdminService.postOneAdUser(payload);
  return res.data;
});

export const deleteAdUser = createAsyncThunk('deleteAdUser', async (payload) => {
  const res = await UsersSuperAdminService.deleteAdUser(payload);
  return res.data;
});

export const updateAdUser = createAsyncThunk('updateAdUser', async ({ modifiedUser, DN }) => {
  const res = await UsersAdminService.updateAdUser(modifiedUser, DN);
  return res.data;
});

export const updateAdUserGroups = createAsyncThunk(
  'updateAdUserGroups',
  async ({ user, groups, oldGroups }) => {
    const res = await UsersAdminService.updateAdUserGroups(user, groups, oldGroups);
    return res.data;
  }
);

export const enableDisableAdUser = createAsyncThunk(
  'enableDisableAdUser',
  async ({ users, isUserEnable }) => {
    const res = await UsersAdminService.enableDisableAdUser(users, isUserEnable);
    return res.data;
  }
);

export const getSubDNObject = createAsyncThunk('getSubDNObject', async ({ DN }) => {
  const res = await UsersAdminService.getSubDNObject(DN);
  return res.data;
});

export const getDC = createAsyncThunk('getDC', async () => {
  const res = await UsersAdminService.getDC();
  return res.data;
});

export const getMandatoryGroup = createAsyncThunk('getMandatoryGroup', async () => {
  const res = await UsersAdminService.getMandatoryGroup();
  return res.data;
});

export const getGroupsAd = createAsyncThunk('getGroupsAd', async () => {
  const res = await UsersAdminService.findAdUserGroups();
  return res.data;
});

export const assignGroupsAdUser = createAsyncThunk(
  'assignGroupsAdUser',
  async ({ users, groups }) => {
    const res = await UsersSuperAdminService.assignGroupsAdUser(users, groups);
    return res.data;
  }
);

export const deleteGroupsAdUser = createAsyncThunk(
  'deleteGroupsAdUser',
  async ({ users, groups }) => {
    const res = await UsersSuperAdminService.deleteGroupsAdUser(users, groups);
    return res.data;
  }
);

export const resetUserPassword = createAsyncThunk('adUserResetPwd', async (payload) => {
  const res = await UsersSuperAdminService.resetUserPassword(payload);
  return res.data;
});

export const getDisableText = createAsyncThunk('getDisableText', async () => {
  const res = await UsersAdminService.getDisableText();
  return res.data;
});

export const createAdUsersBulk = createAsyncThunk('createAdUsersBulk', async (formData) => {
  const res = await UsersAdminService.createAdUsersBulk(formData);
  return res.data;
});

export const deleteAdUsersBulk = createAsyncThunk('deleteAdUsersBulk', async (formData) => {
  const res = await UsersAdminService.deleteAdUsersBulk(formData);
  return res.data;
});

export const editAdUsersBulk = createAsyncThunk('editAdUsersBulk', async (formData) => {
  const res = await UsersAdminService.editAdUsersBulk(formData);
  return res.data;
});

export const findAdUsers = createAsyncThunk('findAdUsers', () => {
  AdUsersService.findAdUsers();
});

export const refreshAdUsers = createAsyncThunk('refreshAdUsers', () => {
  AdUsersService.refreshAdUsers();
});

export const adUsersSlice = createSlice({
  name: 'adUsers',
  initialState,
  reducers: {
    addAdUsersToList: (state, action) => {
      state.adUsers = state.adUsers.concat(action.payload.users);
    },
    resetUsersList: (state) => {
      state.adUsers = [];
    },
    setAdUsersListUpdateAt: (state, action) => {
      const dateFormatted = getDateTimeFormatted(Number(action.payload));
      state.updateAt = dateFormatted;
      state.isLoading = false;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    resetSelectedUser: (state) => {
      state.selectedUser = {};
    },
    resetAdUserState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneAdUser.pending, (state) => {
        state.status = 'pending';
        state.isLoadingUser = true;
      })
      .addCase(getOneAdUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoadingUser = false;
        state.selectedUser = {
          ...action.payload.adUser,
          memberOf: action.payload.adUser?.memberOf?.length
            ? formatGroups(action.payload.adUser.memberOf)
            : []
        };
      })
      .addCase(getOneAdUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoadingUser = false;
      })
      .addCase(getSubDNObject.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getSubDNObject.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const result = action.payload.subDN.map((subDN, index) => {
          const objectClass = action.payload.objectClass[index];

          return {
            DN: subDN,
            label: getLabelAdByDN(subDN),
            subDN: [],
            objectClass
          };
        });
        const path = getPath(action.meta.arg.DN);

        if (action.meta.arg?.isForForm) {
          const newADForForm = changeAd(state.ADForForm, 0, path, result);
          state.ADForForm = newADForForm;
        } else {
          const newAD = changeAd(state.AD, 0, path, result);
          state.AD = newAD;
        }
      })
      .addCase(getSubDNObject.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      .addCase(getDC.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getDC.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const result = action.payload.subDN.map((subDNElem) => ({
          DN: subDNElem,
          label: getLabelAdByDN(subDNElem),
          subDN: []
        }));
        if (action.meta.arg?.isForForm) {
          state.ADForForm = result;
        } else {
          state.AD = result;
        }
      })
      .addCase(getDC.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(deleteAdUser.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(deleteAdUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const listIndex = [];
        const tempAdUsers = state.adUsers;
        tempAdUsers.map((item, index) => {
          if (action.meta.arg.includes(item.distinguishedName)) listIndex.push(index);
          return item;
        });
        listIndex?.reverse()?.map((index) => tempAdUsers.splice(index, 1));
        state.adUsers = tempAdUsers;
      })
      .addCase(deleteAdUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(updateAdUser.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updateAdUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedUser = action.meta.arg.modifiedUser;
        state.selectedUser.whenChanged = getCurrentFormattedDate();
      })
      .addCase(updateAdUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(updateAdUserGroups.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updateAdUserGroups.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedUser.memberOf = action.meta.arg?.groups?.length
          ? formatGroups(action.meta.arg.groups)
          : [];
        state.selectedUser.whenChanged = getCurrentFormattedDate();
      })
      .addCase(updateAdUserGroups.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(enableDisableAdUser.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(enableDisableAdUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        if (Object.keys(state.selectedUser).length !== 0) {
          if (state.selectedUser?.userAccountControl) {
            state.selectedUser.userAccountControl = action.meta.arg.isUserEnable
              ? JSON.stringify(JSON.parse(state.selectedUser?.userAccountControl) + 2)
              : JSON.stringify(JSON.parse(state.selectedUser?.userAccountControl) - 2);
          }
          state.selectedUser.isUserEnable = !action.meta.arg.isUserEnable;
          const newDn = enableDisabledOU(
            state.selectedUser.distinguishedName,
            state.userText,
            state.disableText,
            state.defaultEnableUserText
          );
          state.selectedUser.distinguishedName = newDn;
        }
        const listIndex = [];
        const tempAdUsers = state.adUsers;
        tempAdUsers.map((item, index) => {
          if (action.meta.arg.users.includes(item.distinguishedName)) {
            const newDn = enableDisabledOU(
              item.distinguishedName,
              state.userText,
              state.disableText,
              state.defaultEnableUserText
            );
            listIndex.push({ index, newDN: newDn });
          }
          return item;
        });
        listIndex?.reverse()?.map(({ index, newDN }) => {
          tempAdUsers[index].distinguishedName = newDN;
          tempAdUsers[index].userAccountControl = action.meta.arg.isUserEnable
            ? JSON.stringify(JSON.parse(tempAdUsers[index].userAccountControl) + 2)
            : JSON.stringify(JSON.parse(tempAdUsers[index].userAccountControl) - 2);
          tempAdUsers[index].isUserEnable = !action.meta.arg.isUserEnable;
          return null;
        });
        state.adUsers = tempAdUsers;
      })
      .addCase(enableDisableAdUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(resetUserPassword.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(resetUserPassword.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(resetUserPassword.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(getGroupsAd.pending, (state) => {
        state.status = 'pending';
        state.isLoadingGroups = true;
      })
      .addCase(getGroupsAd.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoadingGroups = false;
        state.groups = action.payload.adGroups.map((group) => {
          const name = group.substring(3, group.indexOf(','));
          return { name, dn: group };
        });
      })
      .addCase(getGroupsAd.rejected, (state) => {
        state.status = 'rejected';
        state.isLoadingGroups = false;
      });

    builder
      .addCase(assignGroupsAdUser.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(assignGroupsAdUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        let newGroups = action.meta.arg.groups;
        if (state.selectedUser.memberOf)
          newGroups = state.selectedUser.memberOf.concat(
            action.meta.arg.groups.filter((item) => !state.selectedUser.memberOf.includes(item))
          );

        const usersIndexWithNewGroups = getIndicesByDistinguishedName(
          state.adUsers,
          action.meta.arg.users
        );
        const newGroups2 = action.meta.arg.groups.map((group) => getCNfromDN(group));
        const tempAdUsers = state.adUsers;
        usersIndexWithNewGroups.map((index) => {
          tempAdUsers[index] = {
            ...tempAdUsers[index],
            memberOf: tempAdUsers[index].memberOf
              ? tempAdUsers[index].memberOf.concat(
                  newGroups2.filter((group) => !tempAdUsers[index].memberOf.includes(group))
                )
              : newGroups2
          };
          return null;
        });
        state.adUsers = tempAdUsers;
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedUser.memberOf = newGroups;
        state.selectedUser.whenChanged = getCurrentFormattedDate();
      })
      .addCase(assignGroupsAdUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(deleteGroupsAdUser.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(deleteGroupsAdUser.fulfilled, (state, action) => {
        let newGroups = [];
        if (state.selectedUser.memberOf)
          newGroups = state.selectedUser.memberOf.filter(
            (item) => !action.meta.arg.groups.includes(item)
          );

        const usersIndexWithNewGroups = getIndicesByDistinguishedName(
          state.adUsers,
          action.meta.arg.users
        );
        const newGroups2 = action.meta.arg.groups.map((group) => getCNfromDN(group));
        const tempAdUsers = state.adUsers;
        usersIndexWithNewGroups.map((index) => {
          tempAdUsers[index] = {
            ...tempAdUsers[index],
            memberOf: tempAdUsers[index].memberOf
              ? tempAdUsers[index].memberOf.filter((group) => !newGroups2.includes(group))
              : undefined
          };
          return null;
        });

        state.adUsers = tempAdUsers;
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedUser.memberOf = newGroups;
      })
      .addCase(deleteGroupsAdUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(getDisableText.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getDisableText.fulfilled, (state, action) => {
        state.userText = action.payload.userText;
        state.disableText = action.payload.disableText;
        state.defaultEnableUserText = action.payload.defaultEnableUserText;
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(getDisableText.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(refreshAdUsers.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.adUsers = [];
      })
      .addCase(refreshAdUsers.fulfilled, (state) => {
        state.status = 'fulfilled';
      })
      .addCase(refreshAdUsers.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(postOneAdUser.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(postOneAdUser.fulfilled, (state, action) => {
        const adUserList = state.adUsers;
        adUserList.push(action.payload.adUserFormatted);
        state.adUsers = adUserList;
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(postOneAdUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(getMandatoryGroup.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getMandatoryGroup.fulfilled, (state, action) => {
        state.mandatoryGroup = action.payload.mandatoryGroup;
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(getMandatoryGroup.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(createAdUsersBulk.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(createAdUsersBulk.fulfilled, (state, action) => {
        const tempAdUsers = [...state.adUsers];
        const newAdusers = tempAdUsers.concat(action.payload.createdAdUsers);
        state.adUsers = newAdusers;
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(createAdUsersBulk.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(deleteAdUsersBulk.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(deleteAdUsersBulk.fulfilled, (state, action) => {
        const tempAdUsers = [...state.adUsers];
        const newAdusers = tempAdUsers.filter(
          ({ distinguishedName }) => !action.payload.deletedADUsers.includes(distinguishedName)
        );
        state.adUsers = newAdusers;
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(deleteAdUsersBulk.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(editAdUsersBulk.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(editAdUsersBulk.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(editAdUsersBulk.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});

export const {
  addAdUsersToList,
  resetSelectedUser,
  resetUsersList,
  setIsLoading,
  setAdUsersListUpdateAt,
  resetAdUserState
} = adUsersSlice.actions;

export const selectAdUsers = (searchTerm) => (state) => {
  if (state.adUsers.adUsers.length > 0) {
    const escapedString = regexpEscaping(searchTerm);
    const regexp = new RegExp(`${escapedString}`, 'gi');
    return state.adUsers.adUsers.filter(
      (user) =>
        (user.name && user.name.match(regexp)) ||
        (user.distinguishedName && user.distinguishedName.match(regexp))
    );
  }
  return state.adUsers.adUsers;
};

export const selectDefaultEnableUserText = (state) => state.adUsers.defaultEnableUserText;
export const selectUserText = (state) => state.adUsers.userText;
export const selectDisableText = (state) => state.adUsers.disableText;
export const selectCurrentOU = (state) => state.adUsers.selectedUser?.distinguishedName;
export const selectUpdateAt = (state) => state.adUsers.updateAt;
export const selectIsLoading = (state) => state.adUsers.isLoading;
export const selectIsTableDisplayable = (state) => {
  if (state.adUsers.adUsers.length <= 0 || state.columns?.columns?.length <= 0) {
    return false;
  }
  return true;
};
export const selectedUser = (state) => state.adUsers.selectedUser;
export const selectUpn = (state) => state.adUsers.selectedUser.userPrincipalName;
export const selectIsAdUserLoading = (state) => state.adUsers.isLoadingUser;
export const selectAD = (state) => state.adUsers.AD;
export const selectADForForm = (state) => state.adUsers.ADForForm;
export const selectedGroups = (searchTerm) => (state) => {
  if (state.adUsers.groups.length > 0) {
    const escapedString = regexpEscaping(searchTerm);
    const regexp = new RegExp(`${escapedString}`, 'gi');
    return state.adUsers.groups.filter((group) => group.name && group.name.match(regexp));
  }
  return state.adUsers.groups;
};
export const selectIsGroupsLoading = (state) => state.adUsers.isLoadingGroups;
export const mandatoryGroup = (state) => state.adUsers.mandatoryGroup;
export const selectedAdUsersStatus = (arrayUsersDn) => (state) => {
  if (state.adUsers.adUsers.length > 0)
    return state.adUsers.adUsers.filter((user) => arrayUsersDn.includes(user.distinguishedName));

  return state.adUsers.adUsers;
};
export default adUsersSlice.reducer;
