import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ItemsList from '../ItemsList';
import { formatColumnsForTable } from '../../../components/datalist/ComponentsInTable';
import {
  dayforceUsersUpdatedAt,
  findDayforceUsers,
  refreshDayforceUsers,
  resetSelectedDayforce,
  selectDayforceUsers,
  selectIsTableDisplayable
} from '../../../redux/slices/DayforceUsers';
import { resetColumn } from '../../../redux/slices/columns';
import { selectIsLoading } from '../../../redux/slices/AzureUsersList';

export default function dayforceUsers() {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const dayforceRessources = useSelector(selectDayforceUsers).map((el) => ({
    ...el,
    Roles: el.Roles.map((role) => role.Role)
  }));
  const dispatch = useDispatch();
  const isTableDisplayable = useSelector(selectIsTableDisplayable);
  const areDayforceUsersLoading = useSelector(selectIsLoading);
  const updateTime = useSelector(dayforceUsersUpdatedAt);

  useEffect(() => {
    dispatch(findDayforceUsers());
    return function cleanup() {
      dispatch(resetSelectedDayforce());
      dispatch(resetColumn());
    };
  }, [dispatch]);

  const dayforceColumns = [
    'LoginId',
    'FirstName',
    'LastName',
    'EmployeeNumber',
    'Roles',
    'EmployeeManager',
    'GenderIdentity',
    'StartDate',
    'HireDate'
  ];

  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];

    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map(
      (rowIndex) => dayforceRessources[rowIndex === null ? 0 : rowIndex]?.id
    );
    setTableSelectedRows(tempRows);
  };

  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
    setPage(0);
  };

  const resetSelectedRows = () => {
    setTableSelectedRows([]);
    setSelectedIndexRows([]);
  };

  const handleRefresh = async () => {
    setPage(0);
    resetSelectedRows();
    dispatch(refreshDayforceUsers());
  };

  const searchBar = {
    isDisplayable: true,
    disabled: false
  };

  const formatEmployeeManager = (EmployeeManager) => EmployeeManager?.ManagerDisplayName;

  const columnOptions = [
    {
      name: 'LoginId',
      type: 'LinkInTable',
      linkOptions: { linkColumn: 'LoginId', linkTo: 'id' },
      display: true,
      filter: true
    },
    { name: 'FirstName', display: true, filter: true },
    { name: 'LastName', display: true, filter: true },
    { name: 'EmployeeNumber', display: true, filter: true },
    {
      name: 'Roles',
      type: 'ChipList',
      display: true
    },
    {
      name: 'EmployeeManager',
      type: 'customFormat',
      format: formatEmployeeManager,
      display: true
    },
    { name: 'GenderIdentity', display: true, filter: true },
    { name: 'StartDate', display: true, filter: true },
    { name: 'HireDate', display: true, filter: true }
  ];

  const optionsKeys = {
    titleNameKey: 'LoginId',
    secondaryTitleKey: 'FirstName',
    statusKey: 'Roles'
  };

  const formatedColumns = formatColumnsForTable(
    dayforceColumns,
    dayforceRessources,
    columnOptions,
    t
  );

  const desktopConfig = {
    actions: {
      handleRefresh,
      handleSearchTerm
    },

    searchBar,

    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: dayforceRessources
    },

    loading: {
      itemsListIsLoading: areDayforceUsersLoading,
      isTableDisplayable
    },

    tables: {
      optionsKeys,
      formatedColumns,
      date: updateTime,
      totalItems,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      selectedIndexRows,
      setTotalItems
    },

    search: {
      searchTerm,
      setSearchTerm
    },

    text: {
      titleText: t('dayforceUsers'),
      bigTitleText: t('manageUsers'),
      selectedItemsText: 'selectedUsers',
      tagId: 'DayforceUsers'
    },
    searchBarPlaceOlder: t('searchUser'),
    isDisableCheckBox: true
  };

  return (
    <Grid>
      <ItemsList config={desktopConfig} />
    </Grid>
  );
}
