import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';

function SelectComponent({ name, value, error, onChange, options }) {
  return (
    <Grid container my={1}>
      <FormControl fullWidth>
        <InputLabel htmlFor={name}>{name}</InputLabel>
        <Select
          error={error}
          label={name}
          data-testid={`${name}Select`}
          name={name}
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
        >
          {options.map((option) => (
            <MenuItem data-testid={`${option}-item`} key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

SelectComponent.propTypes = {
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired
};

SelectComponent.defaultProps = {
  error: false
};

export default SelectComponent;
