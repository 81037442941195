const colorMain = '#71234A';
const colorMainDark = '#4E1230';
const palette = {
  colorMain,
  colorMainDark,
  colorMainGradient: `linear-gradient(180deg, ${colorMainDark} 0%, ${colorMain} 100%);`,
  colorMainHover: '#77465D',
  colorMainSelected: '#934A6D',

  colorTableCheckboxDark: '#71234A',
  colorText: '#ededed',

  colorTextMain: '#fff',
  colorMainLightHover: '#E6D7E6',
  colorMainLight: '#fff',
  colorApps: '#f4f5f7',
  colorSecondaryLight: '#ededed',
  colorGray: '#ddd',
  colorBlack: '#000',
  colorSoftBlack: '#444444',
  colorSoftProfile: '#444444',
  colorNumberPage: '#000',

  successMain: '#42824C',
  successSecondary: '#A2DBAC',

  errorMain: '#B13434',
  errorSecondary: '#CC8181',

  warnMain: '#c47f00',
  warnSecondary: '#ffd589',

  neutralMain: '#27407A',
  neutralSecondary: '#CDF0F9'
};

export default palette;
