import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UsersAdminService from '../../services/admin/UsersAdminService';
import JiraUsersService from '../../services/users/JiraUsersService';
import UsersSuperAdminService from '../../services/superAdmin/UsersSuperAdminService';

import { getDateTimeFormatted } from '../../utils/Date/DateFunction';
import { regexpEscaping } from '../../utils/regex';

export const initialState = {
  jiraUserList: [],
  isLoading: false,
  updatedAt: '',
  status: 'fulfilled',
  selectedUser: {},
  jiraUserGroups: [],
  isJiraGroupLoading: false
};

export const getOneJiraUser = createAsyncThunk('jiraUser', async (payload) => {
  const res = await UsersAdminService.getOneJiraUser(payload);
  return res.data;
});

export const enableDisableJiraUser = createAsyncThunk('enableDisableJiraUser', async (payload) => {
  const res = await UsersAdminService.enableDisableJiraUser(payload);
  return res.data;
});

export const findJiraUsers = createAsyncThunk('findJiraUsers', () => {
  JiraUsersService.findJiraUsers();
});

export const refreshJiraUsers = createAsyncThunk('refreshJiraUsers', () => {
  JiraUsersService.refreshJiraUsers();
});
export const getJiraUserGroups = createAsyncThunk('findJiraUserGroups', async (payload) => {
  const res = await UsersAdminService.findJiraUserGroups(payload);
  return res.data;
});

export const setAssignJiraGroup = createAsyncThunk('setAssignJiraGroup', async (body) => {
  const res = await UsersSuperAdminService.assignJiraGroups(body);
  return res.data;
});

export const removeJiraGroup = createAsyncThunk('removeJiraGroup', async (body) => {
  const res = await UsersSuperAdminService.removeJiraGroups(body);
  return res.data;
});

export const jiraUsersSlice = createSlice({
  name: 'jiraUsers',
  initialState,
  reducers: {
    addJiraUsersToList: (state, action) => {
      state.jiraUserList = state.jiraUserList.concat(action.payload.users);
    },
    resetJiraUserList: (state) => {
      state.jiraUserList = [];
    },
    setJiraUserListUpdatedTime: (state, action) => {
      const dateFormatted = getDateTimeFormatted(Number(action.payload));
      state.updatedAt = dateFormatted;
      state.isLoading = false;
    },
    setAreJiraUsersLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    resetJiraUsersState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneJiraUser.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getOneJiraUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedUser = action.payload.jiraUser;
      })
      .addCase(getOneJiraUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(enableDisableJiraUser.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(enableDisableJiraUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedUser = {
          ...state.selectedUser,
          active: !state.selectedUser.active
        };
        const index = state.jiraUserList.findIndex((user) => user.id === action.meta.arg[0]);
        state.jiraUserList[index] = {
          ...state.jiraUserList[index],
          active: state.selectedUser.active
        };
      })
      .addCase(enableDisableJiraUser.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(findJiraUsers.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.jiraUserList = [];
      })
      .addCase(findJiraUsers.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLoading = true;
      })
      .addCase(findJiraUsers.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(refreshJiraUsers.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.jiraUserList = [];
      })
      .addCase(refreshJiraUsers.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLoading = true;
      })
      .addCase(refreshJiraUsers.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(getJiraUserGroups.pending, (state) => {
        state.status = 'pending';
        state.isJiraGroupLoading = true;
      })
      .addCase(getJiraUserGroups.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isJiraGroupLoading = false;
        state.jiraUserGroups = action.payload.jiraGroups;
      })
      .addCase(getJiraUserGroups.rejected, (state) => {
        state.status = 'rejected';
        state.isJiraGroupLoading = false;
      });
    builder
      .addCase(setAssignJiraGroup.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isJiraGroupLoading = false;
        state.selectedUser = {
          ...state.selectedUser,
          groups: [...(state.selectedUser.groups ?? []), ...action.meta.arg.groups]
        };
      })
      .addCase(removeJiraGroup.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isJiraGroupLoading = false;
        state.selectedUser = {
          ...state.selectedUser,
          groups: (state.selectedUser.groups ?? []).filter(
            (group) => !action.meta.arg.groups.includes(group)
          )
        };
      });
  }
});

export const {
  addJiraUsersToList,
  resetJiraUserList,
  setJiraUserListUpdatedTime,
  setAreJiraUsersLoading,
  resetJiraUsersState
} = jiraUsersSlice.actions;

export const jiraUserSelection = (selectedUsersKeys) => (state) => {
  if (state.jiraUsers.jiraUserList.length > 0) {
    return state.jiraUsers.jiraUserList.filter((user) => selectedUsersKeys.includes(user.key));
  }
  return state.jiraUsers.jiraUserList;
};

export const selectSearchedJiraUser = (searchTerm) => (state) => {
  if (state.jiraUsers.jiraUserList.length > 0 && searchTerm.length > 2) {
    const escapedString = regexpEscaping(searchTerm);
    const regexp = new RegExp(`${escapedString}`, 'gi');
    return state.jiraUsers.jiraUserList
      .filter(
        (user) =>
          (user.displayName && user.displayName.match(regexp)) ||
          (user.emailAddress && user.emailAddress.match(regexp)) ||
          (user.key && user.key.match(regexp)) ||
          (user.name && user.name.match(regexp))
      )
      .map((user) => {
        const formatedUser = { ...user };
        delete formatedUser.deleted;
        return formatedUser;
      });
  }
  return state.jiraUsers.jiraUserList.map((user) => {
    const formatedUser = { ...user };
    delete formatedUser.deleted;
    return formatedUser;
  });
};

export const selectJiraGroups = (searchTerm) => (state) => {
  if (state.jiraUsers.jiraUserGroups.length > 0) {
    const escapedString = regexpEscaping(searchTerm);
    const regexp = new RegExp(`${escapedString}`, 'gi');
    return state.jiraUsers.jiraUserGroups.filter(
      (license) => license.name && license.name.match(regexp)
    );
  }
  return state.jiraUsers.jiraUserGroups;
};
export const selectGroupsIsLoading = (state) => state.jiraUsers.isJiraGroupLoading;
export const jiraUserList = (state) => state.jiraUsers.jiraUserList;
export const selectedUser = (state) => state.jiraUsers.selectedUser;
export const isLoading = (state) => state.jiraUsers.isLoading;
export const JiraUsersUpdatedAt = (state) => state.jiraUsers.updatedAt;

export const selectIsTableDisplayable = (state) => {
  if (state.jiraUsers.jiraUserList.length <= 0 || state.columns?.columns?.length <= 0) {
    return false;
  }
  return true;
};
export const selectIsJiraUserLoaded = (state) => {
  if (!state.jiraUsers.isLoading && state.jiraUsers.selectedUser?.displayName) {
    return true;
  }
  return false;
};
export default jiraUsersSlice.reducer;
