import axios from 'axios';
import API_URL from '../../constants/constants';

const ProfileService = {
  getUserPhoto() {
    return axios.get(`${API_URL}/user/photo`);
  },
  getUserProfile() {
    return axios.get(`${API_URL}/users/profile`);
  },
  getUserPermissions() {
    return axios.get(`${API_URL}/users/permissions`);
  }
};

export default ProfileService;
