import axios from 'axios';
import API_URL from '../../constants/constants';

const PermissionsHistory = {
  getPermissionsHistory() {
    return axios.get(`${API_URL}/history/permissions`);
  },
  removePermissionsHistory(ids) {
    return axios.delete(`${API_URL}/history/permissions/remove`, {
      data: { ids }
    });
  },
  addPermissionsHistory(ids) {
    return axios.post(`${API_URL}/history/permissions/add`, { ids });
  }
};

export default PermissionsHistory;
