import axios from 'axios';
import socket from '../../socket/socket';
import API_URL from '../../constants/constants';

const UsersDayforce = {
  getDayforceById(id) {
    return axios.get(`${API_URL}/users/dayforce/${id}`);
  },
  findDayforceUsers() {
    return socket.emit('dayforce-users:find', 'true');
  },
  refreshDayforceUsers() {
    return socket.emit('dayforce-users:refresh', 'true');
  },
  editDayforceName(id, user) {
    return axios.patch(`${API_URL}/users/dayforce/${id}`, user);
  }
};

export default UsersDayforce;
