import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomizedButton from '../../../../components/buttons/CustomizedButton';
import { InputTypes } from '../../../../constants/constants';
import SwitchComponent from './PopupComponent/SwitchComponent';
import InputComponent from './PopupComponent/InputComponent';
import SelectComponent from './PopupComponent/SelectComponent';
import {
  allowedAzureColumns,
  allowedJiraColumns,
  allowedADColumns
} from '../../../../constants/columns';
import AutocompleteComponent from './PopupComponent/AutoCompleteComponent';

export default function PopUpAddColumns({
  open,
  handleClose,
  columns,
  selectedProvider,
  addChange,
  usedCol
}) {
  const { t } = useTranslation();
  const initialFormData = {
    isDisplayed: false,
    isInDetails: false,
    isEditable: false,
    isInCreation: false,
    isInEdit: false,
    isNullable: false,
    columnName: '',
    columnId: '',
    input: '',
    providerId: null
  };
  const [formData, setFormData] = useState(initialFormData);
  const [inputErrors, setInputErrors] = useState({
    columnName: false,
    columnId: false,
    input: false
  });
  const providerColumnOptions = {
    ACTIVE_DIRECTORY: allowedADColumns
      .map((column) => column.columnId)
      .filter((columnId) => !usedCol.includes(columnId)),
    AZURE_ACTIVE_DIRECTORY: allowedAzureColumns
      .map((column) => column.columnId)
      .filter((columnId) => !usedCol.includes(columnId)),
    BOONDMANAGER: [],
    INTUNE: [],
    JIRA: allowedJiraColumns
      .map((column) => column.columnId)
      .filter((columnId) => !usedCol.includes(columnId)),
    RCS_GOOGLE: [],
    AZURE_FRONT: []
  };

  const columnIdOptions = providerColumnOptions[selectedProvider?.name] || [];

  const closePopup = () => {
    handleClose();
  };

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value, providerId: selectedProvider.id });
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.columnName) {
      errors.columnName = true;
      isValid = false;
    }
    if (!formData.columnId) {
      errors.columnId = true;
      isValid = false;
    }
    if (!formData.input) {
      errors.input = true;
      isValid = false;
    }

    setInputErrors(errors);
    return isValid;
  };
  const dispatchAction = async () => {
    if (validateForm()) {
      addChange(formData);
      setFormData(initialFormData);
      handleClose();
    }
  };

  const excludeColumns = ['providerId', 'columnId'];
  const filteredColumns = columns.filter((column) => !excludeColumns.includes(column));

  const switches = filteredColumns
    .filter((key) => typeof formData[key] === 'boolean')
    .map((key) => ({
      name: key,
      checked: formData[key]
    }));

  const textFields = filteredColumns
    .filter((key) => typeof formData[key] !== 'boolean' && key !== 'input')
    .map((key) => ({
      name: key,
      value: formData[key]
    }));
  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>{`${t('Columns')} :`}</DialogTitle>
      <form>
        <DialogContent>
          <Grid container>
            <AutocompleteComponent
              name="columnId"
              error={inputErrors.columnId}
              onChange={handleInputChange}
              options={columnIdOptions}
            />
            <SelectComponent
              name="input"
              value={formData.input}
              error={inputErrors.columnId}
              onChange={handleInputChange}
              options={Object.values(InputTypes).map((enumItem) => enumItem)}
            />
            <InputComponent
              inputErrors={inputErrors}
              textFields={textFields}
              onChange={handleInputChange}
            />
            <SwitchComponent switches={switches} onChange={handleInputChange} isEdit={false} />
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <CustomizedButton
          variant="contained"
          datatestid="cancelAddColumn"
          onClick={() => closePopup()}
          text={t('cancel')}
        />
        <CustomizedButton
          variant="contained"
          datatestid="confirmAddColumn"
          type="submit"
          onClick={() => dispatchAction()}
          text={t('save')}
        />
      </DialogActions>
    </Dialog>
  );
}

PopUpAddColumns.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string),
  selectedProvider: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }),
  addChange: PropTypes.func.isRequired,
  usedCol: PropTypes.arrayOf(PropTypes.string)
};

PopUpAddColumns.defaultProps = {
  columns: [],
  selectedProvider: null,
  usedCol: []
};
