import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { resetUserPassword, selectedUser } from '../../redux/slices/AdUsers';
import CustomizedButton from '../buttons/CustomizedButton';
import { styleDialogActions } from '../../assets/style/views/AzurADUserPopup';
import { complexityPassword, randomNumberString } from '../../utils/adUsers';
import { styleTitleHeadPageDescript } from '../../assets/style/views/User';
import PasswordField from '../form/PasswordField';
import { styleScrollbar } from '../../assets/style/utils/utils';

export default function PopupResetPassword({ popup, closePopup }) {
  const { t } = useTranslation();
  const defaultPassword = `${process.env.REACT_APP_DEFAULT_AD_PWD}${randomNumberString(4)}`;
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState(defaultPassword);
  const [newPasswordAtNextLog, setNewPasswordAtNextLog] = useState(true);
  const adUser = useSelector(selectedUser);
  const dispatchResetPassword = async () => {
    const body = {
      newPassword,
      newPasswordAtNextLog
    };

    await dispatch(resetUserPassword({ DN: adUser.distinguishedName, body }));

    closePopup();
  };

  const handleClickResetPassword = async () => {
    dispatchResetPassword();
  };

  useEffect(() => {
    setNewPassword(defaultPassword);
    setNewPasswordAtNextLog(true);
  }, [popup]);

  return (
    <Dialog
      open={popup}
      onClose={closePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="CreateAzureUserPopup"
      PaperProps={{ sx: styleScrollbar }}
    >
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
          <Grid>
            <Typography sx={styleTitleHeadPageDescript}>{t('password')}</Typography>
          </Grid>
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={t('validateAdPassword')} placement="right">
              <InfoOutlinedIcon color="disabled" />
            </Tooltip>
          </Grid>
        </Box>
        <PasswordField
          defaultPassword={defaultPassword}
          setNewPassword={setNewPassword}
          newPassword={newPassword}
          newPasswordAtNextLog={newPasswordAtNextLog}
          setNewPasswordAtNextLog={setNewPasswordAtNextLog}
        />
      </DialogContent>
      <DialogActions sx={styleDialogActions}>
        <CustomizedButton onClick={() => closePopup()} text={t('cancel')} datatestid="closePopup" />
        <CustomizedButton
          onClick={() => handleClickResetPassword()}
          text={t('save')}
          datatestid="reset-password-button"
          disabled={complexityPassword(newPassword) < 70}
        />
      </DialogActions>
    </Dialog>
  );
}

PopupResetPassword.propTypes = {
  popup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired
};
