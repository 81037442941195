import { React, useState } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AccountBox from '@mui/icons-material/AccountBox';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { Grid, Box, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getProfile, getPermissions } from '../../../redux/slices/userConnected';
import CustomizedButton from '../../../components/buttons/CustomizedButton';
import '../../../assets/style/views/Login.css';

import {
  styleGridItemMargin,
  styleLoginButton,
  styleAccordionSummary
} from '../../../assets/style/views/LoginPage';

export default function LoginUserPassword({ loginType, loginName, loginAction, isBackOffice }) {
  const { t } = useTranslation();
  const loginFormData = [
    {
      id: 'login',
      type: 'login',
      label: 'Login',
      name: 'login',
      autoComplete: 'login',
      icon: <AccountBox className="loginIcon" position="start" />
    },
    {
      id: 'password',
      type: 'password',
      label: 'password',
      name: 'password',
      autoComplete: 'password',
      icon: <LockOutlinedIcon className="loginIcon" position="start" />
    }
  ];
  const dispatch = useDispatch();
  const [profile, setProfile] = useState({
    login: '',
    password: ''
  });

  const handleChange = (e) => {
    const { value } = e.target;
    setProfile({
      ...profile,
      [e.target.name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(loginAction({ ...profile, isBackOffice }));
      if (response.payload.token) {
        if (loginType === 'ACTIVE_DIRECTORY') {
          localStorage.setItem('dataSession', response.payload.dataSession);
        }
        if (isBackOffice) {
          localStorage.setItem('isBackOffice', true);
        }
        localStorage.setItem('token', response.payload.token);
        localStorage.setItem('isOpenSideBar', true);
        localStorage.setItem('isBlack', false);
        dispatch(getProfile());
        dispatch(getPermissions());
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid component="form" onSubmit={handleSubmit} noValidate style={styleAccordionSummary}>
      <Typography
        sx={{ width: '100%', marginTop: '20px', marginBottom: '0px', fontWeight: 'bold' }}
      >
        {loginName}
      </Typography>
      {loginFormData.map(function createField(text) {
        return (
          <Box key={text.id}>
            <Grid item xs={10} sx={styleGridItemMargin}>
              <TextField
                required
                type={text.type}
                data-testid={text.id}
                id={text.id}
                label={t(text.label)}
                name={text.name}
                autoComplete={text.autoComplete}
                fullWidth
                onChange={handleChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{text.icon}</InputAdornment>
                }}
                autoFocus
              />
            </Grid>
          </Box>
        );
      })}
      <CustomizedButton
        styleButton={styleLoginButton}
        text={t('toLogIn')}
        datatestid="LogInButton"
        type="submit"
      />
    </Grid>
  );
}

LoginUserPassword.propTypes = {
  loginType: PropTypes.string,
  loginName: PropTypes.string.isRequired,
  loginAction: PropTypes.func.isRequired,
  isBackOffice: PropTypes.bool
};

LoginUserPassword.defaultProps = {
  loginType: 'root',
  isBackOffice: false
};
