import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import BackOfficeDesktop from '../../../../components/BackOffice/BackOfficeDesktop';
import { getAllProviders, selectProviders } from '../../../../redux/slices/Providers';
import errorRedirection from '../../../../utils/errorRedirection';
import { formatColumnsForTable } from '../../../../components/datalist/ComponentsInTable';
import {
  getProviderColumns,
  patchProviderColumns,
  resetColumn,
  selectColumns
} from '../../../../redux/slices/columns';
import PopUpAddColumns from './PopUpAddColumns';
import PopUpEditColumns from './PopUpEditColumns';

const formatProviders = (providers, setProviderSelected) => {
  if (providers?.length) {
    return providers.map(({ id, providerName: { name } }) => {
      return {
        id,
        name,
        onClick: async (dispatch) => {
          await dispatch(resetColumn());
          await dispatch(getProviderColumns({ providerName: name, isBackOffice: true }));
          setProviderSelected(id);
        }
      };
    });
  }
  return [];
};

export default function ProvidersColumns() {
  const { t } = useTranslation();
  const [providerSelected, setProviderSelected] = useState(null);
  const providers = formatProviders(useSelector(selectProviders), setProviderSelected);
  const providerColumns = useSelector(selectColumns);
  const [displayedColumns, setDisplayedColumns] = useState([]);
  const [popupAddColumns, setPopupAddColumns] = useState(false);
  const [popupEditColumns, setPopupEditColumns] = useState(false);
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);
  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modification, setModification] = useState({
    addOrChangeItems: [],
    deleteItems: []
  });
  const isItemModified =
    modification.addOrChangeItems.length !== 0 || modification.deleteItems.length !== 0;

  const resetSelectedItems = () => {
    setSelectedIndexRows([]);
    setTableSelectedRows([]);
  };
  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];

    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map((rowIndex) => {
      return displayedColumns[rowIndex === null ? 0 : rowIndex];
    });
    setTableSelectedRows(tempRows);
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleAddItems = (formData) => {
    const newColumns = [...displayedColumns, formData];
    setModification({
      ...modification,
      addOrChangeItems: [...modification.addOrChangeItems, formData]
    });

    setDisplayedColumns(newColumns);
  };

  const handleAddUpdateItems = (formData) => {
    const updateColumn = formData.map((column) => column.columnId);
    const filteredAddItems = modification.addOrChangeItems.filter(
      (column) => !updateColumn.includes(column.columnId)
    );

    const filteredFormData = formData.filter((column) => {
      return !modification.addOrChangeItems.some((item) => item.columnId === column.columnId);
    });

    setModification((prevModification) => ({
      ...prevModification,
      addOrChangeItems: [
        ...prevModification.addOrChangeItems,
        ...filteredFormData,
        filteredAddItems
      ]
    }));

    setModification((updatedModification) => {
      const newColumns = displayedColumns.map((column) => {
        const modified = updatedModification.addOrChangeItems.find((item) => item.id === column.id);
        if (modified) {
          return {
            ...column,
            ...modified
          };
        }
        return column;
      });

      setDisplayedColumns(newColumns);
      return updatedModification;
    });
  };

  const handleAddDeleteItems = () => {
    const deletedColumns = tableSelectedRows.map((column) =>
      displayedColumns.find((provider) => column.id === provider.id)
    );
    const deletedIds = deletedColumns.map((column) => column.id);
    const deletedColumnIds = deletedColumns.map((column) => column.columnId);
    const newColumns = displayedColumns.filter((column) => !deletedIds.includes(column.id));

    const filteredAddItems = modification.addOrChangeItems.filter(
      (column) => !deletedColumnIds.includes(column.columnId)
    );

    setModification((prevModification) => ({
      ...prevModification,
      deleteItems: [...prevModification.deleteItems, ...deletedColumns],
      addOrChangeItems: filteredAddItems
    }));

    resetSelectedItems();
    setDisplayedColumns(newColumns);
  };

  const handleClickCancelButton = () => {
    setModification({
      addOrChangeItems: [],
      deleteItems: []
    });
    resetSelectedItems();
    setDisplayedColumns(providerColumns);
  };

  const handleClickUpdateButton = async () => {
    const selectedProviders = providers.find((provider) => provider.id === providerSelected);
    resetSelectedItems();
    await dispatch(
      patchProviderColumns({ providerName: selectedProviders.name, columns: modification })
    );
    setModification({
      addOrChangeItems: [],
      deleteItems: []
    });
  };

  const theme = useTheme();

  useEffect(() => {
    resetSelectedItems();
    setModification({
      addOrChangeItems: [],
      deleteItems: []
    });
    setDisplayedColumns(providerColumns);
  }, [providerSelected, providerColumns]);

  const dispatchGetProviders = async () => {
    try {
      await dispatch(getAllProviders()).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  useEffect(() => {
    dispatchGetProviders();
  }, [dispatch]);

  const chipsTypes = [
    {
      name: 'OK',
      value: true,
      backgroundColor: theme.palette.successSecondary,
      fontColor: theme.palette.successMain
    },
    {
      name: 'KO',
      value: false,
      backgroundColor: theme.palette.errorSecondary,
      fontColor: theme.palette.errorMain
    }
  ];

  const columnOptions = [
    { name: 'columnId', display: true },
    { name: 'columnName', display: true, filter: true },
    {
      name: 'isDisplayed',
      display: true,
      type: 'boolColorClassification',
      chipsTypes
    },
    {
      name: 'isEditable',
      display: true,
      type: 'boolColorClassification',
      chipsTypes
    },
    {
      name: 'isInCreation',
      display: true,
      type: 'boolColorClassification',
      chipsTypes
    },
    {
      name: 'isInEdit',
      display: true,
      type: 'boolColorClassification',
      chipsTypes
    },
    {
      name: 'isNullable',
      display: true,
      type: 'boolColorClassification',
      chipsTypes
    },
    { name: 'input', display: true },
    {
      name: 'isInDetails',
      display: true,
      type: 'boolColorClassification',
      chipsTypes
    }
  ];

  const columnNames = [
    'columnId',
    'columnName',
    'input',
    'isDisplayed',
    'isEditable',
    'isInCreation',
    'isInEdit',
    'isNullable',
    'isInDetails'
  ];

  const formatedColumns = formatColumnsForTable(columnNames, displayedColumns, columnOptions, t);

  const buttons = [
    {
      disabled: false,
      datatestid: 'addColumn',
      isDisplayable: true,
      onClick: () => setPopupAddColumns(true),
      text: t('addColumns')
    },
    {
      disabled:
        tableSelectedRows.length === 0 || tableSelectedRows.filter((el) => !el.id).length > 0,
      datatestid: 'editColumns',
      isDisplayable: true,
      onClick: () => setPopupEditColumns(true),
      text: t('editColumns')
    },
    {
      disabled:
        tableSelectedRows.length === 0 || tableSelectedRows.filter((el) => !el.id).length > 0,
      datatestid: 'removeColumns',
      isDisplayable: true,
      onClick: () => handleAddDeleteItems(),
      text: t('removeColumns')
    }
  ];

  const desktopConfig = {
    actions: {},
    buttons,
    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: displayedColumns
    },
    loading: {
      isTableDisplayable: true
    },
    tables: {
      formatedColumns,
      totalItems,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      selectedIndexRows,
      setTotalItems
    },
    search: {},
    text: {
      titleText: t('ProvidersColumns'),
      bigTitleText: t('managePermissions'),
      tagId: 'ProvidersColumns'
    },
    isDisableCheckBox: false,
    hideRefresh: true,
    isPositionFixed: true
  };

  const usedCol = displayedColumns.map((column) => column.columnId);

  return (
    <Grid>
      <PopUpAddColumns
        open={popupAddColumns}
        handleClose={() => setPopupAddColumns(false)}
        columns={columnNames}
        selectedProvider={providers.find((provider) => provider.id === providerSelected)}
        addChange={handleAddItems}
        usedCol={usedCol}
      />
      <PopUpEditColumns
        handleClose={() => setPopupEditColumns(false)}
        open={popupEditColumns}
        selectedCol={tableSelectedRows}
        addChange={handleAddUpdateItems}
      />
      <BackOfficeDesktop
        listItems={providers}
        isItemDisplayableDataTable={!!providerSelected}
        isItemDisplayable={!!providerSelected}
        selectedItemId={providerSelected}
        desktopConfig={desktopConfig}
        titleBodyName={t('manageProvidersColumns')}
        titleHeader={t('ProvidersColumns')}
        hideAddButton
        isItemModified={!isItemModified}
        handleClickCancelButton={handleClickCancelButton}
        handleClickUpdateButton={handleClickUpdateButton}
      />
    </Grid>
  );
}
