import { keyframes } from '@emotion/react';

export const styleBackground = {
  backgroundRepeat: 'no-repeat',
  width: { xs: '100vw', sm: '100vw', md: '100vw' },
  height: { xs: '40vh', md: '92vh' },
  marginTop: '-6%',
  objectPosition: '100%'
};

const fadeInTitle = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } });
export const styleTitle = (theme) => ({
  fontFamily: 'Adobe Clean',
  fontSize: { xs: '15px', sm: '20px', md: '40px' },
  position: 'absolute',
  top: { xs: '9%', sm: '13%', md: '28%' },
  left: { xs: '11%', sm: '6.5%', md: '8%' },
  color: theme.palette.colorMainLight,
  textAlign: 'start',
  animation: `${fadeInTitle} 2.5s ease-in-out`,
  textTransform: 'uppercase'
});

export const styleLogo = { flexGrow: 1 };

export const styleLogoPegasusHeaderButton = {
  display: 'flex',
  justifyContent: 'start',
  padding: '0px',
  height: '50px',
  width: '200px'
};

export const styleLogoPegasusHeader = {
  width: { xs: '170px', sm: '200px', md: '220px' },
  background: 'white',
  borderRadius: '5px'
};

export const styleLoginBoardButton = (theme) => ({
  display: 'flex',
  borderRadius: '4px',
  width: { xs: '150px', sm: '180px', md: '180px' },
  height: { xs: '30px', sm: '35px', md: '45px' },
  background: theme.palette.colorMainLight,
  float: 'right',
  transition: 'all 0.5s',
  '&:hover': {
    background: theme.palette.colorMainLight,
    transform: 'scale(0.9,0.9)'
  }
});

export const styleLoginBoardText = (theme) => ({
  fontSize: '20px',
  font: 'bold',
  height: '30px',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'initial',
  borderRadius: '5px',
  textDecoration: 'none',
  width: '300px',
  margin: '10px',
  color: theme.palette.colorBlack
});

export const styleTitleHeadPage = (theme) => ({
  font: 'Nunito',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  LineHeight: '22px',
  color: theme.palette.colorBlack,
  flex: 'none',
  order: '0',
  flexGrow: '0'
});

export const styleTitleHeadPageDescript = (theme) => ({
  font: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '24px',
  LineHeight: '29px',
  color: theme.palette.colorSoftBlack,
  flex: 'none',
  order: '1',
  flexGrow: '0'
});

export const styleTitleHeadPageGrid = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
};

export const styleBoxScrolleIcon = {
  zIndex: '0',
  width: '100%',
  marginTop: { xs: '100px', sm: '135px', md: '160px' },
  marginBottom: '100px',
  overflowY: 'auto'
};

export const styleHeadPage = (theme) => ({
  position: 'fixed',
  zIndex: '1',
  width: '-webkit-fill-available',
  justifyContent: 'center',
  paddingTop: '25px',
  paddingLeft: '50px',
  paddingBottom: '35px',
  gap: '20px',
  borderBottom: `3px solid ${theme.palette.colorMainLight}`,
  background: theme.palette.colorMainLight
});

export const styleHeadPageTwoColumns = (theme) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  justifyItems: 'stretch',
  position: 'fixed',
  zIndex: '1',
  width: '-webkit-fill-available',
  paddingTop: '25px',
  paddingLeft: '50px',
  paddingBottom: '35px',
  gap: '20px',
  borderBottom: `3px solid ${theme.palette.colorMainLight}`,
  background: theme.palette.colorMainLight
});

export const styleLicenceTitle = {
  fontSize: { xs: '30px', sm: '50px', md: '80px' },
  fontWeight: 'bold',
  animation: `${fadeInTitle} 1.0s ease-in-out`
};

export const styleLicenceButtonText = (theme) => ({
  paddingTop: '8px',
  font: 'caption',
  textAlign: 'center',
  position: 'relative',
  textTransform: 'none',
  overflowWrap: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
  maxHeight: '1.2rem',
  fontWeight: 'bold',
  color: theme.palette.colorSoftBlack
});

export const styleLicenceButtonImage = {
  borderRadius: '4px',
  width: '48px',
  height: '48px',
  overflow: 'hidden',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  objectFit: 'contain'
};

export const styleLicences = (margin, padding) => ({
  padding,
  margin
});

export const styleLicencesCard = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: '30px',
  backgroundColor: theme.palette.colorMainLight,
  marginBottom: '20px'
});

export const styleLicencesContainer = (theme, isSelected) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  flexDirection: 'column',
  alignItems: 'center',
  p: 1,
  m: 1,
  padding: '3px',
  width: { xs: '130px', sm: '150px', md: '208px' },
  height: { xs: '102px', sm: '134px', md: '168px' },
  background: theme.palette.colorApps,
  boxShadow: !isSelected
    ? `10px 10px 1px ${theme.palette.colorGray}`
    : `10px 10px 1px ${theme.palette.colorGray}, inset 0px 0px 0px 3px ${theme.palette.colorMainSelected}`,
  borderRadius: '16px',
  transition: 'all 0.5s',
  '&:hover': {
    transform: 'scale(1.15,1.15)',
    boxShadow: !isSelected
      ? '20px 20px 3px #D9D9D9'
      : `20px 20px 3px #D9D9D9, inset 0px 0px 0px 3px #93486D`
  }
});

export const styleLicenceButton = (theme) => ({
  borderRadius: '8px',
  justifyContent: 'space-around',
  height: { xs: '50px', sm: '60px', md: '100px' },
  width: { xs: '50px', sm: '60px', md: '100px' },
  cursor: 'pointer',
  position: 'relative',
  alignItems: 'center',
  display: 'flex',
  fontSize: '32px',
  fontWeight: '200',
  margin: 'auto',
  background: theme.palette.colorMainLight
});
