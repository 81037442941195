import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ItemsList from '../ItemsList';

import { formatColumnsForTable } from '../../../components/datalist/ComponentsInTable';
import {
  getAllActivixLeadsPerPage,
  resetActivixLeadsState,
  selecTotal,
  selectActivixLeads,
  selectIsLoading,
  selectIsTableDisplayable
} from '../../../redux/slices/ActivixLeads';
import ActivixCreationPopup from '../../../components/popup/ActivixCreationPopup';
import hasPermissions from '../../../utils/Permissions/permissions';
import { selectPermissions } from '../../../redux/slices/userConnected';
import { styleActionIconMobileUsersAzure } from '../../../assets/style/views/UserPages';

export default function ActivixLeads() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');
  const activixLeads = useSelector(selectActivixLeads).map((el) => ({
    ...el,
    id: JSON.stringify(el.id)
  }));
  const total = useSelector(selecTotal);
  const [totalItems, setTotalItems] = useState(0);

  const permissions = useSelector(selectPermissions);

  useEffect(() => {
    setTotalItems(total);
  }, [total]);

  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);

  const handleChangePage = (e) => {
    if (e.target?.dataset?.testid === 'KeyboardArrowLeftIcon' && page !== 1) setPage(page - 1);
    if (e.target?.dataset?.testid === 'KeyboardArrowRightIcon') setPage(page + 1);
    if (e?.target?.value) setPage(e.target.value + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };
  useEffect(() => {
    dispatch(getAllActivixLeadsPerPage({ page, perPage: rowsPerPage, searchTerm }));
    return function cleanup() {
      dispatch(resetActivixLeadsState());
    };
  }, [dispatch, rowsPerPage, page, searchTerm]);

  const handleSearchTerm = (e) => {
    setSearchTerm(() => e);
    setPage(1);
  };

  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];

    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map((rowIndex) => activixLeads[rowIndex === null ? 0 : rowIndex]?.id);
    setTableSelectedRows(tempRows);
  };

  const usersListIsLoading = useSelector(selectIsLoading);
  const isTableDisplayable = useSelector(selectIsTableDisplayable);

  const searchBar = {
    isDisplayable: true,
    disabled: false
  };

  const columnOptions = [
    {
      name: 'id',
      type: 'LinkInTable',
      linkOptions: { linkColumn: 'id', linkTo: 'id' },
      display: true
    },
    {
      name: 'first_name',
      display: true
    },
    {
      name: 'last_name',
      display: true
    },
    { name: 'type', display: true },
    {
      name: 'country',
      display: true
    },
    {
      name: 'created_at',
      display: true
    },
    {
      name: 'updated_at',
      display: true
    }
  ];
  const activixLeadsColumnsDisplayed = [
    'id',
    'first_name',
    'last_name',
    'type',
    'comment',
    'country',
    'created_at',
    'updated_at'
  ];
  const formatedColumns = formatColumnsForTable(
    activixLeadsColumnsDisplayed,
    activixLeads,
    columnOptions,
    t
  );
  const [activixCreationPopup, setActivixCreationPopup] = useState(false);

  const buttons = [
    {
      disabled: false,
      datatestid: 'ActivixLeadCreate',
      isDisplayable: hasPermissions(permissions, 'ADD_ACTIVIX_LEAD'),
      onClick: () => setActivixCreationPopup(true),
      text: t('addLead'),
      icon: <PersonAddAlt1Icon sx={styleActionIconMobileUsersAzure} />
    }
  ];

  const optionsKeys = {
    titleNameKey: 'first_name',
    secondaryTitleKey: 'last_name'
  };

  const desktopConfig = {
    buttons,
    actions: {
      handleSearchTerm
    },
    isDisableCheckBox: true,
    hideRefresh: true,
    searchBar,

    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: activixLeads
    },

    loading: {
      itemsListIsLoading: usersListIsLoading,
      isTableDisplayable
    },

    tables: {
      optionsKeys,
      totalItems,
      formatedColumns,
      page: page - 1,
      rowsPerPage,
      selectedIndexRows,
      setTotalItems: () => {},
      handleChangePage,
      handleChangeRowsPerPage,
      jumpPageNumber: 100,
      rowsPerPageOptions: [50]
    },

    search: {
      setSearchTerm,
      isSlowSearch: true
    },

    text: {
      titleText: t('activixLeads'),
      bigTitleText: t('activixLeads'),
      tagId: 'activixLeads'
    },
    searchBarPlaceOlder: t('searchLead')
  };

  return (
    <Grid>
      <ActivixCreationPopup
        popup={activixCreationPopup}
        closePopup={async () => {
          setActivixCreationPopup(false);
        }}
        validatePopup={async () => {
          await dispatch(getAllActivixLeadsPerPage({ page, perPage: rowsPerPage, searchTerm }));
        }}
      />
      <ItemsList config={desktopConfig} />
    </Grid>
  );
}
