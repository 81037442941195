import React from 'react';
import {
  Checkbox,
  Chip,
  FormControl,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useTheme
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { useTranslation } from 'react-i18next';
import {
  styleChipColorStatus,
  styleChipStatus,
  styleChipStatusGroup,
  styleChipSynchro,
  styleColoredChip
} from '../../assets/style/views/UserPages';
import { menuProps } from '../../assets/style/utils/utils';

const containsAllElements = (list1, list2) => list2.every((element) => list1.includes(element));

export const LinkInTable = (rows, linkOptions) =>
  function LinkInTable2(value) {
    return value < rows.length ? (
      <Link
        component={RouterLink}
        data-testid={`${rows[value][linkOptions?.linkTo]}DisplayUserLink`}
        to={rows[value][linkOptions?.linkTo]}
        underline="hover"
        sx={(theme) => ({ overflow: 'hidden', color: theme.palette.colorMainSelected })}
      >
        {rows[value][linkOptions?.linkColumn]}
      </Link>
    ) : null;
  };

export const DoubleLinkInTable = (rows, linkOptions) =>
  function DoubleLinkInTable2(value) {
    if (value < rows.length) {
      return (
        <Link
          component={RouterLink}
          data-testid={`${rows[value][linkOptions?.linkTo1]}+${
            rows[value][linkOptions?.linkTo2]
          }DisplayUserLink`}
          to={`${rows[value][linkOptions?.linkTo1]}+${rows[value][linkOptions?.linkTo2]}`}
          underline="hover"
          sx={(theme) => ({ overflow: 'hidden', color: theme.palette.colorMainSelected })}
        >
          {rows[value][linkOptions?.linkColumn]}
        </Link>
      );
    }
    return null;
  };

export const CheckedIconInTable = (rows, name) =>
  function CheckedIconInTable2(value) {
    const { t } = useTranslation();
    const theme = useTheme();
    return value < rows.length && (rows[value][name] === false || rows[value][name] === true) ? (
      <Chip
        icon={<CircleRoundedIcon fontSize="small" sx={{ marginLeft: '6px', height: '8px' }} />}
        label={!rows[value][name] ? `${t('notActive')}` : `${t('active')}`}
        color={!rows[value][name] ? 'error' : 'success'}
        sx={styleChipStatus(theme, rows[value][name])}
        data-testid="IconActiveNotActive"
      />
    ) : null;
  };

export const chipColorClassification = (rows, name, classification) =>
  function chipColorClassification2(value) {
    const theme = useTheme();
    return value < rows.length ? (
      <Chip
        icon={
          <CircleRoundedIcon
            fontSize="small"
            color={
              classification[rows[value][name]]?.fontColor || classification?.default?.fontColor
            }
            sx={{
              marginLeft: '6px',
              height: '8px'
            }}
          />
        }
        label={rows[value][name]}
        sx={styleChipColorStatus(theme, rows[value][name], classification)}
        data-testid="IconColorCustom"
      />
    ) : null;
  };

export const chipColorCustom = (rows, name, chipsTypes) =>
  function chipColorCustom2(value) {
    if (rows[value]) {
      const realValue = rows[value][name];
      const chipProps = chipsTypes.filter((el) => el.value === realValue)[0];
      return value < rows.length && (rows[value][name] === false || rows[value][name] === true) ? (
        <Chip
          icon={
            <CircleRoundedIcon
              fontSize="small"
              sx={{
                marginLeft: '6px',
                height: '8px',
                color: `${chipProps?.fontColor} !important`
              }}
            />
          }
          label={chipProps.name}
          sx={styleColoredChip(chipProps.fontColor, chipProps.backgroundColor)}
          data-testid="IconColorCustomV2"
        />
      ) : null;
    }
    return null;
  };

export const CheckedSynchronizeInTable = (rows, name) =>
  function CheckedSynchronizeInTable2(value) {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
      rows[value] && (
        <Chip
          icon={<CircleRoundedIcon fontSize="small" sx={{ marginLeft: '6px', height: '8px' }} />}
          label={t(rows[value][name])}
          color="success"
          sx={styleChipSynchro(theme, rows[value][name])}
          data-testid="IconActiveNotActive"
        />
      )
    );
  };

export const PathDistinguishedName = (rows, name) =>
  function PathDistinguishedName2(value) {
    return value < rows.length
      ? rows[value][name]
          ?.split(',')
          .reverse()
          .join('')
          .replaceAll('DC=', '/')
          .replaceAll('OU=', '/')
          .replaceAll('CN=', '/')
      : null;
  };

export const ChipList = (rows, name) =>
  function ChipList2(value) {
    return value < rows.length && rows[value][name]?.length
      ? rows[value][name].map((group, index) => {
          const tempIndex = index;
          if (index < 2) {
            return (
              <Chip
                key={`${group}${tempIndex}`}
                icon={
                  <CircleRoundedIcon fontSize="small" sx={{ marginLeft: '6px', height: '8px' }} />
                }
                label={group}
                sx={styleChipStatusGroup}
              />
            );
          }
          if (index + 1 === rows[value][name].length) {
            return (
              <Chip
                key={`${group}${tempIndex}`}
                icon={
                  <CircleRoundedIcon fontSize="small" sx={{ marginLeft: '6px', height: '8px' }} />
                }
                label={`... +${index - 1}`}
                sx={styleChipStatusGroup}
              />
            );
          }
          return null;
        })
      : null;
  };

export const CustomTypo = (rows, columnName, format) =>
  function CustomTypo2(value) {
    return value < rows.length ? <Typography>{format(rows[value][columnName])}</Typography> : null;
  };

export const formatColumnsForTable = (columns, data, columnOptions, t) =>
  [...columns].map((name) => {
    const columnType = columnOptions.filter((columnOption) => columnOption.name === name);
    const theme = useTheme();
    if (columnType.length && columnType[0]?.type === 'LinkInTable') {
      return {
        name,
        label: t(name),
        options: {
          display: !!columnType[0]?.display,
          customBodyRenderLite: LinkInTable(data, columnType[0]?.linkOptions),
          filter: !!columnType[0]?.filter,
          data
        }
      };
    }
    if (columnType.length && columnType[0]?.type === 'CustomLinkInTable') {
      return {
        name,
        label: t(name),
        options: {
          display: !!columnType[0]?.display,
          customBodyRenderLite: DoubleLinkInTable(data, columnType[0]?.linkOptions),
          filter: !!columnType[0]?.filter,
          data
        }
      };
    }
    if (columnType.length && columnType[0]?.type === 'PathDistinguishedName') {
      return {
        name,
        label: t(name),
        options: {
          display: !!columnType[0]?.display,
          customBodyRenderLite: PathDistinguishedName(data, name),
          filter: !!columnType[0]?.filter,
          data
        }
      };
    }
    if (columnType.length && columnType[0]?.type === 'CheckedIconInTable') {
      return {
        name,
        label: t(name),
        options: {
          display: !!columnType[0]?.display,
          customBodyRenderLite: CheckedIconInTable(data, name),
          filter: !!columnType[0]?.filter,
          filterType: 'custom',
          filterOptions: {
            logic(active, filterVal) {
              if (!filterVal.length) return false;
              if (filterVal[0] === 'Tous') return false;
              if (filterVal[0] === 'Actif') return active === false;
              if (filterVal[0] === 'Inactif') return active === true;

              return false;
            },
            display: (filterList, onChange, index, column) => (
              <FormControl>
                <Typography sx={{ color: theme.palette.colorSoftBlack, fontSize: '15px' }}>
                  {t(name)}
                </Typography>
                <Select
                  variant="outlined"
                  fullWidth
                  value={filterList[index]}
                  renderValue={(selected) => selected}
                  MenuProps={menuProps(theme)}
                  onChange={(event) => {
                    const temp = [...filterList];
                    temp[index] = [event.target.value];
                    onChange(temp[index], index, column);
                  }}
                >
                  {columnType[0]?.filterListOptions.map((columnName) => (
                    <MenuItem key={columnName} value={columnName}>
                      {columnName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          }
        }
      };
    }

    if (columnType.length && columnType[0]?.type === 'colorClassification') {
      return {
        name,
        label: t(name),
        options: {
          display: !!columnType[0]?.display,
          customBodyRenderLite: chipColorClassification(data, name, columnType[0]?.classification),
          filter: !!columnType[0]?.filter,
          setCellProps: (value) => {
            return {
              'data-testidcustomed': `table${name}${value}`
            };
          },
          filterOptions: columnType[0]?.filterListOptions?.length
            ? {
                names: columnType[0]?.filterListOptions
              }
            : undefined
        }
      };
    }

    if (columnType.length && columnType[0]?.type === 'boolColorClassification') {
      return {
        name,
        label: t(name),
        options: {
          display: !!columnType[0]?.display,
          customBodyRenderLite: chipColorCustom(data, name, columnType[0]?.chipsTypes)
        }
      };
    }

    if (columnType.length && columnType[0]?.type === 'isSynchroInTable') {
      return {
        name,
        label: t(name),
        options: {
          display: !!columnType[0]?.display,
          customBodyRenderLite: CheckedSynchronizeInTable(data, name),
          filter: !!columnType[0]?.filter,
          filterType: 'custom',
          filterOptions: columnType[0]?.filterListOptions?.length
            ? {
                logic: (location, filters) => {
                  if (!filters.length) return false;
                  if (filters.length && location?.length) {
                    return !containsAllElements(location, filters);
                  }
                  return true;
                },
                display: (filterList, onChange, index, column) => {
                  const optionValues = columnType[0]?.filterListOptions.map(
                    (filterOption) => filterOption.name
                  );
                  return (
                    <FormControl>
                      <Typography sx={{ color: theme.palette.colorSoftBlack, fontSize: '15px' }}>
                        {t(name)}
                      </Typography>
                      <Select
                        multiple
                        sx={{ width: '100px' }}
                        value={filterList[index]}
                        renderValue={(selected) => selected.join(', ')}
                        onChange={(event) => {
                          const temp = [...filterList];
                          temp[index] = event.target.value;
                          onChange(temp[index], index, column);
                        }}
                      >
                        {optionValues.map((item) => (
                          <MenuItem key={item} value={item}>
                            <Checkbox
                              color="primary"
                              checked={filterList[index].indexOf(item) > -1}
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }
              }
            : undefined
        }
      };
    }

    if (columnType.length && columnType[0]?.type === 'ChipList') {
      return {
        name,
        label: t(name),
        options: {
          display: !!columnType[0]?.display,
          customBodyRenderLite: ChipList(data, name),
          filter: !!columnType[0]?.filter,
          filterType: 'custom',
          filterOptions: columnType[0]?.filterListOptions?.length
            ? {
                logic: (location, filters) => {
                  if (!filters.length) return false;
                  if (filters.length && location?.length) {
                    return !containsAllElements(location, filters);
                  }
                  return true;
                },
                display: (filterList, onChange, index, column) => {
                  const optionValues = columnType[0]?.filterListOptions.map(
                    (filterOption) => filterOption.name
                  );
                  return (
                    <FormControl>
                      <Typography sx={{ color: theme.palette.colorSoftBlack, fontSize: '15px' }}>
                        {t(name)}
                      </Typography>{' '}
                      <Select
                        multiple
                        sx={{ width: '100px' }}
                        value={filterList[index]}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={menuProps(theme)}
                        onChange={(event) => {
                          const temp = [...filterList];
                          temp[index] = event.target.value;
                          onChange(temp[index], index, column);
                        }}
                      >
                        {optionValues.map((item) => (
                          <MenuItem key={item} value={item}>
                            <Checkbox
                              color="primary"
                              checked={filterList[index].indexOf(item) > -1}
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }
              }
            : undefined
        }
      };
    }
    if (columnType.length && columnType[0]?.type === 'customFormat') {
      return {
        name,
        label: t(name),
        options: {
          display: !!columnType[0]?.display,
          customBodyRenderLite: CustomTypo(data, name, columnType[0]?.format),
          filter: !!columnType[0]?.filter,
          data
        }
      };
    }
    return {
      name,
      label: t(name),
      options: {
        display: !!columnType[0]?.display,
        filter: !!columnType[0]?.filter,
        setCellProps: (value) => {
          return {
            'data-testidcustomed': `table${name}${value}`
          };
        },
        filterOptions: columnType[0]?.filterListOptions?.length
          ? {
              names: columnType[0]?.filterListOptions
            }
          : undefined
      }
    };
  });
