import { createTheme } from '@mui/material';
import palette from './palette';
import paletteAdmin from './paletteAdmin';
import paletteSombre from './paletteSombre';

export const themeAdmin = createTheme({
  palette: paletteAdmin
});

export const theme = createTheme({
  palette
});

export const themeSombre = createTheme({
  palette: paletteSombre
});
