import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, useTheme, Tooltip, Input } from '@mui/material';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { selectLicensesIsLoading } from '../../redux/slices/AzureLicenses';
import DataList from '../datalist/DataList';
import {
  styleGridUsers,
  styleToolbar,
  styleSearchBar,
  styleMarginBottom
} from '../../assets/style/views/UserPages';
import { formatColumnsForTable } from '../datalist/ComponentsInTable';
import CustomizedPagination from '../datalist/TablePagination';

export default function AzureLicensesTab({
  page,
  setPage,
  setSelectedLicenses,
  checkboxKey,
  activeCheckBox,
  disableCheckBox,
  azureLicenses,
  searchTerm,
  setSearchTerm
}) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const { t } = useTranslation();
  const theme = useTheme();

  const isLoading = useSelector(selectLicensesIsLoading);

  const columns = [
    'displayName',
    'prepaidUnitsEnable',
    'consumedUnits',
    'prepaidUnitsAvailable',
    'prepaidUnitsWarning'
  ];
  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const handleSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(() => value);
    setPage(0);
  };

  return (
    <Grid container sx={styleGridUsers}>
      <Grid container item md={6} sm={12} xs={12} sx={[styleMarginBottom, styleSearchBar]}>
        <Input
          id="azureLicensesSearchBar"
          type="text"
          name="searchBar"
          placeholder={t('searchLicense')}
          onChange={handleSearchTerm}
          data-testid="searchAzureLicenses"
          sx={{
            '&:after ': {
              borderBottom: `2px solid ${theme.palette.colorMain}`
            },
            color: theme.palette.colorBlack
          }}
        />
        <Tooltip title={t('infoSearchBarAzureLicense')} placement="bottom-start">
          <InfoOutlinedIcon />
        </Tooltip>
      </Grid>
      <DataList
        columns={formatColumnsForTable(
          columns,
          azureLicenses,
          [
            { name: 'displayName', display: true },
            { name: 'prepaidUnitsEnable', display: true },
            { name: 'consumedUnits', display: true },
            { name: 'prepaidUnitsAvailable', display: true },
            { name: 'prepaidUnitsWarning', display: true }
          ],
          t
        )}
        rows={azureLicenses}
        page={page}
        rowsPerPage={rowsPerPage}
        columnDisplayedInCollapse={columns[0]}
        activeCheckBox={activeCheckBox}
        checkboxKey={checkboxKey}
        setTableSelectedRows={setSelectedLicenses}
        disableCheckBox={disableCheckBox}
        isDisplayable={!isLoading}
        searchTerm={searchTerm}
        setTotalItems={setTotalItems}
      />
      <CustomizedPagination
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={styleToolbar}
      />
    </Grid>
  );
}

AzureLicensesTab.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setSelectedLicenses: PropTypes.func,
  checkboxKey: PropTypes.string,
  activeCheckBox: PropTypes.bool,
  disableCheckBox: PropTypes.bool,
  azureLicenses: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
      ])
    )
  ),
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func
};
AzureLicensesTab.defaultProps = {
  setSelectedLicenses: () => {},
  checkboxKey: '',
  activeCheckBox: false,
  disableCheckBox: false,
  azureLicenses: [],
  searchTerm: '',
  setSearchTerm: () => {}
};
