import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Select,
  MenuItem,
  FormHelperText,
  FormLabel,
  Autocomplete,
  Checkbox
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import {
  styleHelperText,
  styleDomain,
  styleEyePassword
} from '../../assets/style/views/AzurADUserPopup';
import { styleCheckbox } from '../../assets/style/views/UserPages';
import { CustomTextField, styleScrollbar } from '../../assets/style/utils/utils';

export default function CustomFormField({
  columnId,
  columnName,
  input,
  isEditable,
  isNullable,
  valueObject,
  setValueObject,
  error,
  isSubmit,
  ruleMessageKey,
  domain,
  setDomain,
  listAllDomain,
  findArgsForRegexByColumnName,
  isAd
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [blurField, setBlurField] = useState(false);

  const handleDisplayError = () =>
    (blurField || isSubmit) && error(columnId, isNullable, isEditable);

  const helperText = () => {
    if (blurField || isSubmit) {
      if (!isNullable && !valueObject[columnId]) {
        return t('fieldNull');
      }
      if (error(columnId, isNullable, isEditable)) {
        return t(ruleMessageKey);
      }
    }
    return '';
  };

  const handleChange = (columnIdField, newValue = '') => {
    const newValueObject = { ...valueObject };

    if (columnIdField === 'domain') {
      setDomain(newValue);
      if (input === 'SEMICOMPLETE') {
        const mailWhitoutDomain = newValueObject.mail?.split('@')[0] || '';
        newValueObject.mail = `${mailWhitoutDomain}@${newValue}`;
      }
    } else if (input === 'SEMICOMPLETE' && columnIdField === 'mail') {
      const currentDomain = newValueObject?.mail?.split('@')[1] || domain;
      newValueObject.mail = `${newValue}@${currentDomain}`;
    } else {
      newValueObject[columnIdField] = newValue;
    }
    if (newValueObject.surname?.length > 0 && newValueObject.givenName?.length > 0) {
      newValueObject.userPrincipalName = `${newValueObject.givenName}.${newValueObject.surname}`;
      if (newValueObject?.displayName)
        newValueObject.displayName = `${newValueObject.givenName} ${newValueObject.surname}`;
      if (newValueObject?.mailNickName)
        newValueObject.mailNickName = `${newValueObject.givenName}.${newValueObject.surname}`;
    }
    setValueObject(newValueObject);
  };

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Grid item xs={9}>
        <FormLabel sx={{ color: theme.palette.colorSoftProfile }}>
          {isAd && columnId === 'mail' ? 'Login' : columnName}
        </FormLabel>
      </Grid>
      {input === 'SEMICOMPLETE' ? (
        <Grid container item xs={9}>
          <Grid item xs={6}>
            <CustomTextField
              sx={styleDomain(theme)}
              value={
                valueObject[columnId]
                  ? valueObject[columnId]
                      ?.split('@')[0]
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f' ]/g, '')
                      .toLowerCase()
                  : ''
              }
              error={handleDisplayError()}
              data-testid={`${columnId}Field`}
              onChange={(event) => handleChange(columnId, event.target.value)}
              onBlur={() => setBlurField(true)}
              variant="standard"
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              id="select"
              labelId="select-domain"
              value={domain}
              sx={styleDomain(theme)}
              onChange={(event) => handleChange('domain', event.target.value)}
              data-testid="select-domain"
              variant="standard"
              MenuProps={{
                PaperProps: {
                  sx: styleScrollbar
                }
              }}
            >
              {listAllDomain.map((element) => (
                <MenuItem value={element} key={element} data-testid={element}>
                  {`@${element}`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <FormHelperText sx={styleHelperText}>{helperText()}</FormHelperText>
          </Grid>
        </Grid>
      ) : null}
      {input === 'TEXTFIELD' ? (
        <Grid item xs={9}>
          <CustomTextField
            type="text"
            sx={styleDomain(theme)}
            value={valueObject[columnId] ? valueObject[columnId] : ''}
            error={handleDisplayError()}
            helperText={helperText()}
            data-testid={`${columnId}Field`}
            onChange={(event) => handleChange(columnId, event.target.value)}
            onBlur={() => setBlurField(true)}
            variant="standard"
            disabled={!isEditable}
          />
        </Grid>
      ) : null}
      {input === 'PASSWORD' ? (
        <Grid item xs={9}>
          <form>
            <CustomTextField
              name="new_password"
              autoComplete="on"
              type={isPasswordVisible ? 'text' : 'password'}
              sx={styleDomain(theme)}
              error={handleDisplayError()}
              helperText={helperText()}
              data-testid={`${columnId}Field`}
              onChange={(event) => handleChange(columnId, event.target.value)}
              onBlur={() => setBlurField(true)}
              variant="standard"
              disabled={!isEditable}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={styleEyePassword(theme)}
                    onClick={() => {
                      setIsPasswordVisible(!isPasswordVisible);
                    }}
                  >
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                )
              }}
            />
          </form>
        </Grid>
      ) : null}
      {input === 'AUTOCOMPLETE' ? (
        <Grid item xs={9}>
          <Autocomplete
            disablePortal
            disableClearable
            id={`combo-box-demo-${columnId}`}
            options={findArgsForRegexByColumnName(columnId)}
            sx={{ width: '100%' }}
            value={valueObject[columnId] ? valueObject[columnId] : ''}
            onChange={(event, newValue) => handleChange(columnId, newValue)}
            onBlur={() => setBlurField(true)}
            data-testid={`select-${columnId}`}
            ListboxProps={{ sx: styleScrollbar(theme) }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disabled={!isEditable}
            renderInput={(params) => (
              <CustomTextField
                error={handleDisplayError()}
                helperText={helperText()}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
              />
            )}
          />
        </Grid>
      ) : null}
      {input === 'CHECKBOX' ? (
        <Grid item xs={9}>
          <Checkbox
            checked={valueObject[columnId] || false}
            sx={styleCheckbox(theme)}
            defaultValue={valueObject[columnId]}
            disabled={!isEditable}
            data-testid={`${columnId}Checkbox`}
            onClick={() =>
              handleChange(columnId, valueObject[columnId] ? !valueObject[columnId] : true)
            }
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

CustomFormField.propTypes = {
  columnId: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  input: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isNullable: PropTypes.bool.isRequired,
  valueObject: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
          )
        ])
      ),
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.arrayOf(PropTypes.string)])
      )
    ])
  ).isRequired,
  setValueObject: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  isSubmit: PropTypes.bool,
  ruleMessageKey: PropTypes.string,
  domain: PropTypes.string,
  setDomain: PropTypes.func,
  listAllDomain: PropTypes.arrayOf(PropTypes.string),
  findArgsForRegexByColumnName: PropTypes.func,
  isAd: PropTypes.bool,
  password: PropTypes.string
};

CustomFormField.defaultProps = {
  ruleMessageKey: '',
  domain: '',
  setDomain: null,
  listAllDomain: [],
  findArgsForRegexByColumnName: null,
  isAd: false,
  password: '',
  isSubmit: false
};
