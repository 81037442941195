import axios from 'axios';
import API_URL from '../../constants/constants';

const AppListService = {
  getRouteNoConnected() {
    return axios.get(`${API_URL}/auth/app_list`);
  },
  getRouteConnected() {
    return axios.get(`${API_URL}/app_list`);
  },
  getAllAppList() {
    return axios.get(`${API_URL}/allAppList`);
  },
  deleteAppList(body) {
    return axios.delete(`${API_URL}/appList`, { data: body });
  },
  postCreateAppList(body) {
    return axios.post(`${API_URL}/create_applist`, body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  updateApp(id, body) {
    return axios.post(`${API_URL}/appList/${id}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    });
  }
};

export default AppListService;
