import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Button, Checkbox, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RadioButtonUnchecked } from '@mui/icons-material';
import {
  styleLicenceButtonText,
  styleLicenceButtonImage,
  styleLicencesContainer
} from '../../assets/style/views/LoginBoard';
import { BACK_URL } from '../../constants/constants';

export default function SelectablePicture({ name, link, id, urlId, isBackOffice, selectApp }) {
  const [isSelected, setIsSelected] = useState(false);
  const theme = useTheme();
  return (
    <Box key={name}>
      <Button
        sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        key={name}
        href={isBackOffice ? undefined : link}
        target="_blank"
        data-testid={`box${name}`}
        onClick={() => {
          selectApp(isSelected, id);
          setIsSelected(!isSelected);
        }}
      >
        <Box
          href={isBackOffice ? undefined : link}
          sx={styleLicencesContainer(theme, isSelected && isBackOffice)}
        >
          {isBackOffice && (
            <Box
              sx={{
                height: '20%',
                width: '100%',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center'
              }}
            >
              <Checkbox
                icon={<RadioButtonUnchecked />}
                checkedIcon={<CheckCircleIcon sx={{ color: theme.palette.colorMainSelected }} />}
                data-testid={`ListButtonCheck${id}`}
                checked={isSelected}
                sx={{ display: 'center' }}
              />
            </Box>
          )}
          <Box
            sx={{
              height: '60%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              sx={styleLicenceButtonImage}
              src={urlId.startsWith('data:image') ? urlId : `${BACK_URL}api/images/${urlId}.png`}
              style={{ maxHeight: '90%', maxWidth: '90%' }}
              alt="Logos licences"
            />
          </Box>
          <Typography data-testid={`title${name}`} sx={styleLicenceButtonText}>
            {name}
          </Typography>
        </Box>
      </Button>
    </Box>
  );
}
SelectablePicture.propTypes = {
  name: PropTypes.string.isRequired,
  urlId: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  isBackOffice: PropTypes.bool,
  id: PropTypes.number.isRequired,
  selectApp: PropTypes.func
};

SelectablePicture.defaultProps = {
  isBackOffice: false,
  selectApp: () => {}
};
