import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ActivixLeadsService from '../../services/activix/ActivixLeadsService';

export const initialState = {
  leads: [],
  isLoading: false,
  updatedAt: '',
  status: 'fulfilled',
  selectedLead: {},
  total: 0
};

export const getAllActivixLeadsPerPage = createAsyncThunk(
  'getAllActivixLeadsPerPage',
  async (payload) => {
    const res = await ActivixLeadsService.getAllActivixLeadsPerPage(payload);
    return res.data;
  }
);

export const getActivixLeadById = createAsyncThunk('getActivixLeadById', async (payload) => {
  const res = await ActivixLeadsService.getActivixLeadById(payload);
  return res.data;
});

export const updateActivixLead = createAsyncThunk('updateActivixLead', async (payload) => {
  const res = await ActivixLeadsService.updateActivixLead(payload);
  return res.data;
});

export const createActivixLead = createAsyncThunk('createActivixLead', async (payload) => {
  const res = await ActivixLeadsService.createActivixLead(payload);
  return res.data;
});

export const activixLeadsSlice = createSlice({
  name: 'activixLeads',
  initialState,
  reducers: {
    resetActivixLeadsState: (state) => {
      state.leads = [];
    },
    resetSelectedUser: (state) => {
      state.selectedUser = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllActivixLeadsPerPage.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getAllActivixLeadsPerPage.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.leads = action.payload?.leads;
        state.total = action.payload?.meta?.total;
      })
      .addCase(getAllActivixLeadsPerPage.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(getActivixLeadById.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getActivixLeadById.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedLead = action?.payload?.lead;
      })
      .addCase(getActivixLeadById.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(updateActivixLead.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(updateActivixLead.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedLead = action?.meta?.arg;
      })
      .addCase(updateActivixLead.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(createActivixLead.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(createActivixLead.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(createActivixLead.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});

export const { resetActivixLeadsState } = activixLeadsSlice.actions;

export const selectActivixLeads = (state) => {
  return state.activixLeads.leads;
};

export const selectIsLoading = (state) => {
  return state.activixLeads.isLoading;
};
export const selectIsTableDisplayable = (state) => {
  return !state.activixLeads.isLoading;
};

export const selecTotal = (state) => {
  return state.activixLeads.total;
};
export const selectedLead = (state) => state.activixLeads.selectedLead;

export const selectIsLeadLoaded = (state) => {
  if (!state.activixLeads.isLoading && state.activixLeads.selectedLead?.id) {
    return true;
  }
  return false;
};

export default activixLeadsSlice.reducer;
