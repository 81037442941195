import loginBackground from '../../images/backgroundloginflou.webp';

export const styleBody = {
  width: 'auto',
  height: 'auto'
};

export const styleBackground = {
  backgroundImage: `url(${loginBackground})`,
  backgroundSize: 'cover',
  width: { xs: '100vh', sm: '100vh', md: '189vh' },
  height: '100vh'
};
export const logoPegasus = {
  width: '50%',
  position: 'absolute',
  top: '7.5%',
  left: '25.5%',
  background: 'white',
  borderRadius: '5px'
};

export const styleCardSize = (theme) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: { xs: '380px', sm: '390px', md: '460px' },
  boxShadow: `2px 3px 3px ${theme.palette.colorBlack}`,
  border: `solid ${theme.palette.colorMainLight} 0.2rem`
});

export const styleResponsiveBox = {
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '70% !important',
  marginLeft: '15%'
};

export const styleGridItemMargin = {
  margin: '25px',
  marginBottom: '2px',
  width: '300px',
  maxWidth: '300px !important'
};

export const styleDivider = {
  marginTop: '15px'
};

export const styleCardMedia = {
  width: '160%',
  height: '70%'
};

export const styleLinkForgotPassword = (theme) => ({
  color: theme.palette.colorMain,
  marginTop: '20px',
  marginBottom: '20px',
  textDecoration: 'none'
});

export const styleCollapseLogin = {
  border: 'none',
  boxShadow: 'none',
  position: 'initial',
  justifyContent: 'center'
};

export const styledLoginButton = {
  '& .MuiAccordionSummary-content': {
    justifyContent: 'center'
  }
};

export const styleAccordionSummary = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
};

export const styleLoginButton = (theme) => ({
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'uppercase',
  borderRadius: '4px',
  textDecoration: 'none',
  width: { xs: '160px', sm: '160px', md: '200px' },
  height: '42px',
  marginTop: '10%',
  color: theme.palette.colorMainLight,
  backgroundColor: theme.palette.colorMain,
  transition: 'all 0.5s',
  '&:hover': {
    background: theme.palette.colorMain,
    transform: 'scale(0.95, 0.95)'
  },
  '&:focus': {
    outline: 'none'
  },
  '&:disabled': {
    background: theme.palette.colorMain
  }
});
