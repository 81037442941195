/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  TextField,
  ListItem,
  Autocomplete,
  ListItemText,
  useTheme
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
// import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
// import { selectPermissions } from '../../redux/slices/userConnected';
import {
  styleContainer,
  styleTextfieldWrapper,
  stylePopupContainer,
  stylePopupNameWrapper,
  stylePopupName,
  stylePopupInfo,
  stylePopupInfoWrapper,
  stylePopupInfoIcon,
  styleAutoCompleteWrapper
} from '../../assets/style/components/SMS/Dashboard';
import { selectAzureUsersWithPhones } from '../../redux/slices/AzureUsersList';
import {
  updateConversationList,
  setSelectedConversation,
  selectConversationsList,
  rcsHandler,
  findOneConversation
} from '../../redux/slices/Messages';
// import hasPermissions from '../../utils/Permissions/permissions';
import { regexPhoneNumbers, regexpEscaping } from '../../utils/regex';
import { styleScrollbar } from '../../assets/style/utils/utils';

export default function Dashboard({ setIsChatOpen }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState('');
  // const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isInputIncorrect, setIsInputIncorrect] = useState(false);
  // const permissions = useSelector(selectPermissions);
  const azureUsersWithPhones = useSelector(selectAzureUsersWithPhones);
  const conversationList = useSelector(selectConversationsList);
  const theme = useTheme();

  const startConversation = (phone) => {
    const conversation = {
      phoneNumberId: null,
      phoneNumber: phone
    };
    const newItem = {
      text: '',
      createdTime: null,
      readTime: new Date().toDateString(),
      phoneNumber: {
        id: null,
        name: phone
      },
      status: {
        id: null,
        name: null
      }
    };
    dispatch(updateConversationList(newItem));
    dispatch(setSelectedConversation(conversation));
    dispatch(rcsHandler(phone));
    dispatch(findOneConversation(conversation.phoneNumberId, conversation.phoneNumber));
    setIsChatOpen(true);
  };

  const joinConversation = (phone) => {
    const matchingConversation = conversationList.filter(
      (conv) => conv.phoneNumber.name === phone
    )[0];
    const conversation = {
      phoneNumberId: matchingConversation.phoneNumber.id,
      phoneNumber: matchingConversation.phoneNumber.name
    };
    dispatch(setSelectedConversation(conversation));
    dispatch(findOneConversation(conversation.phoneNumberId, conversation.phoneNumber));
    setIsChatOpen(true);
  };

  const handleOptionSelection = (user) => {
    if (user.businessPhones !== '') {
      if (conversationList.some((conv) => conv.phoneNumber.name === user.businessPhones)) {
        joinConversation(user.businessPhones);
      } else {
        startConversation(user.businessPhones);
      }
    } else if (user.mobilePhone !== '') {
      if (conversationList.some((conv) => conv.phoneNumber.name === user.mobilePhone)) {
        joinConversation(user.mobilePhone);
      } else {
        startConversation(user.mobilePhone);
      }
    }
    setAutoCompleteValue(null);
    setAutoCompleteInputValue('');
    setIsInputIncorrect(false);
  };

  const handleInputSelection = (phoneNumber) => {
    if (regexPhoneNumbers(phoneNumber)) {
      startConversation(phoneNumber);
      setAutoCompleteInputValue('');
      setIsInputIncorrect(false);
    } else {
      setIsInputIncorrect(true);
    }
  };

  // useEffect(() => {
  //   if (autoCompleteInputValue === '' || isInputIncorrect) {
  //     setIsSubmitDisabled(true);
  //   } else {
  //     setIsSubmitDisabled(false);
  //   }
  // }, [autoCompleteInputValue, isInputIncorrect]);

  const handleChange = (e, newValue) => {
    if (newValue && typeof newValue === 'object') {
      handleOptionSelection(newValue);
    } else if (newValue && typeof newValue === 'string') {
      handleInputSelection(newValue);
    }
  };

  const handleInputChange = (newValue) => {
    setIsInputIncorrect(false);
    setAutoCompleteInputValue(newValue);
  };

  const filter = (users, params) => {
    const escapedString = regexpEscaping(params.inputValue);
    const regexp = new RegExp(`${escapedString}`, 'gi');
    return users.filter(
      (user) =>
        (user.displayName && user.displayName.match(regexp)) ||
        (user.surname && user.surname.match(regexp)) ||
        (user.givenName && user.givenName.match(regexp)) ||
        (user.mail && user.mail.match(regexp)) ||
        (user.mobilePhone !== '' && user.mobilePhone.match(regexp)) ||
        (user.businessPhones !== '' && user.businessPhones.match(regexp))
    );
  };

  const filterOption = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = filtered.length > 0;
    if (inputValue !== '' && !isExisting) {
      filtered.push(inputValue);
    }
    return filtered;
  };

  const getOptionLabel = (option) => {
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.displayName;
  };

  const renderOption = (props, option, id) => {
    if (typeof option === 'string') {
      return (
        <ListItem {...props}>
          <ListItemText>
            <Typography>{option}</Typography>
          </ListItemText>
        </ListItem>
      );
    }
    const user = option;
    return (
      <ListItem {...props} key={id}>
        <Box sx={stylePopupContainer}>
          <Box sx={stylePopupNameWrapper}>
            <Typography
              component="p"
              sx={stylePopupName}
              data-testid={`smsSearchOption${user.displayName}`}
            >
              {user.displayName}
            </Typography>
            {user.mail && (
              <Typography component="p" sx={stylePopupInfo}>
                <MailIcon sx={stylePopupInfoIcon} />
                {user.mail}
              </Typography>
            )}
          </Box>
          <Box sx={stylePopupInfoWrapper}>
            {user.businessPhones.length > 0 && (
              <Typography component="p" sx={stylePopupInfo}>
                <PhoneIcon sx={stylePopupInfoIcon} />
                {user.businessPhones}
              </Typography>
            )}
            {user.mobilePhone && (
              <Typography component="p" sx={stylePopupInfo}>
                <PhoneAndroidIcon sx={stylePopupInfoIcon} />
                {user.mobilePhone}
              </Typography>
            )}
          </Box>
        </Box>
      </ListItem>
    );
  };

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        label={t('search')}
        error={isInputIncorrect}
        data-testid="smsSearchTextField"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon
                style={{
                  color: theme.palette.colorMain,
                  width: '1.5em',
                  height: '1.5em'
                }}
              />
            </InputAdornment>
          ),
          type: 'search'
        }}
      />
    );
  };

  return (
    <Box sx={styleContainer}>
      <Box sx={styleTextfieldWrapper}>
        <Box sx={styleAutoCompleteWrapper}>
          <Autocomplete
            freeSolo
            autoHighlight
            disableClearable
            selectOnFocus
            handleHomeEndKeys
            id="sms-search-autocomplete"
            sx={{ width: { md: 340 } }}
            ListboxProps={{ sx: styleScrollbar(theme) }}
            options={azureUsersWithPhones}
            filterOptions={(options, params) => filterOption(options, params)}
            value={autoCompleteValue}
            onChange={(e, newValue) => handleChange(e, newValue)}
            inputValue={autoCompleteInputValue}
            onInputChange={(e) => handleInputChange(e.target.value)}
            getOptionLabel={(option) => getOptionLabel(option)}
            // eslint-disable-next-line react/prop-types
            renderOption={(props, option) => renderOption(props, option, props.id)}
            renderInput={(params) => renderInput(params)}
          />
        </Box>
        {t('smsSearchInfo')}
        {/* <Box sx={styleFieldIconWrapper}>
          <IconButton
            disabled={
              (isSubmitDisabled && hasPermissions(permissions, 'ADD_SMS')) ||
              !hasPermissions(permissions, 'ADD_SMS')
            }
            type="button"
            aria-label="New conversation"
            onClick={() => handleInputSelection(autoCompleteInputValue)}
            data-testid="smsSearchAddButton"
          >
            <AddIcon sx={styleFieldIcon} />
          </IconButton>
        </Box> */}
      </Box>
    </Box>
  );
}

Dashboard.propTypes = {
  setIsChatOpen: PropTypes.func.isRequired
};
