import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getDisableText,
  getOneAdUser,
  selectIsAdUserLoading,
  selectedUser
} from '../../../redux/slices/AdUsers';
import LoadingWrapper from '../../../components/layout/LoadingWrapper';
import AdUserDescription from '../../../components/adUser/AdUserDescription';
import UserAdGroupDescription from '../../../components/user/UserAdGroupsDescription';
import HeaderPage from '../../../components/HeaderPage';

import {
  getProviderColumns,
  resetColumn,
  selectColumnsInDetails
} from '../../../redux/slices/columns';
import UserStandardDescription from '../../../components/user/UserStandardDescription';
import UserLicensesDescription from '../../../components/user/UserLicensesDescription';

import {
  getAzureUser,
  getAzureUserAvailableServices,
  selectedUser as selectedAzureUser,
  resetSelectedUser,
  requestAzureUserDomains,
  findAzureUsers,
  resetAzureUserState,
  selectIsUserLoaded
} from '../../../redux/slices/AzureUsersList';
import { getLicensesAzure } from '../../../redux/slices/AzureLicenses';
import errorRedirection from '../../../utils/errorRedirection';
import UserAzureGroupDescription from '../../../components/user/UserAzureGroupsDescription';
import TagChips from '../../../components/TagChips';

export default function SynchroADAzureDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAdUserLoading = useSelector(selectIsAdUserLoading);
  const isUserLoaded = useSelector(selectIsUserLoaded);

  const { DNAzureId } = useParams();
  const [DN, id] = DNAzureId.split('+');

  const user = useSelector(selectedUser);
  const azureUser = useSelector(selectedAzureUser);

  const isMemberOfAzure = useSelector(selectColumnsInDetails).find(
    ({ columnId, providerName }) =>
      columnId === 'memberOf' && providerName === 'AZURE_ACTIVE_DIRECTORY'
  );
  const isAssignedLicensesAzure = useSelector(selectColumnsInDetails).find(
    ({ columnId, providerName }) =>
      columnId === 'assignedLicenses' && providerName === 'AZURE_ACTIVE_DIRECTORY'
  );
  const isMemberOfAD = useSelector(selectColumnsInDetails).find(
    ({ columnId, providerName }) => columnId === 'memberOf' && providerName === 'ACTIVE_DIRECTORY'
  );

  const dispatchGetOneAdUser = async () => {
    dispatch(getOneAdUser(DN));
  };
  const dispatchGetAzureUserAndColumns = async () => {
    try {
      await dispatch(getAzureUser(id)).unwrap();
      await dispatch(
        getProviderColumns({ providerName: 'ACTIVE_DIRECTORY', isBackOffice: false })
      ).unwrap();
      await dispatch(
        getProviderColumns({ providerName: 'AZURE_ACTIVE_DIRECTORY', isBackOffice: false })
      ).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  useEffect(() => {
    dispatch(getDisableText());
  }, [dispatch]);

  useEffect(() => {
    if (DN !== null) dispatchGetOneAdUser();
    if (id !== 'undefined') {
      dispatchGetAzureUserAndColumns();
      dispatch(getLicensesAzure());
      dispatch(requestAzureUserDomains());
      dispatch(getAzureUserAvailableServices());
      dispatch(findAzureUsers());
    }

    return function cleanup() {
      dispatch(resetColumn());
      dispatch(resetSelectedUser());
      dispatch(resetAzureUserState());
    };
  }, [dispatch, DN]);

  let bigTitleText = '';

  if (user && azureUser !== 'undefined') {
    if (user?.name && azureUser?.displayName) {
      bigTitleText = `${user?.name}`;
    } else if (user?.name) {
      bigTitleText = user?.name;
    } else if (azureUser?.displayName) {
      bigTitleText = azureUser?.displayName;
    }
  }

  const headerPageConfig = {
    text: {
      titleText: t('SynchroAdAzureEntity'),
      bigTitleText,
      selectedItemsText: '',
      tagId: 'synchroAdAzureDetail'
    },
    buttonReturn: {
      isDisplayable: true,
      onClick: () => navigate('/synchroAdAzure')
    }
  };

  return (
    <Grid
      container
      sx={{ contain: 'inline-size', height: '100%' }}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <HeaderPage headerPageConfig={headerPageConfig} />

      <Grid
        container
        sx={{
          flex: '1',
          overflowY: 'auto',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Grid sx={{ width: '100%', marginTop: '10px' }}>
          <TagChips DN={DN} azureId={id} />
        </Grid>

        <LoadingWrapper isChildDisplayable={!isAdUserLoading}>
          {user.distinguishedName ? (
            <Grid
              sx={(theme) => ({
                width: 'calc(100% - 30px)',
                background: theme.palette.colorSecondaryLight,
                borderRadius: '20px'
              })}
            >
              <AdUserDescription />
              {isMemberOfAD ? <UserAdGroupDescription /> : null}
            </Grid>
          ) : null}
        </LoadingWrapper>
        {id !== 'undefined' && (
          <LoadingWrapper isChildDisplayable={isUserLoaded}>
            <Grid
              sx={(theme) => ({
                width: 'calc(100% - 30px)',
                background: theme.palette.colorSecondaryLight,
                borderRadius: '20px'
              })}
              marginTop="10px"
            >
              <UserStandardDescription />
              {isMemberOfAzure ? <UserAzureGroupDescription /> : null}
              {isAssignedLicensesAzure ? <UserLicensesDescription /> : null}
            </Grid>
          </LoadingWrapper>
        )}
      </Grid>
    </Grid>
  );
}
