import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import JiraUserDescription from '../../../components/jiraUser/JiraUserDescription';
import {
  getOneJiraUser,
  selectedUser,
  getJiraUserGroups,
  selectIsJiraUserLoaded
} from '../../../redux/slices/JiraUsers';
import UserJiraGroupDescription from '../../../components/user/UserJiraGroupsDescription';
import errorRedirection from '../../../utils/errorRedirection';
import LoadingWrapper from '../../../components/layout/LoadingWrapper';
import HeaderPage from '../../../components/HeaderPage';
import { getProviderColumns, selectColumnsInDetails } from '../../../redux/slices/columns';

export default function JiraUser() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isJiraUserLoaded = useSelector(selectIsJiraUserLoaded);
  const isGroups = useSelector(selectColumnsInDetails).find(
    ({ columnId }) => columnId === 'groups'
  );
  const user = useSelector(selectedUser);
  const { key } = useParams();
  const navigate = useNavigate();

  const dispatchGetOneJiraUser = async () => {
    try {
      await dispatch(getOneJiraUser(key)).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };
  const dispatchGetAllJiraUserColumns = async () => {
    try {
      await dispatch(getProviderColumns({ providerName: 'JIRA', isBackOffice: false })).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const headerPageConfig = {
    text: {
      titleText: t('jiraUser'),
      bigTitleText: `${user.displayName}`,
      selectedItemsText: '',
      tagId: 'titleHeadPage'
    },
    buttonReturn: {
      isDisplayable: true,
      onClick: () => navigate('/jiraUsers')
    },
    isPositionFixed: true
  };

  useEffect(() => {
    dispatchGetAllJiraUserColumns();
    dispatchGetOneJiraUser();
    dispatch(getJiraUserGroups());
  }, [dispatch]);

  return (
    <LoadingWrapper isChildDisplayable={isJiraUserLoaded}>
      <HeaderPage headerPageConfig={headerPageConfig} />
      <Grid sx={{ width: '100%' }} marginTop={{ xs: '100px', sm: '130px', md: '175px' }}>
        <JiraUserDescription />
        {isGroups ? <UserJiraGroupDescription /> : null}
      </Grid>
    </LoadingWrapper>
  );
}
