import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import { useNavigate } from 'react-router-dom';
import {
  isLoading,
  selectSearchedJiraUser,
  JiraUsersUpdatedAt,
  refreshJiraUsers,
  findJiraUsers,
  resetJiraUsersState,
  getJiraUserGroups,
  selectIsTableDisplayable
} from '../../../redux/slices/JiraUsers';
import { selectPermissions } from '../../../redux/slices/userConnected';
import JiraGroupsPopup from '../../../components/popup/JiraGroupsPopup';
import hasPermissions from '../../../utils/Permissions/permissions';
import { formatColumnsForTable } from '../../../components/datalist/ComponentsInTable';
import { styleActionIconMobileUsers } from '../../../assets/style/views/UserPages';
import ItemsList from '../ItemsList';
import {
  getProviderColumns,
  resetColumn,
  selectColumnsDisplayed
} from '../../../redux/slices/columns';
import errorRedirection from '../../../utils/errorRedirection';

export default function JiraUsers() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [jiraGroupsPopup, setJiraGroupsPopup] = useState(false);
  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isRemoveGroups, setIsRemoveGroups] = useState(false);
  const displayColumns = useSelector(selectColumnsDisplayed).map(({ columnId }) => columnId);
  const isTableDisplayable = useSelector(selectIsTableDisplayable);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const permissions = useSelector(selectPermissions);

  const jiraUsers = useSelector(selectSearchedJiraUser(''));
  const areJiraUsersLoading = useSelector(isLoading);
  const updatedTime = useSelector(JiraUsersUpdatedAt);
  const resetSelectedRows = () => {
    setTableSelectedRows([]);
    setSelectedIndexRows([]);
  };

  const dispatchGetAllJiraUserColumns = async () => {
    try {
      await dispatch(getProviderColumns({ providerName: 'JIRA', isBackOffice: false })).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };
  useEffect(() => {
    dispatchGetAllJiraUserColumns();
    dispatch(findJiraUsers());
    dispatch(getJiraUserGroups());
    return function cleanup() {
      dispatch(resetJiraUsersState());
      dispatch(resetColumn());
    };
  }, [dispatch]);

  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
    setPage(0);
  };

  const handleRefresh = async () => {
    setPage(0);
    resetSelectedRows();
    dispatch(refreshJiraUsers());
  };

  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];
    setSelectedIndexRows(copyRows);

    const tempRows = copyRows.map((rowIndex) => jiraUsers[rowIndex === null ? 0 : rowIndex]);
    setTableSelectedRows(tempRows);
  };

  const searchBar = {
    isDisplayable: true,
    disabled: false
  };

  const columnOptions = [
    { name: 'key', display: true },
    {
      name: 'displayName',
      type: 'LinkInTable',
      linkOptions: { linkColumn: 'displayName', linkTo: 'key' },
      display: true
    },
    { name: 'active', type: 'CheckedIconInTable', display: true, filter: true }
  ];
  const formatedColumns = formatColumnsForTable(displayColumns, jiraUsers, columnOptions, t);
  const buttons = [
    {
      disabled: tableSelectedRows.length === 0,
      datatestid: 'addJiraGroups',
      isDisplayable: hasPermissions(permissions, 'SET_JIRA_GROUPS_TO_USER'),
      onClick: () => {
        setIsRemoveGroups(false);
        setJiraGroupsPopup(true);
      },
      text: t('addJiraGroups'),
      icon: <BookmarkAddIcon sx={styleActionIconMobileUsers} />
    },
    {
      disabled: tableSelectedRows.length === 0,
      datatestid: 'RemoveJiraGroup',
      isDisplayable: hasPermissions(permissions, 'SET_REMOVE_JIRA_GROUPS_TO_USER'),
      onClick: () => {
        setIsRemoveGroups(true);
        setJiraGroupsPopup(true);
      },
      text: t('removeJiraGroups'),
      icon: <BookmarkRemoveIcon sx={styleActionIconMobileUsers} />
    }
  ];

  const optionsKeys = {
    titleNameKey: 'displayName',
    secondaryTitleKey: 'name',
    statusKey: 'active'
  };

  const desktopConfig = {
    actions: {
      handleRefresh,
      handleSearchTerm
    },

    buttons,
    searchBar,

    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: jiraUsers
    },

    loading: {
      itemsListIsLoading: areJiraUsersLoading,
      isTableDisplayable
    },

    tables: {
      optionsKeys,
      formatedColumns,
      date: updatedTime,
      totalItems,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      selectedIndexRows,
      setTotalItems
    },

    search: {
      searchTerm,
      setSearchTerm
    },

    text: {
      titleText: t('jiraUsers'),
      bigTitleText: t('manageUsers'),
      selectedItemsText: t('selectedUsers'),
      tagId: 'JiraUsers'
    },
    searchBarPlaceOlder: t('searchUser')
  };

  return (
    <Grid>
      <JiraGroupsPopup
        popup={jiraGroupsPopup}
        closePopup={() => setJiraGroupsPopup(false)}
        selectedUsers={tableSelectedRows.map(({ name }) => name)}
        isRemove={isRemoveGroups}
        resetSelectedRows={resetSelectedRows}
      />
      <ItemsList config={desktopConfig} />
    </Grid>
  );
}
