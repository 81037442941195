import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import { useNavigate, useParams } from 'react-router-dom';
import { styleMainGrid } from '../../../assets/style/views/Device';
import {
  getOneDeviceById,
  getRecoverKeysById,
  selectSelectedDevice,
  selectRecoveryKeys,
  selectRecoveryKeysIsLoading,
  getRecoverKeysDetailsById,
  selectRecoveryKeysDetails,
  rebootIntuneDevice,
  selectIsDeviceIntuneLoaded,
  selectDeviceIntuneActionsColumns
} from '../../../redux/slices/IntuneDevices';
import CustomizedButton from '../../../components/buttons/CustomizedButton';
import InformationList from '../../../components/InformationList';
import StandardBox from '../../../components/StandardBox';
import StandardTable from '../../../components/StandardTable/StandardTable';
import InformationPopUp from '../../../components/popup/InformationPopup';
import CopyButton from '../../../components/buttons/CopyButton';
import EditIntuneDevicePopup from '../../../components/popup/EditIntuneDevicePopup';
import ConsentPopup from '../../../components/popup/ ConsentPopup';
import { selectPermissions } from '../../../redux/slices/userConnected';
import hasPermissions from '../../../utils/Permissions/permissions';
import { findAzureUsers, resetAzureUserState } from '../../../redux/slices/AzureUsersList';
import errorRedirection from '../../../utils/errorRedirection';
import LoadingWrapper from '../../../components/layout/LoadingWrapper';
import HeaderPage from '../../../components/HeaderPage';
import {
  getProviderColumns,
  resetColumn,
  selectColumnsInDetails
} from '../../../redux/slices/columns';

export default function DeviceIntune() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const device = useSelector(selectSelectedDevice);
  const recoveryKeys = useSelector(selectRecoveryKeys);
  const recoveryKeysDetails = useSelector(selectRecoveryKeysDetails);
  const recoveryKeysDetailsIsLoading = useSelector(selectRecoveryKeysIsLoading);
  const permissions = useSelector(selectPermissions);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('md'));
  const [bitLockerPopup, setBitLockerPopup] = useState(false);
  const [editDevicePopup, setEditDevicePopup] = useState(false);
  const [consentPopup, setConsentPopup] = useState(false);
  const navigate = useNavigate();
  const deviceColumns = useSelector(selectColumnsInDetails)
    .map(({ columnId }) => columnId)
    .filter((columnId) => columnId !== 'deviceActionResults');
  const actionsColumns = useSelector(selectDeviceIntuneActionsColumns);
  const isDeviceIntuneLoaded = useSelector(selectIsDeviceIntuneLoaded);

  const dispatchGetOneDeviceById = async () => {
    try {
      await dispatch(getOneDeviceById(id)).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const dispatchGetAllIntuneColumns = async () => {
    try {
      await dispatch(getProviderColumns({ providerName: 'INTUNE', isBackOffice: false })).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  useEffect(() => {
    dispatch(findAzureUsers());
    dispatchGetAllIntuneColumns();
    return function cleanup() {
      dispatch(resetAzureUserState());
      dispatch(resetColumn());
    };
  }, [dispatch]);

  useEffect(() => {
    if (hasPermissions(permissions, 'GET_INTUNE_DEVICES')) {
      dispatch(getRecoverKeysById(id));
    }
    if (hasPermissions(permissions, 'GET_INTUNE_DEVICE_KEY')) {
      dispatchGetOneDeviceById();
    }
  }, [permissions, dispatch]);

  const openBitLockerPopup = async (recoveryKeysId) => {
    setBitLockerPopup(true);
    dispatch(getRecoverKeysDetailsById(recoveryKeysId));
  };

  const bitLockerRows = [
    { name: 'deviceName', key: 'deviceName', type: 'string' },
    { name: 'bitlockerKeyId', key: 'id' },
    {
      name: 'bitlockerKey',
      key: 'key',
      type: 'component',
      component: <CopyButton copyValue={recoveryKeysDetails?.key} />
    },
    { name: 'driveType', key: 'volumeType', type: 'string' }
  ];

  const columns = [
    { name: 'bitlockerKeyId', key: 'id', type: 'string' },
    { name: 'driveType', key: 'volumeType', type: 'string' },
    {
      name: 'bitlockerRecoveryKeyPreview',
      key: 'button',
      type: 'component',
      component: (
        <CustomizedButton
          text={isMobile ? `${t('showRecoveryKey')}` : <VpnKeyRoundedIcon />}
          datatestid="recoveryKey"
          onClick={openBitLockerPopup}
        />
      )
    }
  ];

  const buttons = [
    {
      text: t('edit'),
      datatestid: 'editIntuneDevice',
      onClick: () => setEditDevicePopup(true),
      isDisplayable: true,
      disabled: false
    },
    {
      text: t('reboot'),
      datatestid: 'rebootButton',
      onClick: () => setConsentPopup(true),
      isDisplayable: true,
      disabled: false
    }
  ];

  const buttonReturn = {
    isDisplayable: true,
    onClick: () => navigate('/devicesIntune')
  };

  const headerPageConfig = {
    text: {
      titleText: t('intuneDevice'),
      bigTitleText: `${device?.deviceName}`,
      selectedItemsText: '',
      tagId: 'IntuneDevice'
    },
    buttons,
    buttonReturn
  };

  const handleRebootDevice = () => {
    dispatch(rebootIntuneDevice(device.id));
    setConsentPopup(false);
  };
  return (
    <LoadingWrapper isChildDisplayable={isDeviceIntuneLoaded}>
      <Grid
        container
        sx={{
          height: { xs: 'calc(100vh - 60px)', sm: '100vh' },
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <HeaderPage headerPageConfig={headerPageConfig} />
        <Grid item xs={12} justifyContent="center" sx={styleMainGrid}>
          <Grid marginTop={{ xs: '10px' }}>
            <StandardBox
              title="information"
              ChildComponent={
                <InformationList
                  row={
                    device && Object.keys(device?.user).length === 0
                      ? { ...device, userPrincipalName: '', userId: '' }
                      : {
                          ...device,
                          userPrincipalName: device?.user?.displayName,
                          userId: device?.user?.id
                        }
                  }
                  columns={deviceColumns.map((column) => {
                    if (column === 'user')
                      return {
                        name: 'userPrincipalName',
                        key: 'userPrincipalName',
                        type: 'link'
                      };
                    if (column === 'lastSyncDateTime')
                      return { name: column, key: column, type: 'date' };

                    return { name: column, key: column, type: 'string' };
                  })}
                />
              }
            />
          </Grid>

          <StandardBox
            title="deviceActionsStatus"
            ChildComponent={
              <StandardTable
                columns={actionsColumns}
                rows={device?.deviceActionResults.map((row) => {
                  return {
                    ...row,
                    actionName: t(`${row.actionName}`),
                    actionState: t(`${row.actionState}`),
                    errorCode: row.errorCode === '0' ? '' : row.errorCode
                  };
                })}
                refColumn="actionName"
              />
            }
          />
          {hasPermissions(permissions, 'GET_INTUNE_DEVICE_KEY') ? (
            <StandardBox
              title="recoveryKeys"
              ChildComponent={
                <StandardTable
                  columns={columns}
                  rows={recoveryKeys.map((recoveryKey) => {
                    return { ...recoveryKey, volumeType: t(recoveryKey?.volumeType) };
                  })}
                  refColumn="id"
                />
              }
            />
          ) : null}
          <ConsentPopup
            open={consentPopup}
            handleClose={() => setConsentPopup(false)}
            handleAction={handleRebootDevice}
            text={t('askReboot')}
            actionTitle={t('rebootDevice')}
          />
          <InformationPopUp
            isLoading={recoveryKeysDetailsIsLoading}
            openPop={bitLockerPopup}
            onClose={() => setBitLockerPopup(false)}
            title={t('bitlockerRecoveryKeyPreview')}
            data={{
              ...recoveryKeysDetails,
              volumeType:
                recoveryKeysDetails.volumeType !== undefined
                  ? t(`${recoveryKeysDetails?.volumeType}`)
                  : null,
              deviceName: device?.deviceName
            }}
            rows={bitLockerRows}
          />
          {editDevicePopup ? (
            <EditIntuneDevicePopup
              popup={editDevicePopup}
              closePopup={() => setEditDevicePopup(false)}
            />
          ) : null}
        </Grid>
      </Grid>
    </LoadingWrapper>
  );
}
