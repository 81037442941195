import React, { useEffect } from 'react';
import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styleScrollbar } from '../../assets/style/utils/utils';
import AzureSetGroups from '../azureGroups/AzureSetGroups';
import {
  assignAzureGroupsUser,
  removeAzureGroupsUser,
  getAzureGroupsGraph,
  selectAzureGroupsGraph
} from '../../redux/slices/AzureGroups';
import hasPermissions from '../../utils/Permissions/permissions';
import { selectPermissions } from '../../redux/slices/userConnected';

export default function AzureGroupsPopup({
  selectedUsers,
  isRemove,
  popup,
  closePopup,
  resetSelectedRows
}) {
  const dispatch = useDispatch();
  const allAzureGroups = useSelector(selectAzureGroupsGraph);

  const permissions = useSelector(selectPermissions);
  const { t } = useTranslation();

  const handleAssignAzureGroup = async (selectedAzureGroups) => {
    await dispatch(
      assignAzureGroupsUser({
        groups: selectedAzureGroups,
        users: selectedUsers,
        allGroups: allAzureGroups
      })
    );
    resetSelectedRows();
    return closePopup();
  };

  const handleRemoveAzureGroup = async (selectedAzureGroups) => {
    await dispatch(
      removeAzureGroupsUser({
        groups: selectedAzureGroups,
        users: selectedUsers,
        allGroups: allAzureGroups
      })
    );
    resetSelectedRows();
    return closePopup();
  };

  const assignButton = {
    hasPermissions: hasPermissions(permissions, 'SET_AZURE_GROUPS_TO_USER'),
    text: `${t('assignAzureGroups')}`,
    datatestid: 'assignAzureGroups'
  };

  const removeButton = {
    hasPermissions: hasPermissions(permissions, 'SET_REMOVE_AZURE_GROUPS_TO_USER'),
    text: `${t('removeAzureGroups')}`,
    datatestid: 'removeAzureGroups'
  };

  useEffect(() => {
    dispatch(getAzureGroupsGraph());
  }, [dispatch]);

  return (
    <Dialog
      onClose={closePopup}
      open={popup}
      fullWidth
      maxWidth="xl"
      PaperProps={{ sx: styleScrollbar }}
    >
      {isRemove ? (
        <AzureSetGroups
          closePopup={closePopup}
          setGroups={handleRemoveAzureGroup}
          numberOfUsers={selectedUsers.length}
          button={removeButton}
        />
      ) : (
        <AzureSetGroups
          closePopup={closePopup}
          setGroups={handleAssignAzureGroup}
          numberOfUsers={selectedUsers.length}
          button={assignButton}
        />
      )}
    </Dialog>
  );
}

AzureGroupsPopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isRemove: PropTypes.bool,
  resetSelectedRows: PropTypes.func
};
AzureGroupsPopup.defaultProps = {
  isRemove: false,
  resetSelectedRows: () => {}
};
