import React from 'react';
import { Grid, InputLabel, Switch } from '@mui/material';
import PropTypes from 'prop-types';

function SwitchComponent({ switches, onChange, isEdit }) {
  return (
    <Grid container sx={{ p: 2 }} spacing={1}>
      {switches.map((switchItem) => (
        <Grid key={switchItem.name} item xs={12} sm={4}>
          <InputLabel htmlFor={switchItem.name}>{switchItem.name}</InputLabel>
          <Switch
            data-testid={`${switchItem.name}Switch`}
            name={switchItem.name}
            checked={switchItem.checked}
            onChange={(e) =>
              isEdit
                ? onChange(switchItem.id, switchItem.name, e.target.checked)
                : onChange(switchItem.name, e.target.checked)
            }
          />
        </Grid>
      ))}
    </Grid>
  );
}

SwitchComponent.propTypes = {
  switches: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired
    })
  ),
  onChange: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired
};

SwitchComponent.defaultProps = {
  switches: []
};

export default SwitchComponent;
