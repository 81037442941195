import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import StandardBox from '../../../components/StandardBox';
import InformationList from '../../../components/InformationList';
import {
  selectSelectedJiraDevices,
  getOneJiraDevice,
  setIsLoading,
  selectIsJiraDeviceLoaded
} from '../../../redux/slices/JiraDevices';
import Comments from '../../../components/Comments';
import hasPermissions from '../../../utils/Permissions/permissions';
import { selectPermissions } from '../../../redux/slices/userConnected';
import EditJiraDevicePopup from '../../../components/popup/EditJiraDevicePopup';
import { findJiraUsers, resetJiraUsersState } from '../../../redux/slices/JiraUsers';
import errorRedirection from '../../../utils/errorRedirection';
import LoadingWrapper from '../../../components/layout/LoadingWrapper';
import HeaderPage from '../../../components/HeaderPage';
import { styleMainGrid } from '../../../assets/style/views/Device';
import {
  getProviderColumns,
  resetColumn,
  selectColumnsInDetails
} from '../../../redux/slices/columns';

export default function DeviceJira() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const permissions = useSelector(selectPermissions);
  const jiraDevicesColumns = useSelector(selectColumnsInDetails).map((column) => ({
    id: column.columnId,
    name: column.columnName
  }));
  const jiraDevice = useSelector(selectSelectedJiraDevices);
  const isJiraDeviceLoaded = useSelector(selectIsJiraDeviceLoaded);
  const [editJiraDevicePopup, setEditJiraDevicePopup] = useState(false);
  const navigate = useNavigate();

  const dispatchGetOneJiraUser = async () => {
    try {
      await dispatch(getOneJiraDevice(id)).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const buttons = [
    {
      hasPermissions: hasPermissions(permissions, 'SET_JIRA_DEVICE_REPORTER'),
      text: t('edit'),
      datatestid: 'editJiraDevice',
      onClick: () => setEditJiraDevicePopup(true),
      isDisplayable: true,
      disabled: false
    }
  ];

  const buttonReturn = {
    isDisplayable: true,
    onClick: () => navigate('/devicesJira')
  };

  const headerPageConfig = {
    text: {
      titleText: t('deviceJira'),
      bigTitleText: `${jiraDevice.issueData?.name}`,
      selectedItemsText: '',
      tagId: 'JiraDevice'
    },
    buttons,
    buttonReturn,
    isPositionFixed: true
  };
  const dispatchGetAllJiraUserColumns = async () => {
    try {
      await dispatch(
        getProviderColumns({ providerName: 'JIRA_DEVICE', isBackOffice: false })
      ).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };
  useEffect(() => {
    dispatch(findJiraUsers());
    dispatch(setIsLoading(true));
    dispatchGetOneJiraUser();
    dispatchGetAllJiraUserColumns();
    return function cleanup() {
      dispatch(resetJiraUsersState());
      dispatch(resetColumn());
    };
  }, [dispatch]);

  return (
    <Grid>
      <LoadingWrapper isChildDisplayable={isJiraDeviceLoaded}>
        <HeaderPage headerPageConfig={headerPageConfig} />
        <Grid container justifyContent="center" sx={styleMainGrid}>
          <Grid marginTop={{ xs: '100px', sm: '150px', md: '240px' }}>
            <StandardBox
              title={jiraDevice?.issueData?.name}
              ChildComponent={
                <InformationList
                  row={jiraDevice?.issueData}
                  columns={jiraDevicesColumns.map((column) => ({
                    name: column.name,
                    key: column.id,
                    type: 'string'
                  }))}
                />
              }
            />
          </Grid>
          {hasPermissions(permissions, 'GET_JIRA_DEVICE_COMMENT') ? (
            <StandardBox
              title="comments"
              ChildComponent={<Comments data={jiraDevice.comments} deviceId={id} />}
            />
          ) : null}

          {editJiraDevicePopup ? (
            <EditJiraDevicePopup
              popup={editJiraDevicePopup}
              closePopup={() => setEditJiraDevicePopup(false)}
            />
          ) : null}
        </Grid>
      </LoadingWrapper>
    </Grid>
  );
}
