import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  FormLabel,
  DialogTitle,
  DialogActions,
  createFilterOptions,
  FormControl,
  Autocomplete,
  ListItem,
  ListItemText,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { jiraUserList } from '../../redux/slices/JiraUsers';
import {
  selectSelectedJiraDevices,
  assignReporterToJiraDevice
} from '../../redux/slices/JiraDevices';
import CustomizedButton from '../buttons/CustomizedButton';
import { styleDialogActions } from '../../assets/style/views/AzurADUserPopup';
import {
  CustomTextField,
  formLabel,
  listboxProps,
  styleScrollbar
} from '../../assets/style/utils/utils';

export default function EditJiraDevicePopup({ popup, closePopup }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const jiraUsers = useSelector(jiraUserList);
  const device = useSelector(selectSelectedJiraDevices);
  const [selectedUser, setSelectedUser] = useState({});
  const [isAssignUserBtnDisable, setIsAssignUserBtnDisabled] = useState(true);
  const [fakeUser] = useState({ displayName: t('none') });
  const jiraUsersAutoComplete = [...jiraUsers];
  jiraUsersAutoComplete.push(fakeUser);

  useEffect(() => {
    if (
      Object.keys(selectedUser).length === 0 ||
      selectedUser.key === device.issueData.reporter.id ||
      (selectedUser.displayName === 'Aucun' && device.issueData.reporter.id.length === 0)
    ) {
      setIsAssignUserBtnDisabled(true);
    } else {
      setIsAssignUserBtnDisabled(false);
    }
  }, [selectedUser]);

  const handleDefaultValue = () => {
    if (device.issueData.reporter.id?.length !== 0) {
      const index = jiraUsers.findIndex((user) => user.key === device.issueData.reporter.id);
      if (index !== -1) {
        return jiraUsers[index];
      }
    }
    return fakeUser;
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => {
      return option.displayName;
    },
    limit: 10
  });

  const handleAssignReporter = () => {
    const body = {
      id: selectedUser.key,
      name: selectedUser.name
    };
    dispatch(assignReporterToJiraDevice({ key: device.issueData.name, body }));
    closePopup();
  };

  return (
    <Dialog
      open={popup}
      onClose={closePopup}
      aria-labelledby="alert-dialog-editJiraDevice"
      aria-describedby="alert-dialog-editJiraDevice"
      fullWidth
      maxWidth="md"
      PaperProps={{ sx: styleScrollbar }}
    >
      <DialogTitle>{t('manageDeviceInformation')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl sx={formLabel(theme)}>
          <FormLabel align="left" sx={{ color: theme.palette.colorBlack }}>
            {t('Reporter')} :
          </FormLabel>
          <Autocomplete
            data-testid="select-jiraDeviceReporter"
            options={jiraUsersAutoComplete}
            filterOptions={filterOptions}
            disableClearable
            autoHighlight
            getOptionLabel={(user) => user.displayName}
            onChange={(e, user) => setSelectedUser(user)}
            defaultValue={handleDefaultValue}
            ListboxProps={listboxProps(theme)}
            renderOption={(props, option) => {
              return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <ListItem {...props} key={option.key} data-testid={`option${option.key}`}>
                  <ListItemText>{option.displayName}</ListItemText>
                </ListItem>
              );
            }}
            renderInput={(params) => (
              <CustomTextField
                data-testid="selectReporter"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
              />
            )}
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={styleDialogActions}>
        <CustomizedButton
          datatestid="ButtonUpdateReporter"
          disabled={isAssignUserBtnDisable}
          onClick={handleAssignReporter}
          text={t('save')}
        />
        <CustomizedButton
          onClick={() => closePopup()}
          text={t('closePopup')}
          datatestid="closePopup"
        />
      </DialogActions>
    </Dialog>
  );
}

EditJiraDevicePopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired
};
