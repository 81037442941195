import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ColumnsService from '../../services/columns/columnsService';
import removeDuplicates from '../../utils/columns';

export const initialState = {
  columns: [],
  isLoading: false,
  status: 'fulfilled'
};

export const getProviderColumns = createAsyncThunk(
  'getColumns/list',
  async ({ providerName, isBackOffice }) => {
    const res = await ColumnsService.getColumns(providerName, isBackOffice);
    return res.data;
  }
);

export const patchProviderColumns = createAsyncThunk('patchColumns/list', async (params) => {
  const { providerName, columns } = params;
  const res = await ColumnsService.patchColumns(providerName, columns);
  return res.data;
});

export const columnsSlice = createSlice({
  name: 'columns',
  initialState,
  reducers: {
    resetColumn: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviderColumns.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getProviderColumns.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        let newColumns = [...state.columns];
        newColumns = newColumns.concat(
          action.payload.columns.map((column) => ({
            ...column,
            providerName: action?.meta?.arg?.providerName
          }))
        );
        state.columns = removeDuplicates(newColumns);
      })
      .addCase(getProviderColumns.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      .addCase(patchProviderColumns.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(patchProviderColumns.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.columns = action.payload.columns;
      })
      .addCase(patchProviderColumns.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});

export const { resetColumn } = columnsSlice.actions;

export const selectColumns = (state) => state.columns.columns;
export const selectColumnsCreation = (state) =>
  state.columns.columns.filter(({ isInCreation }) => isInCreation);
export const selectColumnsEdit = (state) =>
  state.columns.columns.filter(({ isInEdit }) => isInEdit);

export const selectColumnsDisplayed = (state) =>
  state.columns.columns.filter((column) => column.isDisplayed);

export const selectColumnsInDetails = (state) =>
  state.columns.columns.filter((column) => column.isInDetails);

export const selectAreColumnsLoaded = (state) => {
  const columnsArePresent = state.columns.columns?.length > 0;
  return columnsArePresent;
};

export default columnsSlice.reducer;
