import React, { useEffect } from 'react';
import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styleScrollbar } from '../../assets/style/utils/utils';
import { assignGroupsAdUser, deleteGroupsAdUser, getGroupsAd } from '../../redux/slices/AdUsers';
import hasPermissions from '../../utils/Permissions/permissions';
import { selectPermissions } from '../../redux/slices/userConnected';
import AdSetGroups from '../adUserGroups/AdSetGroups';

export default function AdGroupsPopup({
  selectedUsers,
  isRemove,
  popup,
  closePopup,
  resetSelectedRows
}) {
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  const { t } = useTranslation();

  const handleAssignAdGroup = async (selectedAdGroups) => {
    await dispatch(
      assignGroupsAdUser({
        users: selectedUsers,
        groups: selectedAdGroups
      })
    );
    resetSelectedRows();
    return closePopup();
  };

  const handleRemoveAdGroup = async (selectedAdGroups) => {
    await dispatch(
      deleteGroupsAdUser({
        users: selectedUsers,
        groups: selectedAdGroups
      })
    );
    resetSelectedRows();
    return closePopup();
  };

  const assignButton = {
    hasPermissions: hasPermissions(permissions, 'ASSIGN_GROUPS_AD_USERS'),
    text: `${t('assignADGroups')}`,
    datatestid: 'assignADGroupsPopUp'
  };

  const removeButton = {
    hasPermissions: hasPermissions(permissions, 'DELETE_GROUPS_AD_USERS'),
    text: `${t('removeADGroups')}`,
    datatestid: 'removeADGroupsPopUp'
  };

  useEffect(() => {
    dispatch(getGroupsAd());
  }, [dispatch]);

  return (
    <Dialog
      onClose={closePopup}
      open={popup}
      fullWidth
      maxWidth="xl"
      PaperProps={{ sx: styleScrollbar }}
    >
      {isRemove ? (
        <AdSetGroups
          closePopup={closePopup}
          setGroups={handleRemoveAdGroup}
          numberOfUsers={selectedUsers.length}
          button={removeButton}
        />
      ) : (
        <AdSetGroups
          closePopup={closePopup}
          setGroups={handleAssignAdGroup}
          numberOfUsers={selectedUsers.length}
          button={assignButton}
        />
      )}
    </Dialog>
  );
}

AdGroupsPopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isRemove: PropTypes.bool,
  resetSelectedRows: PropTypes.func
};
AdGroupsPopup.defaultProps = {
  isRemove: false,
  resetSelectedRows: () => {}
};
