import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HeaderPage from '../../../components/HeaderPage';
import {
  selectedUser,
  getDayforceById,
  selectIsLoading
} from '../../../redux/slices/DayforceUsers';
import errorRedirection from '../../../utils/errorRedirection';
import LoadingWrapper from '../../../components/layout/LoadingWrapper';
import DayforceDescription from '../../../components/dayforceUser/DayforceDescription';

export default function DayforceUser() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);
  const user = useSelector(selectedUser);
  const dispatchGetDayforce = async () => {
    try {
      await dispatch(getDayforceById(id)).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const headerPageConfig = {
    text: {
      titleText: t('dayforceEmployee'),
      bigTitleText: `${user?.FirstName} ${user?.LastName}`,
      selectedItemsText: '',
      tagId: 'DayforceEmployee'
    },
    buttonReturn: {
      isDisplayable: true,
      onClick: () => navigate('/dayforceUsers')
    }
  };

  useEffect(() => {
    dispatchGetDayforce();
  }, [dispatch]);

  return (
    <LoadingWrapper isChildDisplayable={!isLoading}>
      <HeaderPage headerPageConfig={headerPageConfig} />
      <Grid>
        <DayforceDescription />
      </Grid>
    </LoadingWrapper>
  );
}
