import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const styleScrollbar = (theme) => ({
  '::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '::-webkit-scrollbar-track': {
    background: theme.palette.colorMainHover,
    borderRadius: '5px'
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.colorMainDark,
    borderRadius: '5px'
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.colorMain
  },
  '::-webkit-scrollbar-corner': {
    background: 'transparent'
  },
  backgroundColor: theme.palette.colorSecondaryLight,
  color: theme.palette.colorBlack
});

export const colorScrollBar = (theme) => ({
  '::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '::-webkit-scrollbar-track': {
    background: theme.palette.colorMainHover,
    borderRadius: '5px'
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.colorMainDark,
    borderRadius: '5px'
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.colorMain
  },
  '::-webkit-scrollbar-corner': {
    background: 'transparent'
  },
  backgroundColor: theme.palette.colorSecondaryLight,
  color: theme.palette.colorBlack
});

export const selectProps = (theme) => ({
  MenuProps: {
    PaperProps: {
      style: {
        color: theme.palette.colorSoftProfile,
        backgroundColor: theme.palette.colorSecondaryLight
      }
    }
  }
});

export const menuProps = (theme) => ({
  PaperProps: {
    style: {
      color: theme.palette.errorMain,
      backgroundColor: theme.palette.colorSecondaryLight
    }
  },
  color: theme.palette.successSecondary
});

export const listboxProps = (theme) => ({
  style: {
    color: theme.palette.colorBlack,
    backgroundColor: theme.palette.colorMainLight
  }
});

export const formLabel = (theme) => ({
  '& .label.Mui-focused': { color: theme.palette.colorBlack },
  gap: 2
});

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: '100%',
    color: theme.palette.colorBlack,
    '&:disabled': {
      color: theme.palette.colorGray
    }
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.colorSoftBlack
    },
    '&:hover fieldset': {
      borderColor: theme.palette.colorSoftBlack
    }
  }
}));
