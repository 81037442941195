import React from 'react';
import { Typography, Grid, Avatar, Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { useTranslation } from 'react-i18next';
import {
  styleTypographyProfile,
  styleAlignHorizontal,
  styleHeader,
  styleTitle,
  styleSelectContainer,
  styleGreyText
} from '../../assets/style/views/Profile';
import {
  selectPhoto,
  selectLicenses,
  selectGroup,
  selectName,
  selectEmail
} from '../../redux/slices/userConnected';
import AzureLicensesList from '../../components/azureLicenses/AzureLicensesList';
import LanguageSelect from '../../components/Translation/LanguageSelect';
import ThemeSombre from '../../components/buttons/ThemeSombre';

export default function Profile() {
  const { t } = useTranslation();
  const userPhoto = useSelector(selectPhoto);
  const azureLicenses = useSelector(selectLicenses);
  const userGroups = useSelector(selectGroup);
  const userName = useSelector(selectName);
  const userEmail = useSelector(selectEmail);
  const colorEnum = {
    TYPE_1: { groupId: 1, color: '#666666', icon: <RemoveModeratorIcon /> },
    TYPE_2: { groupId: 2, color: '#C4B4CC', icon: <AdminPanelSettingsIcon /> },
    TYPE_3: { groupId: 3, color: '#9B6580', icon: <LocalPoliceIcon /> },
    TYPE_4: { groupId: 4, color: '#B13434', icon: <LocalPoliceIcon /> }
  };
  const chipsColor = Object.values(colorEnum).find(
    ({ groupId }) => groupId === userGroups.id
  )?.color;
  const chipsIcon = Object.values(colorEnum).find(({ groupId }) => groupId === userGroups.id)?.icon;

  return (
    <Grid container>
      <Grid sx={{ width: '100%' }}>
        <Grid sx={styleHeader}>
          <Typography sx={styleTypographyProfile}>Profile</Typography>
          <Typography variant="h4" sx={styleTitle}>
            {t('userInfos')}
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ padding: '20px' }}>
            <Grid sx={styleAlignHorizontal}>
              <Avatar alt="Alan turing" sx={{ width: 150, height: 150 }} src={userPhoto} />
              <Grid sx={{ textAlign: 'center' }}>
                <Typography variant="h4" sx={styleTitle}>
                  {userName}
                </Typography>
                <Typography sx={styleGreyText}>{userEmail}</Typography>
              </Grid>
              <Chip
                icon={chipsIcon}
                label={userGroups.name}
                color="primary"
                sx={{ padding: '20px', fontSize: '16px', backgroundColor: chipsColor }}
              />
              <Grid sx={styleSelectContainer}>
                <Typography sx={styleGreyText}>Sélectionner une langue</Typography>
                <LanguageSelect />
              </Grid>
              <Grid>
                <Typography sx={styleGreyText}>Mode sombre</Typography>
                <ThemeSombre />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} sx={{ padding: '20px' }}>
            <AzureLicensesList azureLicenses={azureLicenses} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
