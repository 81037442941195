import React from 'react';
import {
  Paper,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  useTheme
} from '@mui/material';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { azurePrivilegeUp } from '../../services/authentication/AzureAD/authAzure';
import { deleteAzureUser } from '../../redux/slices/AzureUsersList';
import CustomizedButton from '../buttons/CustomizedButton';
import { styleScrollbar } from '../../assets/style/utils/utils';

export default function AzureADDeleteUserPopup({
  popup,
  closePopup,
  selectedAzureUsers,
  clearSelectedAzureUser
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleClick = async () => {
    // https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful/23747597#23747597
    setTimeout(async () => {
      try {
        const accessToken = (await dispatch(azurePrivilegeUp())).payload;
        const deletedUsersBody = {
          userIdList: selectedAzureUsers,
          accessTokenMicrosoft: accessToken
        };
        const response = await dispatch(deleteAzureUser(deletedUsersBody));
        if (response?.payload?.success === true) {
          clearSelectedAzureUser();
          closePopup();
        }
      } catch (error) {
        console.error(error);
      }
    });
  };
  return (
    <Dialog
      open={popup}
      onClose={closePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="deleteAzureUserPopup"
      PaperProps={{ sx: styleScrollbar }}
    >
      <DialogContent sx={styleScrollbar}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    color: theme.palette.colorBlack,
                    backgroundColor: theme.palette.colorSecondaryLight
                  }}
                  align="center"
                >
                  <Typography>
                    {t('confirmDeleteBegin') + selectedAzureUsers.length + t('confirmDeleteEnd')}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <CustomizedButton onClick={closePopup} text={t('cancel')} datatestid="closePopup" />
        <CustomizedButton
          onClick={handleClick}
          text={t('delete')}
          datatestid="ButtondeleteAzureUser"
        />
      </DialogActions>
    </Dialog>
  );
}

AzureADDeleteUserPopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  selectedAzureUsers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    .isRequired,
  clearSelectedAzureUser: PropTypes.func.isRequired
};
