import React from 'react';
import WidgetsIcon from '@mui/icons-material/Widgets';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AppsIcon from '@mui/icons-material/Apps';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import { styleIconSize } from '../../../assets/theme/utils';

const iconSize = 32;

function SubMenuBackOffice() {
  return [
    {
      id: 'Providers',
      codename: 'GET_DASHBOARD',
      openChild: false,
      icon: <WidgetsIcon sx={styleIconSize(iconSize)} />,
      path: '/providers',
      child: null,
      datatestid: 'SubMenuBackOffice-backoffice-providers'
    },
    {
      id: 'AzureGroups',
      codename: 'GET_DASHBOARD',
      openChild: false,
      icon: <WorkspacesIcon sx={styleIconSize(iconSize)} />,
      path: '/azureGroups',
      child: null,
      datatestid: 'SubMenuBackOffice-backoffice-azure-groups'
    },
    {
      id: 'AppList',
      codename: 'GET_DASHBOARD',
      openChild: false,
      icon: <AppsIcon sx={styleIconSize(iconSize)} />,
      path: '/appList',
      child: null,
      datatestid: 'SubMenuBackOffice-backoffice-app-list'
    },
    {
      id: 'AuthGroups',
      codename: 'GET_DASHBOARD',
      openChild: false,
      icon: <ManageAccountsIcon sx={styleIconSize(iconSize)} />,
      path: '/AuthGroups',
      child: null,
      datatestid: 'SubMenuBackOffice-backoffice-auth-groups'
    },
    {
      id: 'History',
      codename: 'GET_DASHBOARD',
      openChild: false,
      icon: <ManageHistoryIcon sx={styleIconSize(iconSize)} />,
      path: '/History',
      child: null,
      datatestid: 'SubMenuBackOffice-backoffice-history'
    },
    {
      id: 'Parameters',
      codename: 'GET_DASHBOARD',
      openChild: false,
      icon: <DisplaySettingsIcon sx={styleIconSize(iconSize)} />,
      path: '/parameters',
      child: null,
      datatestid: 'SubMenuBackOffice-backoffice-parameters'
    },
    {
      id: 'ProvidersColumns',
      codename: 'GET_DASHBOARD',
      openChild: false,
      icon: <WidgetsIcon sx={styleIconSize(iconSize)} />,
      path: '/providersColumns',
      child: null,
      datatestid: 'SubMenuBackOffice-backoffice-providersColumns'
    }
  ];
}

export default SubMenuBackOffice;
