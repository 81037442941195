export const styleDomain = (theme) => ({
  width: '100%',
  color: theme.palette.colorBlack
});

export const styleHelperText = (theme) => ({
  color: theme.palette.errorMain,
  margin: '0px'
});

export const styleDialogActions = {
  display: 'flex',
  justifyContent: 'center'
};

export const styleEyePassword = (theme) => ({
  color: theme.palette.colorBlack
});
