import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  TableBody,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  Paper,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  styleColumnUsers,
  styleRowUserSelected,
  styleTableHead,
  styleSelect,
  styleCell
} from '../../assets/style/views/UserPages';
import { isNotif, licenseNotif } from '../../redux/slices/licenseNotification';
import { selectedAzureUsers } from '../../redux/slices/AzureUsersList';
import { getDateTimeFormatted } from '../../utils/Date/DateFunction';
import CustomizedButton from '../buttons/CustomizedButton';
import { styleScrollbar } from '../../assets/style/utils/utils';

const NBR_NOTIFICATION = 4;
export default function AzureLicenseNotifPopup({ popup, closePopup }) {
  const selectedAzureUser = useSelector(selectedAzureUsers);
  const notifNb = useSelector(isNotif);
  const licenseNotification = useSelector(licenseNotif);
  const [reload, setreload] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const tmpArr = licenseNotification
    .map((element) => {
      return element.licenseNotifList.map((data) => {
        return { id: element.id, msg: data.msg, read: data.read, date: data.date };
      });
    })
    .flat(1);

  const licenseNotifPreview = tmpArr
    .sort((a, b) => a.date - b.date)
    .slice(
      0,
      tmpArr.length > 0 && tmpArr.length > NBR_NOTIFICATION ? NBR_NOTIFICATION : tmpArr.length
    );

  const goToNotifLicense = () => {
    closePopup();
    setreload(true);
  };

  const GetUserName = ({ id }) => {
    const index = selectedAzureUser.findIndex((object) => {
      return object.id === id;
    });
    if (index !== -1) {
      return selectedAzureUser[index].displayName;
    }
    return t('noName');
  };
  return (
    <Dialog
      sx={styleSelect}
      open={popup}
      onClose={closePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="CreateAzureUserPopup"
      PaperProps={{ sx: styleScrollbar }}
    >
      {reload ? <Navigate to="/licenseNotif" /> : ''}
      <DialogContent sx={styleScrollbar}>
        <TableContainer component={Paper} sx={styleScrollbar}>
          <Table aria-label="table products">
            <TableHead>
              <TableRow>
                <TableCell sx={(styleColumnUsers, styleTableHead(theme))}>
                  {t('displayName')}
                </TableCell>
                <TableCell sx={(styleColumnUsers, styleTableHead(theme))}>
                  {t('notification')}
                </TableCell>
                <TableCell sx={(styleColumnUsers, styleTableHead(theme))}>{t('Date')}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {licenseNotifPreview.map((column, index) => (
                <TableRow
                  sx={styleRowUserSelected(theme)}
                  key={`notification${column.id}${column.date}`}
                >
                  <TableCell sx={styleCell}>
                    <GetUserName id={column.id} />
                  </TableCell>
                  <TableCell sx={styleCell}>
                    <Typography gutterBottom data-testid={`notification${column.id}${index}`}>
                      {column.msg}
                    </Typography>
                  </TableCell>
                  <TableCell sx={styleCell}>
                    <Typography gutterBottom data-testid={`date${column.id}${index}`}>
                      {getDateTimeFormatted(Number(column.date))}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      {licenseNotification && notifNb > 0 && tmpArr.length > licenseNotifPreview.length && (
        <DialogActions>
          <CustomizedButton
            onClick={goToNotifLicense}
            text={t('seeMore')}
            datatestid="closePopupNotificationLicense"
          />
        </DialogActions>
      )}
    </Dialog>
  );
}

AzureLicenseNotifPopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired
};
