import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BACK_URL } from '../../../../../constants/constants';
import { styleLicenceButtonImage } from '../../../../../assets/style/views/LoginBoard';
import CustomFiledDrop from '../../../../fileDrop/CustomFileDrop';

export default function ImageField({ value, setModifiedItem, file, setFile }) {
  const { t } = useTranslation();
  const [previewImage, setPreviewImage] = useState('');
  const urlId =
    previewImage.length && file && value === file.name ? previewImage || '' : value || '';

  const handleImageChange = (newFile) => {
    setFile(newFile);

    if (newFile) {
      const reader = new FileReader();
      reader.onload = function load(e) {
        const base64Image = e.target.result;
        setPreviewImage(base64Image);
      };
      reader.readAsDataURL(newFile);
    }
  };
  return (
    <Box sx={{ marginBottom: '25px' }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        borderRadius="5px"
        padding="25px"
      >
        <img
          sx={styleLicenceButtonImage}
          src={urlId.startsWith('data:image') ? urlId : `${BACK_URL}api/images/${urlId}`}
          style={{
            maxHeight: '125px',
            width: 'auto',
            height: 'auto',
            marginBottom: '15px'
          }}
          alt="Logos licences"
        />
        <CustomFiledDrop
          setFile={handleImageChange}
          setFileName={setModifiedItem}
          fileName={value}
        />
        <Typography sx={{ paddingTop: '10px' }}>{t('rectangularPictureSizeLimit')}</Typography>
      </Grid>
    </Box>
  );
}

ImageField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool]),
  setModifiedItem: PropTypes.func,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setFile: PropTypes.func
};

ImageField.defaultProps = {
  value: '',
  setModifiedItem: () => {},
  file: null,
  setFile: () => {}
};
